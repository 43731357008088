import React, { useContext, useState } from 'react'
import {
    IonBackButton,
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonRouterLink,
    IonTitle,
    IonToolbar,
    IonItem,
    IonList,
    IonInput,
    IonLabel,
    IonLoading,
    IonText,
    IonAlert,
    IonCheckbox,
    IonModal,
    IonIcon,
  } from "@ionic/react"
  import { Redirect } from "react-router-dom"
import { UserAuth } from '../auth'
import { useHistory } from "react-router-dom"
import "./authPage.scss"
import { planet, planetOutline} from "ionicons/icons"


interface AxiosError {
  message: string;
  name: string;
  stack?: string;
  config: {
      transitional: {
          silentJSONParsing: boolean;
          forcedJSONParsing: boolean;
          clarifyTimeoutError: boolean;
      };
      adapter: string[];
      transformRequest: Array<null>;
      transformResponse: Array<null>;
      timeout: number;
      xsrfCookieName: string;
      xsrfHeaderName: string;
      maxContentLength: number;
      maxBodyLength: number;
      env: Record<string, unknown>;
      headers: {
          Accept: string;
          "Content-Type": string;
      };
      method: string;
      url: string;
      data: string;
  };
  response: {
    code?: string;
    status?: number;
  };
}




const SignupPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('')
    const [status, setStatus] = useState({ loading: false, error: false });
    const [inviteCode, setInviteCode] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const {createUser} = UserAuth();
    const history = useHistory()

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSignup();
      }
    }

    const isFormValid = () => {
      return email.length > 0 && password.length > 0 && confirmPassword.length > 0 && password === confirmPassword && termsChecked;
  };

    const handleSignup = async () => {
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }

      try {
        setStatus({ loading: true, error: false });
        const credential = await createUser(email, password, inviteCode);
        console.log('credential:', credential);
        setStatus({ loading: false, error: false });
        setShowAlert(true); // Show alert on successful signup
        // history.push(`/home`)
        resetForm();
      } catch (error) {
        const axiosError = error as AxiosError;
        setStatus({ loading: false, error: true });
        console.log('error:', axiosError, error);
        if (axiosError.response.status === 400) {
            setError("Invalid invite code, please check code and try again or request a new one.");
        } else {
            setError("An error occurred during signup. Please try again.");
        }
      }
    };
  
    const handleBackToLogin = () => {
      history.push('/login');
    };

    const resetForm = () => {
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setInviteCode('');
      setTermsChecked(false);
      setError('');
      setStatus({ loading: false, error: false });
    };

    return (
      <IonPage className="page">
        <IonHeader>
          <IonToolbar className="custom-toolbar">
            <IonTitle className="ion-padding">Sign up</IonTitle>
            <h5 style={{ marginTop: "0.1rem", marginLeft: "1.3rem", color: "#FFD03B"}}>Join the journey!</h5>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
        {/* <div className="auth-text">Space Subs</div> */}
        <IonRouterLink routerLink="/land">
        <div className="auth-text">Space Subs</div>
        </IonRouterLink>
          <IonList style={{ '--background': 'transparent', '--ion-item-background': 'transparent' }}>
          <IonItem fill="outline" className="ion-padding-start ion-padding-end field-spacing">
              <IonLabel position="stacked">Invite Code</IonLabel>
              <IonInput type="text" value={inviteCode}
                onIonChange={(event) => setInviteCode(event.detail.value ?? '')}
                onKeyUp={handleKeyPress}
              />
            </IonItem>
            <IonItem fill="outline" className="ion-padding-start ion-padding-end field-spacing">
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput type="email" value={email}
                onIonChange={(event) => setEmail(event.detail.value ?? '')}
                onKeyUp={handleKeyPress}
              />
            </IonItem>
            <IonItem fill="outline" className="ion-padding-start ion-padding-end field-spacing">
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput type="password" value={password}
                onIonChange={(event) => setPassword(event.detail.value ?? '')}
                onKeyUp={handleKeyPress}
              />
            </IonItem>

            <IonItem fill="outline" className="ion-padding-start ion-padding-end field-spacing">
                    <IonLabel position="stacked">Confirm Password</IonLabel>
                    <IonInput
                        type="password"
                        value={confirmPassword}
                        onIonChange={(e) => setConfirmPassword(e.detail.value ?? '')}
                    />
            </IonItem>


            <div style={{ display: 'flex', alignItems: 'center' , width: '100%' }}>
            <IonItem lines="none" className="ion-padding-start ion-padding-end" style={{ padding: '0', flex: 'none' }}>
   
                <IonCheckbox
                  checked={termsChecked}
                  onIonChange={(e) => setTermsChecked(e.detail.checked)}
                />
                   </IonItem>
                <IonLabel className="ion-padding-start" style={{ margin: 0 }}>
                  I agree to the{' '}
                  <IonRouterLink
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(true);
                    }}
                  >
                    terms and conditions
                  </IonRouterLink>
                </IonLabel>
            </div>
         

          </IonList>
          {status.error && 
            <IonText color="danger">{error}</IonText>
          }


          <IonButton className='ion-padding' expand="block" onClick={handleSignup} disabled={!isFormValid()}>Sign Up!</IonButton>
          <IonButton className='ion-padding' expand="block" onClick={handleBackToLogin} fill="clear">Back to Login</IonButton>


          <div style={{ marginTop:'30px',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <IonText style={{ fontSize: '1.3em', textAlign: 'center' }} color="warning">
    If you need an Invite Code, please fill out the <IonRouterLink routerLink="/land#form">Home Page form at the bottom<IonIcon icon={planet}></IonIcon></IonRouterLink>
  </IonText>
</div>
          <IonLoading isOpen={status.loading} />
          
          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Terms and Conditions</IonTitle>
              <IonButton slot="end" onClick={() => setShowModal(false)}>
                Close
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="ion-padding">
          <h2>Terms and Conditions for Space Subs</h2>
          <p><em>Last Updated: 2024 May 24</em></p>
          
          <h3>1. Acceptance of Terms</h3>
          <p>
            By using Space Subs (“the App”), you agree to be bound by these Terms and Conditions (“Terms”).
            If you do not agree to these Terms, please do not use the App.
          </p>

          <h3>2. Services Provided</h3>
          <p>
          Space Subs provides [describe the services offered by the app]. The services are subject to change without prior notice.
          </p>

          <h3>3. User Responsibilities</h3>
          <p>
            <strong>Account Registration:</strong> You must provide accurate information when creating an account.<br />
            <strong>Confidentiality:</strong> You are responsible for maintaining the confidentiality of your account credentials
            and are fully responsible for all activities that occur under your account.<br />
            <strong>Sensitive Information:</strong> <strong>Do not save sensitive information (e.g., passwords, social security numbers,
            financial information) in the App.</strong> The App is not designed to store sensitive data securely.
          </p>

          <h3>4. Privacy</h3>
          <p>
            We are committed to protecting your privacy. Please review our Privacy Policy for details on how we collect,
            use, and disclose your information.
          </p>

          <h3>5. Data Security</h3>
          <p>
            While we strive to protect your data, <strong>you acknowledge that data transmission over the internet is inherently insecure,
            and we cannot guarantee the absolute security of your data.</strong>
          </p>

          <h3>6. User Conduct</h3>
          <p>
            You agree not to use the App for any unlawful purpose or in any way that could harm the App or its users. This includes, but is not limited to:
            <ul>
              <li>Uploading or transmitting any form of malicious code or harmful data.</li>
              <li>Engaging in activities that compromise the security or integrity of the App.</li>
              <li>Using the App to harass, abuse, or harm another person.</li>
            </ul>
          </p>

          <h3>7. Intellectual Property</h3>
          <p>
            All content, trademarks, and data on the App are the property of Space Subs or its licensors and are protected by intellectual property laws.
            You may not use, reproduce, or distribute any content from the App without prior written permission from us.
          </p>

          <h3>8. Limitation of Liability</h3>
          <p>
            To the fullest extent permitted by law, Space Subs will not be liable for any direct, indirect, incidental, special, or consequential damages resulting from:
            <ul>
              <li>Your use of or inability to use the App.</li>
              <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
              <li>Any bugs, viruses, or the like that may be transmitted to or through our App by any third party.</li>
            </ul>
          </p>

          <h3>9. Indemnification</h3>
          <p>
            You agree to indemnify and hold Space Subs, its affiliates, and their respective officers, agents, and employees, harmless from any claims, damages, liabilities, costs,
            and expenses (including reasonable attorney’s fees) arising from:
            <ul>
              <li>Your use of the App.</li>
              <li>Your violation of these Terms.</li>
              <li>Your infringement of any intellectual property or other right of any person or entity.</li>
            </ul>
          </p>

          <h3>10. Changes to Terms</h3>
          <p>
            We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the App.
            Your continued use of the App following the posting of revised Terms means that you accept and agree to the changes.
          </p>

          <h3>11. Governing Law</h3>
          <p>
            These Terms are governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law principles.
          </p>

          <h3>12. Contact Us</h3>
          <p>
            If you have any questions about these Terms, please contact us at [Contact Information].
          </p>

          <h3>Reminder:</h3>
          <p>
            While using Space Subs, please do not save sensitive information, and be aware that data transmitted online can never be 100% secure.
          </p>
            </div>
          </IonContent>
        </IonModal>

        </IonContent>

        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => history.push('/login')} // Navigate to login page when the alert is dismissed
            header={'Sign-up Successful!'}
            message={'Whoo Hoo!!! Welcome aboard, please sign in, then select your target language.  Remember to add a shortcut to your home page if using a mobile device or tablet.'}
            buttons={['OK']}
        />
      </IonPage>
    );

}

export default SignupPage