import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useLocation, useParams } from "react-router"
import ReactPlayer from "react-player"
import {
  IonIcon,
  IonCard,
  IonRippleEffect,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonSegmentButton,
  IonSegment,
  IonThumbnail,
  IonButton,
  IonTextarea,
  IonToast,
  IonModal,
  IonInput,
  IonTitle,
  IonContent,
  IonRadioGroup,
  IonRadio,
  IonList,
  IonAlert,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonRouterLink,
  IonSkeletonText,
  IonSearchbar,
} from "@ionic/react"
import {
  createOutline,
  settingsOutline,
  trashOutline,
  informationCircleOutline,
  gridOutline,
} from "ionicons/icons"
import {
  apiYT,
  downloadYoutubeSubtitles,
  convertYouTubeDurationToSeconds,
} from "../utils/apiYT"
import { Storage } from "@ionic/storage"
import { languageMap, languageMapAvail } from "../utils/constants"
import HeartComponent from "../components/HeartComponent"
import favoriteVideosService from "../utils/FavoriteVideosService"
import historyVideosService from "../utils/HistoryVideosService" // replace path-to with actual path
import BookmarkComponent from "../components/BookmarkComponent"
import bookmarkTilesService from "../utils/BookmarkTilesService"
import PlayerControls from "../components/PlayerControls"
import PlayerSubs from "../components/PlayerSubs/PlayerSubs"
import SubtitleEditor from "../components/SubtitleEditor/SubtitleEditor"
import { useMediaQuery } from "react-responsive"
import "./Zplayer.scss"
import "../components/SubtitleEditor/SubtitleEditor.scss"
import {
  convertToSeconds,
  formatTime,
  formatTimeRP,
  getFontSizeAndLineHeight,
  secondsToYouTube8601,
} from "../utilities/utilities"
import { Clipboard } from "@capacitor/clipboard"
import userActivityService from "../utils/UserActivityService"

const { vtt } = require("../mockdata")

// Create an instance of Ionic Storage
const storage = new Storage()
storage.create()

// Define the type of the subtitle object
type Subtitle = {
  i: number
  s: string
  e: string
  t: string
}
interface VideoDetail {
  lengthSeconds: string;
  title: string;
  channelId: string;
  channelTitle: string;
  categoryId: string;
  viewCount: string;
  description: string;
}


const ZLplayer = () => {

  const defaultVideoDetail: VideoDetail = {
    lengthSeconds: "0",
    title: "",
    channelId: "",
    channelTitle: "",
    categoryId: "",
    viewCount: "0",
    description: ""
  };

  const [videoDetail, setVideoDetail] = useState<VideoDetail>(defaultVideoDetail);



  let duration = ""
  const intervalRef = useRef<any>() //used for interval tracking, doesn't invoke rerenders and persists across renders

  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 768px)" })
  
  //These subtitles indexes are for the actual index value stored in to each subtitle under key i. There is a separate index for tiles as that list changes
  const [activeSubtitleIndex, setActiveSubtitleIndex] = useState(-1) //activate index of selected user lang
  const [activeSubtitleIndexOrig, setActiveSubtitleIndexOrig] = useState(-1) //activate index of orig lang
  const [activeChapterIndex, setActiveChapterIndex] = useState(-1) //activate index of selected user lang
  //Refs needed to access latest values when within closures like the setIntervals inside the useEffects
  const activeSubtitleIndexRef = useRef(activeSubtitleIndex); //need refs to help prevent setting state var mult times on change
  const activeSubtitleIndexOrigRef = useRef(activeSubtitleIndexOrig);
  const lastSubtitleIndexRef = useRef(-1); // Tracks the last active subtitle index
  const lastSubtitleIndexOrigRef = useRef(-1); // Tracks the last active subtitle index
  const currentStartRef = useRef(0); // Start time of current active sub
  const currentStartOrigRef = useRef(0); // Start time of current active sub
  const currentEndRef = useRef(0); // End time of current active sub
  const currentEndOrigRef = useRef(0); // End time of current active sub
  const nextStartRef = useRef(0); // Start time of next sub after active one
  const nextStartOrigRef = useRef(0); // Start time of next sub after active one
  // These index values pertain to Tile indexes not the actual subtitle's index key "i" that's stored with each sub
  //   these are used to keep track of which tiles are active because the list of tiles displayed might be filtered
  //   by a search term or by bookmarks
  const [activeTilePosSubIndex, setActiveTilePosSubIndex] = useState(-1) //active index of selected user lang
  const [activeTilePosSubIndexOrig, setActiveTilePosSubIndexOrig] = useState(-1) //active index of orig lang

  //Booksmarks playback
  const [playBookmarksOnly, setPlayBookmarksOnly] = useState(true) //defaults to only only bookmarks on bookmarks tab
  const [currentBookmarkIndex, setCurrentBookmarkIndex] = useState(0);
  const [isPlayingBookmarks, setIsPlayingBookmarks] = useState(false);

  

  const [subtitlesEnabled, setSubtitlesEnabled] = useState(false)
  const [subtitlesOrigEnabled, setSubtitlesOrigEnabled] = useState(false)

  // Add a state to store the active subtitle
  const [activeSubtitle, setActiveSubtitle] = useState<Subtitle | null>(null)
  const [videos, setVideos] = useState(null)

  //Video playback
  const [playing, setPlaying] = useState(false)
  const playingRef = useRef(playing); // to access current value inside closures
  const [playbackRate, setPlaybackRate] = useState(1)
  const [count, setCount] = useState(0)
  const [loopCount, setLoopCount] = useState(1)
  const [currentTime, setCurrentTime] = useState(0)
  const [durationRP, setDurationRP] = useState(0)

  const playerRef = useRef<ReactPlayer>(null)
  //const { id } = useParams<{ id: string }>() //get the id from route param
  const [id, setId]  = useState("")
  // const [videoDetail, setVideoDetail] = useState(null) //contains the YT response payload from api call

  //store the keys from subtitles_json to populate the select lang popup
  const [subtitleLanguagesGen, setSubtitleLanguagesGen] = useState<string[]>([]) //list of original processed subtitles
  const [subtitleLanguages, setSubtitleLanguages] = useState<string[]>([]) //all subtitles including custom subs
  const [custSubtitles, setCustSubtitles] = useState<string[]>([]) //custom subs only

  // Add a ref to store the active button
  const activeButtonRef = useRef<HTMLIonButtonElement>(null)

  //const buttonRefs = useRef<HTMLIonButtonElement[]>([]);
  const buttonRefs = useRef<(HTMLIonCardElement | null)[]>([])

  const [isFavorite, setIsFavorite] = useState(false) // only client side datat

  const [firstLoad, setFirstLoad] = useState(true)

  // DB operations
  const [languageCode, setLanguageCode] = useState("en")
  const [languageCodeOrig, setLanguageCodeOrig] = useState("en")
  const [vttLoaded, setVttLoaded] = useState<Subtitle[]>([]) //user selected-target language of video subtitle
  const [vttLoadedOrig, setVttLoadedOrig] = useState<Subtitle[]>([]) //original-native language of video subtitle
  const [vttLoadedOrigRom, setVttLoadedOrigRom] = useState<Subtitle[]>([]) //for mandarin, japanese, korean phonetics also loaded

  const [chaptersLoaded, setChaptersLoaded] = useState<Subtitle[]>([]) //chapters data for video
  //tiles that are bookmarked for view purposes, this list should usually be stable unless the view is changed, allowing the user to add or remove bookmarks without view changing
  const [bookmarksLoaded, setBookmarksLoaded] = useState<number[]>([]) // View: selected language bookmarks for the video, only client side data, format ex: [2,15,33], used for display between segments switches
  //actual bookmarks list that are used to manipulate, add or remove tiles. This allows the view to remain stable while the user adds/removes bookmarks
  const [bookmarkedTiles, setBookmarkedTiles] = useState<number[]>([]) // Temp to hold changes before saving: selected language bookmarks for the video, used to change bookmarks data
  const [tilesFilterValue, setTilesFilterValue] = useState<string>("all") //determines how the tiles are filtered, default is all, other values are bookmarked, chapters
  const [tilesDisplayOrig, setTilesDisplayOrig] = useState(false) //determines which tiles are displayed, default is "false", which is display the selected tiles

  //Accordion subtitles files
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null)
  const [popoverContent, setPopoverContent] = useState("")
  //const [popoverContentOrig, setPopoverContentOrig] = useState('');
  const [videoLengthSeconds, setVideoLengthSeconds] = useState(0)
  const [processingMessage, setProcessingMessage] = useState("")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const location = useLocation()

  //Subtitle Editing
  const [editingSubtitleIndex, setEditingSubtitleIndex] = useState<
    number | null
  >(null)
  const [isSubtitleEditorOpen, setIsSubtitleEditorOpen] = useState(false)
  const [editedSubtitle, setEditedSubtitle] = useState({
    s: "",
    e: "",
    t: "",
  })

  const [noteContent, setNoteContent] = useState("")

  //slate-react editor
  //const editor = useMemo(() => withReact(createEditor()), []);
  //const [value, setValue] = useState<CustomDescendant[]>(initialValue);

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("")

  //Subtitle tiles create
  const [showModal, setShowModal] = useState(false)
  const [custName, setCustName] = useState("")
  const [subtitleType, setSubtitleType] = useState("copy") // New state for radio button selection
  const [custSubtitlesList, setCustSubtitlesList] = useState<string[]>([]) //*****CUSTOM subs
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [subtitleToDelete, setSubtitleToDelete] = useState("")
  const [showMediaCard, setShowMediaCard] = useState(false)

  const [searchTerm, setSearchTerm] = useState("")
  const [useTileSubsOrig, setUseTileSubsOrig] = useState(false)


  const [subtitles, setSubtitles] = useState<Subtitle[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [videoUrl, setVideoUrl] = useState("");
  const videoFileInputRef = useRef<HTMLInputElement>(null);

  const addWordToRecents = (wordObj) => {
    console.log("called addWordToRecents on local player")
  }


//Load Local Video file
const handleSelectVideoFile = () => {
  videoFileInputRef.current?.click();
};

const generateVideoId = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  // Convert the 32bit hash to a 16 character hexadecimal string
  let hexHash = Math.abs(hash).toString(16);
  while (hexHash.length < 16) {
    hexHash = '0' + hexHash; // Pad with leading zeros if necessary
  }

  return hexHash.slice(0, 16); // Truncate to 16 characters
};

const handleVideoFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files && event.target.files[0]) {
      const videoFile = event.target.files[0];
      const videoUrl = URL.createObjectURL(videoFile);
      const videoId = generateVideoId(videoFile.name); // Generate a unique ID

      // Set videoDetail state
      setVideoDetail({
        lengthSeconds: "0", // You may need to adjust this if you can calculate the length
        title: videoFile.name,
        channelId: "LOCAL",
        channelTitle: "LOCAL",
        categoryId: "", // Set this accordingly if needed
        viewCount: "0", // Set this accordingly if needed
        description:"",
      });
      console.log("Setting videoUrl to local file: ", videoUrl);
      setVideoUrl(videoUrl); // Update the state with the new video URL
      setId(videoId); // Update the state with the new video ID

  }
};

//parse SRT subtitle file
const parseSRTContent = (content: string): Subtitle[] => {
  const subtitles: Subtitle[] = [];
  const lines = content.split(/\r\n|\n|\r/);
  let index = 0;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    // SRT timecode format: '00:00:00,000 --> 00:00:02,000'
    const timecodeMatch = line.match(/^(\d{2}:\d{2}:\d{2},\d{3}) --> (\d{2}:\d{2}:\d{2},\d{3})/);

    if (timecodeMatch) {
      const textLines: string[] = [];
      i++;

      while (i < lines.length && lines[i].trim() !== '') {
        textLines.push(lines[i].trim());
        i++;
      }

      const text = textLines.join('\n');
      subtitles.push({
        i: ++index,
        s: timecodeMatch[1].replace(',', '.'), // Convert ',' to '.' for consistency
        e: timecodeMatch[2].replace(',', '.'),
        t: text
      });
    }
  }

  return subtitles;
};

  //Parse VTT subtitle file
  const parseVTTContent = (content: string): Subtitle[] => {
    const subtitles: Subtitle[] = [];
    const lines = content.split(/\r\n|\n|\r/);
    let index = 0;
  
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      const timecodeMatch = line.match(/^(\d{2}:\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}:\d{2}\.\d{3})/);
      
      if (timecodeMatch) {
        // Explicitly declare textLines as an array of strings
        const textLines: string[] = [];
        i++;
  
        while (i < lines.length && lines[i].trim() !== '') {
          textLines.push(lines[i].trim());
          i++;
        }
  
        const text = textLines.join('\n');
        subtitles.push({
          i: ++index,
          s: timecodeMatch[1],
          e: timecodeMatch[2],
          t: text
        });
      }
    }
  
    return subtitles;
  };
  
  

  // const parseVTTFile = (file: File): Promise<Subtitle[]> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const content = (event.target as FileReader).result;
  //       if (typeof content === 'string') {
  //         const subtitles = parseVTTContent(content);
  //         resolve(subtitles);
  //       } else {
  //         reject(new Error('Failed to read file content as string'));
  //       }
  //     };
  //     reader.onerror = (error) => reject(error);
  
  //     // Reading the file
  //     reader.readAsText(file);
  //   });
  // };
  const parseSubsFile = (file: File): Promise<Subtitle[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = (event.target as FileReader).result;
        if (typeof content === 'string') {
          // Determine file type (VTT or SRT) based on file extension
          const fileType = file.name.split('.').pop()?.toLowerCase();
          // let subtitles = [];
          let subtitles: Subtitle[] = [];
  
          if (fileType === 'vtt') {
            subtitles = parseVTTContent(content);
          } else if (fileType === 'srt') {
            subtitles = parseSRTContent(content);
          } else {
            reject(new Error('Unsupported file type'));
            return;
          }
  
          resolve(subtitles);
        } else {
          reject(new Error('Failed to read file content as string'));
        }
      };
      reader.onerror = (error) => reject(error);
  
      // Reading the file
      reader.readAsText(file);
    });
  };
  

const handleSelectSubsFile = () => {
    fileInputRef.current?.click();
  };

// Handler for file selection
// Handler for file selection
// Handler for VTT file selection
const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target?.files?.length) {
    const file = event.target.files[0];
    parseSubsFile(file)
      .then(async parsedSubtitles => {
        // Set the parsed subtitles to both vttLoaded and vttLoadedOrig
        setVttLoaded(parsedSubtitles);
        setVttLoadedOrig(parsedSubtitles); // Add this line
        console.log(parsedSubtitles);
        //moved to useEffect to load custom subtitles: Load bookmarkfiles since we have subtitles now
        // const bookmarks = await bookmarkTilesService.getBookmarks(
        //   id,
        //   'en'
        // )
        // console.log("Got bookmarks: ", id, bookmarks);
        // setBookmarksLoaded(bookmarks)
        // setBookmarkedTiles(bookmarks)
      })
      .catch(error => console.error('Error parsing VTT file:', error));
  }
};


// Set Chapters data
const processVideoDetails = (videoDetail) => {
  //set videoDetail state var
  setVideoDetail(videoDetail)
  console.log("processing videoDetails: ", videoDetail)
  //extract chapter markers if any
  const chaps = parseDescription(
    videoDetail["description"],
    videoDetail["lengthSeconds"]
  )
  if (chaps) {
    console.log("Setting Chapters: ", videoDetail["lengthSeconds"], chaps)
    setChaptersLoaded(chaps)
  }
}

function parseDescription(
  description: string,
  lengthSeconds: string
): Subtitle[] {
  const chapterPattern = /(\d{1,2}:\d{1,2}(?::\d{1,2})?) (.+)/g
  let match: RegExpExecArray | null
  const matches: RegExpExecArray[] = []

  while ((match = chapterPattern.exec(description)) !== null) {
    matches.push(match)
  }

  const chapters: Subtitle[] = []
  matches.forEach((match, index) => {
    const nextTime = index + 1 < matches.length ? matches[index + 1][1] : null

    const currentTimeParts = match[1].split(":").map(Number)
    const nextTimeParts = nextTime ? nextTime.split(":").map(Number) : null

    let endSeconds
    if (nextTimeParts) {
      switch (nextTimeParts.length) {
        case 2:
          // ending should be 100ms less than the start of the next segment
          endSeconds = (nextTimeParts[0] * 60 + nextTimeParts[1]) * 1000 - 100
          break
        case 3:
          endSeconds =
            (nextTimeParts[0] * 3600 +
              nextTimeParts[1] * 60 +
              nextTimeParts[2]) *
              1000 -
            100
          break
        default:
          break
      }
    } else if (index === matches.length - 1) {
      // this is the last chapter
      endSeconds = parseInt(lengthSeconds) * 1000
    } else {
      endSeconds = null
    }

    const endTime = new Date(endSeconds)
    const endHours = String(endTime.getUTCHours()).padStart(2, "0")
    const endMinutes = String(endTime.getUTCMinutes()).padStart(2, "0")
    const endSecondsFormatted = String(endTime.getUTCSeconds()).padStart(
      2,
      "0"
    )

    let startTime = match[1]
    switch (currentTimeParts.length) {
      case 2:
        startTime = formatTime(
          `00:${currentTimeParts[0]}:${currentTimeParts[1]}`
        )
        break
      case 3:
        startTime = formatTime(match[1])
        break
      default:
        break
    }

    chapters.push({
      //i: (index + 1).toString(),
      i: index + 1,
      s: startTime,
      e: endSeconds
        ? `${endHours}:${endMinutes}:${endSecondsFormatted}`
        : "End",
      t: match[2],
    })
  })

  return chapters
}






//called when 
const handleDuration = (duration) => {
  setDurationRP(duration);
};

  //Search algo to find the index of a subtitle by time
  function binarySearchSubtitles(subtitles, currentTime) {
    let low = 0;
    let high = subtitles.length - 1;
  
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const start = convertToSeconds(subtitles[mid].s);
      const end = convertToSeconds(subtitles[mid].e);
  
      if (start <= currentTime && currentTime <= end) {
        return mid;
      } else if (currentTime < start) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }
    return -1; // not found
  }

  //Search algo to find the positional index of a subtitle by the specific sub's id value
  function binarySearchById(subtitles, targetId) {
    let low = 0;
    let high = subtitles.length - 1;

    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const midIndex = subtitles[mid].i;

        if (midIndex === targetId) {
            return mid;
        } else if (targetId < midIndex) {
            high = mid - 1;
        } else {
            low = mid + 1;
        }
    }
    return -1; // Target index not found
}

  const openMediaCard = () => {
    setShowMediaCard(true)
  }

  // Tile searching functions, uses filteredSubtitles which is used to render the subtitle tiles ***
  const handleTileSearch = (e: any) => {
    setSearchTerm(e.detail.value)
  }
  const subtitlesToFilter = useTileSubsOrig ? vttLoadedOrig : vttLoaded
  // const filteredSubtitles = subtitlesToFilter.filter((subtitle) =>
  //   subtitle.t.toLowerCase().includes(searchTerm.toLowerCase())
  // )

  const filteredSubtitles = subtitlesToFilter
  .filter(subtitle =>
    subtitle.t.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .filter((_, index) =>
    tilesFilterValue !== "bookmarked" || bookmarksLoaded.includes(index)
  );

  const matchCount = filteredSubtitles.length


  const handleDeleteSubtitle = async () => {
    const videoSubtitlesKey = `c_subtitles_${id}`
    const existingSubtitlesString = await storage.get(videoSubtitlesKey)
    let existingSubtitles = existingSubtitlesString
      ? JSON.parse(existingSubtitlesString)
      : {}

    delete existingSubtitles[subtitleToDelete] // Delete the selected subtitle

    await storage.set(videoSubtitlesKey, JSON.stringify(existingSubtitles))
    setToastMessage(`Subtitle '${subtitleToDelete}' deleted successfully.`)
    setShowToast(true)
    fetchSubtitlesList() // Refresh the list
    setShowDeleteAlert(false) // Close the alert
  }

  // Fetch and update the list of CUSTOM subtitles
  const fetchSubtitlesList = async () => {
    const videoSubtitlesKey = `c_subtitles_${id}`
    const existingSubtitlesString = await storage.get(videoSubtitlesKey)
    const existingSubtitles = existingSubtitlesString
      ? JSON.parse(existingSubtitlesString)
      : {}
    setCustSubtitlesList(Object.keys(existingSubtitles))
  }

  // const handleCreateSubtitles = async () => {
  //   if (!custName.trim()) {
  //     setToastMessage("Please enter a name for the subtitles.")
  //     setShowToast(true)
  //     return
  //   }

  //   const videoSubtitlesKey = `c_subtitles_${id}`
  //   // let existingSubtitles = await storage.get(videoSubtitlesKey) || {};
  //   const existingSubtitlesString = await storage.get(videoSubtitlesKey)
  //   let existingSubtitles = existingSubtitlesString
  //     ? JSON.parse(existingSubtitlesString)
  //     : {}
  //   console.log("Custom SUBS: ", existingSubtitles)
  //   let uniqueName = custName.trim()
  //   let counter = 1

  //   // Check if the name exists and find a unique name
  //   while (existingSubtitles.hasOwnProperty(uniqueName)) {
  //     uniqueName = `${custName.trim()}-${counter}`
  //     counter++
  //   }

  //   let newSubtitles = [...vttLoaded] // Copy the existing subtitles

  //   // If selected blank option, blank out the 't' values
  //   if (subtitleType === "blank") {
  //     newSubtitles = newSubtitles.map((subtitle) => ({ ...subtitle, t: "" }))
  //   }

  //   console.log("saving subtitle name: " + uniqueName)

  //   try {
  //     // Update the CUSTOM subtitles for this video
  //     existingSubtitles[uniqueName] = newSubtitles
  //     await storage.set(videoSubtitlesKey, JSON.stringify(existingSubtitles))
  //     setCustSubtitlesList(Object.keys(existingSubtitles)) //update state variable for cust subs
  //     setToastMessage(`Subtitles '${uniqueName}' created successfully.`)
  //     setShowToast(true)
  //   } catch (error) {
  //     console.error("Error saving subtitles:", error)
  //     setToastMessage("Error saving subtitles.")
  //     setShowToast(true)
  //   }
  //   setCustName("")
  //   setShowModal(false) // Close the modal
  // }
  const handleCreateSubtitles = async () => {
    if (!custName.trim()) {
      setToastMessage("Please enter a name for the subtitles.")
      setShowToast(true)
      return
    }

    const videoSubtitlesKey = `c_subtitles_${id}`
    const existingSubtitlesString = await storage.get(videoSubtitlesKey)
    let existingSubtitles = existingSubtitlesString
      ? JSON.parse(existingSubtitlesString)
      : {}
    console.log("Custom SUBS: ", existingSubtitles)
    let uniqueName = custName.trim()
    let counter = 1

    // Check if the name exists and find a unique name
    while (existingSubtitles.hasOwnProperty(uniqueName)) {
      uniqueName = `${custName.trim()}-${counter}`
      counter++
    }

    let newSubtitles: Subtitle[] = [];

    // Generate blank subtitles based on video duration if vttLoaded is blank
    if (!vttLoaded.length) {
      let startTime = 1; // Start from 1 second
      let endTime = 4.65; // End time for the first subtitle
      let index = 1; // Starting index

      //Del this code, since it has leading zeros in front of seconds
      // while (startTime < durationRP) { // Use durationRP to limit the loop
      //   let start = new Date(startTime * 1000).toISOString().substr(11, 12);
      //   let end = new Date(endTime * 1000).toISOString().substr(11, 12);
      //   let adjustedEnd = Math.min(endTime, durationRP); // Ensure end time does not exceed video duration
      //   let endFormatted = new Date(adjustedEnd * 1000).toISOString().substr(11, 12);

      //   newSubtitles.push({
      //     i: index,
      //     s: start.substring(0, start.length - 1), // Format to "HH:MM:SS.ms"
      //     e: endFormatted.substring(0, endFormatted.length - 1), // Ensure it matches required format
      //     t: ""
      //   });
      while (startTime < durationRP) {
        let endTime = startTime + 4.65; // Define endTime for each subtitle block
      
        // Formatting for startTime and endTime
        let startHours = Math.floor(startTime / 3600);
        let startMinutes = Math.floor((startTime % 3600) / 60);
        let startSeconds = (startTime % 60).toFixed(2); // Keeping two decimal places for milliseconds
      
        let endHours = Math.floor(endTime / 3600);
        let endMinutes = Math.floor((endTime % 3600) / 60);
        let endSeconds = (endTime % 60).toFixed(2); // Keeping two decimal places for milliseconds
      
        // Constructing time strings in the format "HH:MM:SS.ms"
        let startFormatted = `${startHours.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}:${startSeconds}`;
        let endFormatted = `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}:${endSeconds}`;
      
        newSubtitles.push({
          i: index,
          s: startFormatted,
          e: endFormatted,
          t: ""
        });
      
        // Check if the end time for the current subtitle exceeds or matches the video duration
        if (endTime >= durationRP) {
          break; // Stop adding more subtitles if we've reached or exceeded the video's duration
        }
      
        startTime += 5; // Increment startTime for the next subtitle block by subtitle duration + gap
        index++; // Increment subtitle index
      }
    } else {
      newSubtitles = [...vttLoaded]; // Copy existing subtitles if loaded
    }

    // If selected blank option, blank out the 't' values
    if (subtitleType === "blank") {
      newSubtitles = newSubtitles.map(subtitle => ({ ...subtitle, t: "" }));
    }

    console.log("saving subtitle name: " + uniqueName);

    try {
      // Update the CUSTOM subtitles for this video
      existingSubtitles[uniqueName] = newSubtitles;
      await storage.set(videoSubtitlesKey, JSON.stringify(existingSubtitles));
      
      //Load the newly created subtitles
      setLanguageCode(uniqueName)
      setVttLoaded(newSubtitles);

      setCustSubtitlesList(Object.keys(existingSubtitles)); // Update state variable for cust subs
      setToastMessage(`Subtitles '${uniqueName}' created successfully.`);
      setShowToast(true);
    } catch (error) {
      console.error("Error saving subtitles:", error);
      setToastMessage("Error saving subtitles.");
      setShowToast(true);
    }
    setCustName("");
    setShowModal(false); // Close the modal
}

  //Save note to the video's own key 'note_videoId'----------------------
  const handleSaveNote = async () => {
    try {
      const noteKey = `note_${id}` // Assuming 'id' is the videoId
      if (noteContent.trim()) {
        // Save video info only if videoDetail is not null
        let videoData = {}
        if (videoDetail) {
          videoData = {
            id: id,
            title: videoDetail["title"],
            channelTitle: videoDetail["channelTitle"],
            channelId: videoDetail["channelId"],
            description: videoDetail["title"],
            thumbnail: "assets/generic.png",
          }
          // Optionally, handle videoData here if needed
        }

        // If noteContent is not empty, save it as an object to Ionic Storage
        const noteObject = { text: noteContent, videoData: videoData }
        await storage.set(noteKey, noteObject)
        setToastMessage("Note saved successfully.")
      } else {
        // If noteContent is empty, remove the key from Ionic Storage
        await storage.remove(noteKey)
        setToastMessage("Note was empty, so it was removed.")
      }
      setShowToast(true)
    } catch (error) {
      console.error("Failed to save or remove note:", error)
      setToastMessage("Failed to save note.")
      setShowToast(true)
    }
  }

  const copyToClipboard = async () => {
    if (noteContent.trim()) {
      await Clipboard.write({
        string: noteContent.trim(),
      })
      setToastMessage("Note copied to clipboard.")
      setShowToast(true)
    }
  }

  //User clicked on tile button to open sub title editor
  const handleEditSubtitle = (subtitle, event) => {
    event.stopPropagation()
    setEditingSubtitleIndex(vttLoaded.indexOf(subtitle))
    console.log("[Called handleEditSubtitle]", subtitle)
    setEditedSubtitle(subtitle)
    setIsSubtitleEditorOpen(true)
  }

  //User clicked on save button in subs editor
  const handleSaveEditedSubtitle = (editedSubtitle) => {

    if (editingSubtitleIndex !== null) {
      let updatedLangSubtitles = [...vttLoaded]
      updatedLangSubtitles[editingSubtitleIndex] = editedSubtitle
      setVttLoaded(updatedLangSubtitles)

      const isCustomSubtitle = custSubtitlesList.includes(languageCode)
      updateStorageWithSubtitles(updatedLangSubtitles, isCustomSubtitle)
    }

    setIsSubtitleEditorOpen(false)
  }

  const updateStorageWithSubtitles = async (
    updatedLangSubtitles,
    isCustom = false
  ) => {
    try {
      const videoSubtitlesKey = isCustom
        ? `c_subtitles_${id}`
        : `subtitles_${id}`

      // Fetch existing subtitles for the video from Ionic Storage
      // const storedData = await storage.get(`subtitles_${id}`)
      const storedData = await storage.get(videoSubtitlesKey)
      // let subtitlesData = JSON.parse(storedData || "{}")
      let subtitlesData = storedData ? JSON.parse(storedData) : {}

      // Update the specific language subtitles
      // subtitlesData.subs[languageCode] = updatedLangSubtitles
      if (isCustom) {
        subtitlesData[languageCode] = updatedLangSubtitles
      } else {
        // Assuming standard subtitles are stored differently
        subtitlesData.subs[languageCode] = updatedLangSubtitles
      }
console.log("[updateStorageWithSubtitles]: ",videoSubtitlesKey, languageCode, subtitlesData );
      // Save the updated subtitles back to Ionic Storage
      await storage.set(videoSubtitlesKey, JSON.stringify(subtitlesData))
      console.log(`Subtitles for ${languageCode} saved to Ionic Storage`)
    } catch (error) {
      console.error("Error updating subtitle:", error)
    }
  }

  //udpate playing status for non-ui components to get the current state
  useEffect(() => {
    playingRef.current = playing;
  }, [playing]);

  useEffect(() => {
    setPlaying(location?.pathname.includes("/video/") && !firstLoad)
  }, [location, firstLoad])

  //Save subtitles to local storage
  const storeSubtitles = async (
    videoId: string,
    subtitles_json: object,
    orig_lang: string
  ) => {
    try {
      // Save subtitles to Ionic Storage
      await storage.set(
        `subtitles_${videoId}`,
        JSON.stringify({ subs: subtitles_json, origLang: orig_lang })
      )
      console.log("Subtitles saved to Ionic Storage")
      // Store the keys from subtitles_json in subtitleLanguages to indicate available subs languages, <--- DELETE?
      //setSubtitleLanguages(Object.keys(subtitles_json))
    } catch (error) {
      console.error("Error storing subtitles:", error)
    }
  }

  //Attempt to retrieve subs from local storage
  const retrieveSubtitles = async (video_id: string, langCode: string) => {
    try {
      //const subtitles = await storage.get(`subtitles_${video_id}`);
      const storedData = await storage.get(`subtitles_${video_id}`)
      //const subtitles_json = JSON.parse(subtitles);
      const metaData = JSON.parse(storedData)

      const videoSubtitlesKey = `c_subtitles_${id}`
      const customSubtitlesString = await storage.get(videoSubtitlesKey)
      const customSubtitles = customSubtitlesString
        ? JSON.parse(customSubtitlesString)
        : {}
      const customSubtitleNames = Object.keys(customSubtitles)
      setCustSubtitles(customSubtitleNames) //save list of custom subs

      //if (subtitles_json) {
      if (metaData && metaData.subs) {
        //get orig language
        let orig_lang

        orig_lang = metaData["origLang"]
        setLanguageCodeOrig(orig_lang) // Update state variable
        const subtitles_json = metaData["subs"]

        setSubtitleLanguagesGen(
          Object.keys(subtitles_json).filter((key) => !key.endsWith("rom"))
        ) //save list of generated subs provided by BE

        // Combine standard and custom subtitles to create an ALL list of subs
        const allSubtitleLanguages = [
          ...Object.keys(metaData.subs).filter((key) => !key.endsWith("rom")),
          ...customSubtitleNames,
        ]
        setSubtitleLanguages(allSubtitleLanguages)

        // load selected language subs
        if (subtitles_json[langCode]) {
          setVttLoaded(subtitles_json[langCode])
          // console.log(`Subtitles for SELECTED lang '${langCode}' retrieved from Ionic Storage:`, subtitles_json[langCode])

          //Load bookmarks from local storage (indexedDB) if not already set
          //This will will be the set of tiles the user interacts with, meaning this array does not change until the next "refresh"
          // i.e. user switches to all tiles view or navigates out and back to video. The indexes are used to only toggle the bookmark in local storage
          //So initial load of bookmarks happens here and then subsequent refreshes happen as user switches tile views from All to Bookmarks.
          const bookmarks = await bookmarkTilesService.getBookmarks(
            video_id,
            langCode
          )
          setBookmarksLoaded(bookmarks)
          setBookmarkedTiles(bookmarks)
        }
        // load orig language subs
        if (subtitles_json[orig_lang]) {
          setVttLoadedOrig(subtitles_json[orig_lang])

          //for the 3 languages zh, ja, ko, add the romanization
          const key = orig_lang.slice(0, 2) + "rom" // Extract the first two characters of orig_lang and append 'rom'
          setVttLoadedOrigRom(subtitles_json[key] ? subtitles_json[key] : [])

          // console.log(`Subtitles for ORIG lang '${orig_lang}' retrieved from Ionic Storage:`,subtitles_json[orig_lang])
        }
        return subtitles_json[langCode] || []
      } else {
        console.log(`No subtitles found for '${langCode}' in Ionic Storage.`)
        // Clear the generated buttons if there are no subtitles
        setVttLoaded([])
        return []
      }
    } catch (error) {
      console.error("Error retrieving subtitles:", error)
      return []
    }
  }



  //--------------------*** HANDLERS ***/

  const handleButtonClickChapter = (
    startTime: string,
    endTime: string,
    subtitle: Subtitle
  ) => {
    //Clear any existing intervals
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  const loopCountRef = useRef(0) // Ref for tracking loop count

  //Subtitle Tile Clicked: Seek to Tile location and repeat the number of times indicated in the loop dropdown
  const handleButtonClick = (startTime, endTime, index) => {
    // Clear any existing intervals
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    console.log("Clicked on Sub and Tile indexes: ", index)
    const startSeconds = convertToSeconds(startTime)
    const endSeconds = convertToSeconds(endTime)
    
    setPlaying(true)
    loopCountRef.current = 0 // Reset loop count
    playerRef.current?.seekTo(startSeconds, "seconds")
    //reset to where the user has clicked the index, if in the bookmarks tab
    if (tilesFilterValue == "bookmarked") {
      const nextIndex = index < filteredSubtitles.length -1 ? index +1 : index;
      setCurrentBookmarkIndex(nextIndex)
    }

    const interval = setInterval(() => {
      if ((playerRef.current?.getCurrentTime() ?? 0) >= endSeconds) {
        loopCountRef.current += 1
        console.log(`Loop count: ${loopCountRef.current}`)

        if (loopCountRef.current >= loopCount) {
          clearInterval(interval)
          setPlaying(false) // Pause playback after reaching loop count
          intervalRef.current = null
        } else {
          playerRef.current?.seekTo(startSeconds, "seconds")
        }
      }
    }, 200)

    intervalRef.current = interval // Store the current interval
  }

  const handleLoopCountChange = (event) => {
    const loopCount = parseInt(event.detail.value, 10)
    if (!isNaN(loopCount)) {
      setLoopCount(loopCount)
      console.log("Setting Loop Count from Dropdown: " + loopCount)
    }
  }

//------Bookmarks playback ******

  const onVideoProgress = ({ playedSeconds }) => {
    setCurrentTime(playedSeconds);
    if (isPlayingBookmarks && currentBookmarkIndex > 0 && playedSeconds >= convertToSeconds(filteredSubtitles[currentBookmarkIndex - 1].e)) {
      playNextBookmarkedSubtitle();
    }
  };

  const playNextBookmarkedSubtitle = () => {
    if (currentBookmarkIndex < filteredSubtitles.length) {
      const subtitle = filteredSubtitles[currentBookmarkIndex];
      playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
      setCurrentBookmarkIndex(currentBookmarkIndex + 1);
    } else {
      // Reset at the end of the list
      setIsPlayingBookmarks(false);
      setPlaying(false);
      console.log("PLAYER: Set to STOP, STOP NOW!!!!!!!");
      setCurrentBookmarkIndex(0);
    }
  };
  

  const handlePlayPause = () => {
    // Check if the app is in the mode to play only bookmarked subtitles
    if (playBookmarksOnly && tilesFilterValue === "bookmarked") {
      if (isPlayingBookmarks) {
        // If already playing bookmarks, stop the playback
        setIsPlayingBookmarks(false);
        setPlaying(false); // Also stop the video playback
      } else {
        // Start playing bookmarks from the current index
        playNextBookmarkedSubtitle();
        setIsPlayingBookmarks(true);
        setPlaying(true); // Start the video playback if it's not already playing
      }
    } else {
      // Standard play/pause for continuous video playback
      if (playing && intervalRef.current) {
        // Clear the looping interval
        clearInterval(intervalRef.current);
        intervalRef.current = null;
  
        // Reset any states if necessary
        setCount(0); // Assuming 'count' is used to track the current loop iteration
      }
  
      // Toggling the playing state for standard playback
      setPlaying(!playing);
    }
  };
  

  const handleSetPlaybackRate = (rate) => {
    setPlaybackRate(rate)
  }

  const handleBackFive = () => {
    console.log("Clicked handleBackFive")
    if (playerRef.current?.getCurrentTime()) {
      const currentTime = Math.abs(playerRef.current?.getCurrentTime())
      console.log("Clicked handleBackFive: " + currentTime)
      playerRef.current?.seekTo(currentTime - 5)
    } else {
      playerRef.current?.seekTo(0)
    }
  }

  const handleForwardFive = () => {
    console.log("Clicked handleForwardFive")
    if (playerRef.current?.getCurrentTime()) {
      const currentTime = playerRef.current?.getCurrentTime()
      playerRef.current?.seekTo(currentTime + 5)
    } else {
      playerRef.current?.seekTo(5)
    }
  }

  const toggleOverlay = (key) => {
    if (key === "subtitlesEnabled") {
      setSubtitlesEnabled((prev) => !prev)
      setSubtitlesOrigEnabled(false)
    } else if (key === "subtitlesOrigEnabled") {
      setSubtitlesOrigEnabled((prev) => !prev)
      setSubtitlesEnabled(false)
    }
  }

  const handleFavoriteClick = async () => {
    try {
      if (isFavorite) {
        await favoriteVideosService.removeFromFavorites(id)
        setIsFavorite(false)
      } else {
        //await favoriteVideosService.addToFavorites({id: { videoId }, video});
        await favoriteVideosService.addToFavorites(videoDetail)
        setIsFavorite(true)
      }

      // Updating the favorite status after user interaction
      //setIsFavorite(!isFavorite);
    } catch (error) {
      console.error(error)
    }
  }


  const handleBookmarkClick = async (subtitle, event) => {
    event.stopPropagation()

    // Determine the correct set of subtitles and index based on useTileSubsOrig
    const subtitles = useTileSubsOrig ? vttLoadedOrig : vttLoaded
    // Find the actual index against the full subtitles unfiltered list, user currently maybe  viewing a filtered bookmark list
    const subtitleIndex = subtitles.indexOf(subtitle)

    // Choose the appropriate language code
    const currentLanguageCode = useTileSubsOrig
      ? languageCodeOrig
      : languageCode

    try {
      const currBookmarks = await bookmarkTilesService.toggleBookmark(
        id,
        currentLanguageCode,
        subtitleIndex
      )
      setBookmarkedTiles(currBookmarks)
    } catch (error) {
      console.error("Error handling bookmark click:", error)
    }
  }

  //Sets the view for the tiles: all, bookmarked, or chapter tiles
  const handleSegmentClick = async (segVal) => {
    setTilesFilterValue(segVal)
    setBookmarksLoaded(bookmarkedTiles)
    setSearchTerm("") //clear search term whenever user clicks around, this avoids confusion of having filtered tiles when they return back.
  }

  //--------------------*** useEffects ***---------------------------/

  useEffect(() => {
    return () => {
      console.log("ORIG language changed to: " + languageCodeOrig)
    }
  }, [languageCodeOrig])

  const vttLoadedOrigRef = useRef(vttLoadedOrig)
  useEffect(() => {
    vttLoadedOrigRef.current = vttLoadedOrig
  }, [vttLoadedOrig])

  // INITIALIZE Video Info: when video is first loaded to attempt to retreive subs locally, then from BE


//TARGET TILE HIGHLIGHT select idx: Set video user selected active sub index so the correct button can be highlighted
// Continuously runs when the user is playing a video, interval checks will stop when video is paused.
// Note: activeSubtitleIndex and activeSubtitleIndexRef represent the subtitle.i index, the absolute index for that language's subtitle
//       activeTileSubIndex and activeTileSubIndexOrig are the relative index of the tile in the current list which changes based on filtering criteria
useEffect(() => {
  if (!vttLoaded.length) {
    // Potentially reset some state here if needed
    return;
  }
  let interval;
  const startInterval = () => {
    interval = setInterval(() => {
      // console.log("Player is playing: ", playingRef.current);
      if (!playerRef.current || !playingRef.current) return;
      const currentTime = playerRef.current.getCurrentTime();

      let currIndex = activeSubtitleIndexRef.current; //current sub index
      let nextIndex = currIndex < vttLoaded.length - 1 ? currIndex + 1 : currIndex; //next sub index
      let newIndex = -1;

      // Update current subtitle times if we have a new index from the last iteration
      if (currIndex !== lastSubtitleIndexRef.current) {
        if (currIndex < vttLoaded.length) {
          //Edge case where user has clicked on first tile in Original sub tiles which has different timings and this Target tile has no corresponding start time yet
          if (currIndex == -1) {
            currIndex = 0
          }
          //console.log("Updating current subtitle start end times for index: ", currIndex,lastSubtitleIndexRef.current, vttLoaded);
          currentStartRef.current = convertToSeconds(vttLoaded[currIndex].s);
          currentEndRef.current = convertToSeconds(vttLoaded[currIndex].e);
          lastSubtitleIndexRef.current = currIndex;
          // console.log("Updated lastSubtitleIndexRef to: ", currIndex);
        }
        if (nextIndex < vttLoaded.length) {
          //save the start time of the next sub, so the gap between the current and next one is captured
          nextStartRef.current = convertToSeconds(vttLoaded[nextIndex].s);
        }
      }

      // ***** SHORT CIRCUITS *********************************
      // Handle case before very first subtitle starts
      if (vttLoaded.length && currentTime < convertToSeconds(vttLoaded[0].s)) {
        if (activeSubtitleIndexRef.current !== -1) {
          //reset the state and ref vars activeSubtitleIndex, activeSubtitleIndexRef, so nothing is selected
          //console.log("BEFORE first tile, setting index to -1")
          setActiveSubtitleIndex(-1);
          activeSubtitleIndexRef.current = -1;
        }
        return; // Exit, as no subtitles are active yet
      }

      // Check if the current time is within the current subtitle or in the gap to the next
      if (currIndex !== -1 && currentStartRef.current !== null && currentEndRef.current !== null) {
        if (currentStartRef.current <= currentTime && currentTime < (nextStartRef.current || Infinity)) {
          //console.log("Current index or gap is active, do nothing.:", currIndex);
          return; // Exit the interval callback
        }
      }
      // ******************************************************

      // Check if the next subtitle is the active one
      if (nextIndex < vttLoaded.length && nextStartRef.current !== null) {
        const endNext = convertToSeconds(vttLoaded[nextIndex].e);

        if (nextStartRef.current <= currentTime && currentTime <= endNext) {
          newIndex = nextIndex;
          //console.log("Next index is valid, making it active:", newIndex);
        }
      }

      // Binary search as last resort
      if (newIndex === -1) {
        newIndex = binarySearchSubtitles(vttLoaded, currentTime);
        // console.log("You jumped! Binary Search found:", newIndex);
      }

      // Update state and ref
      if (newIndex !== -1 && newIndex !== activeSubtitleIndexRef.current) {
        console.log("TARGET >>>>> Setting ActiveSubtitleIndex to new FROM old: ", newIndex, activeSubtitleIndexRef.current);
        setActiveSubtitleIndex(newIndex);
        activeSubtitleIndexRef.current = newIndex;
        
        
        if (tilesFilterValue == "bookmarked"){
          console.log("TARGET>>>>> Setting ActiveTilePosSubIndex to Binary new FROM old: ", binarySearchById(filteredSubtitles,newIndex+1), activeTilePosSubIndex);
          //Add +1 because newIndex is the position of the zero based list but search function expecting Sub Id which is 1 based list
          setActiveTilePosSubIndex(binarySearchById(filteredSubtitles,newIndex+1));
        } else {
          console.log("TARGET>>>>> Setting ActiveTilePosSubIndex to new FROM old: ", newIndex, activeTilePosSubIndex);
          setActiveTilePosSubIndex(newIndex);
        }
      }
    }, 300);
  }

    // Start the interval only if playing, else clear the interval and wait for playing state change
    if (playing) {
      startInterval();
    } else if (interval) {
      clearInterval(interval);
    }

  return () => {
    if (interval){
      clearInterval(interval);
    }
  }
}, [vttLoaded, tilesFilterValue, bookmarksLoaded, playing, filteredSubtitles]);


  //ORIG TILE HIGHLIGHT orig idx: Set video active original language sub index so the correct text can be displayed
  useEffect(() => {
    console.log("useEffect for  setting active tile indexes:", vttLoaded)
    if (!vttLoaded.length) {
      // Potentially reset some state here if needed
      return;
    }
    let interval;
    const startInterval = () => {
      interval = setInterval(() => {
        // console.log("Player is playing: ", playingRef.current);
        if (!playerRef.current || !playingRef.current) return;
        const currentTime = playerRef.current.getCurrentTime();
  
        let currIndex = activeSubtitleIndexOrigRef.current; //current sub index
        let nextIndex = currIndex < vttLoadedOrig.length - 1 ? currIndex + 1 : currIndex; //next sub index
        let newIndex = -1;
  
        // Update subtitle times if we have a new index
        if (currIndex !== lastSubtitleIndexOrigRef.current) {
          if (currIndex < vttLoadedOrig.length) {
            //Edge case where user has clicked on first tile in Target sub tiles which has different timings and this Original tile has no corresponding start time yet
            if (currIndex == -1) {
              currIndex = 0
            }
            //console.log("Updating current subtitle ORIG start end times for index: ", currIndex, vttLoadedOrig[currIndex]);
            currentStartOrigRef.current = convertToSeconds(vttLoadedOrig[currIndex].s);
            currentEndOrigRef.current = convertToSeconds(vttLoadedOrig[currIndex].e);
            lastSubtitleIndexOrigRef.current = currIndex;
            // console.log("Orig Updated lastSubtitleIndexOrigRef to: ", currIndex);
          }
          if (nextIndex < vttLoadedOrig.length) {
            nextStartOrigRef.current = convertToSeconds(vttLoadedOrig[nextIndex].s);
          }
        }
  
        // Check if the current time is within the current subtitle or in the gap to the next
        if (currIndex !== -1 && currentStartOrigRef.current !== null && currentEndOrigRef.current !== null) {
          if (currentStartOrigRef.current <= currentTime && currentTime < (nextStartOrigRef.current || Infinity)) {
            //console.log("Current index or gap is active, do nothing.:", currIndex);
            return; // Exit the interval callback
          }
        }
  
        // Check if the next subtitle is the active one
        if (nextIndex < vttLoadedOrig.length && nextStartOrigRef.current !== null) {
          const endNext = convertToSeconds(vttLoadedOrig[nextIndex].e);
  
          if (nextStartOrigRef.current <= currentTime && currentTime <= endNext) {
            newIndex = nextIndex;
            // console.log("Orig Next index is valid, making it active:", newIndex);
          }
        }
  
        // Binary search as last resort
        if (newIndex === -1) {
          newIndex = binarySearchSubtitles(vttLoadedOrig, currentTime);
          // console.log("Orig You jumped! Orig Binary Search found:", newIndex);
        }
  
        // Update state and ref
        if (newIndex !== -1 && newIndex !== activeSubtitleIndexOrigRef.current) {
          console.log("Orig >>>>> Setting ActiveSubtitleIndexOrig to new from old: ", newIndex, activeSubtitleIndexOrigRef.current);
          setActiveSubtitleIndexOrig(newIndex);
          activeSubtitleIndexOrigRef.current = newIndex;
          
          if (tilesFilterValue == "bookmarked"){
            console.log("Orig >>>>> Setting ActiveTilePosSubIndexOrig to Binary new from old: ", binarySearchById(filteredSubtitles,newIndex+1), activeTilePosSubIndexOrig);
            //Add +1 because newIndex is the position of the zero based list but search function expecting Sub Id which is 1 based list
            setActiveTilePosSubIndexOrig(binarySearchById(filteredSubtitles,newIndex+1));
          } else {
            console.log("Orig >>>>> Setting ActiveTilePosSubIndexOrig to new from old: ", newIndex, activeTilePosSubIndexOrig);
            setActiveTilePosSubIndexOrig(newIndex);
          }
        }
      }, 300);
    }
  
      // Start the interval only if playing, else clear the interval and wait for playing state change
      if (playing) {
        startInterval();
      } else if (interval) {
        clearInterval(interval);
      }
  
    return () => {
      if (interval){
        clearInterval(interval);
      }
    }
  }, [vttLoadedOrig, tilesFilterValue, bookmarksLoaded, playing, filteredSubtitles]);



  // Auto scroll sub tiles
  useEffect(() => {
    // const currentRef = buttonRefs.current[activeSubtitleIndex]
    const currentRef = useTileSubsOrig
      ? buttonRefs.current[activeTilePosSubIndexOrig]
      : buttonRefs.current[activeTilePosSubIndex]

    // if (activeSubtitleIndex >= 0 && currentRef) {
    if (currentRef) {
      currentRef.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }, [activeTilePosSubIndex,activeTilePosSubIndexOrig])


  // Auto scroll chapter tiles
  useEffect(() => {
    const currentRef = buttonRefs.current[activeChapterIndex]
    if (activeChapterIndex >= 0 && currentRef) {
      currentRef.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }, [activeChapterIndex])

  // ------- ******* API: Get video details from api TODO: change to AWS API gateway ******-----------

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  //load custom subtitles
  useEffect(() => {
    const loadCustomSubtitles = async () => {
      const videoSubtitlesKey = `c_subtitles_${id}`
      console.log(`Loading custom subtitles for key: ${videoSubtitlesKey}`)
      const existingSubtitlesString = await storage.get(videoSubtitlesKey)
      if (existingSubtitlesString) {
        const existingSubtitles = JSON.parse(existingSubtitlesString)
        const customSubtitleNames = Object.keys(existingSubtitles)
        console.log(`^^^^^^^^^^Custom subtitles found: ${customSubtitleNames}`)
        // setSubtitleLanguages(prevLanguages => Array.from(new Set([...prevLanguages, ...customSubtitleNames])));
        setSubtitleLanguages([...subtitleLanguagesGen, ...customSubtitleNames])
        //For now just default the first custom sub as the language code
        setLanguageCode(customSubtitleNames[0])
        setVttLoaded(existingSubtitles[customSubtitleNames[0]]);
        setVttLoadedOrig(existingSubtitles[customSubtitleNames[0]]);
        setCustSubtitlesList(customSubtitleNames)

        //Load bookmarkfiles since we have subtitles now
        const bookmarks = await bookmarkTilesService.getBookmarks(
          id,
          customSubtitleNames[0],
        )
        console.log("Got bookmarks: ", id, bookmarks);
        setBookmarksLoaded(bookmarks)
        setBookmarkedTiles(bookmarks)

      } else {
        console.log("^^^^^^^^^^^No custom subtitles found.")
      }
    }

    if (id) {
      loadCustomSubtitles()
    } else {
      console.log("^^^^^^^No video id found for loading custom subtitles.")
    }
  // }, [custSubtitlesList, id])
}, [id])

  useEffect(() => {
    const loadNote = async () => {
      try {
        const noteKey = `note_${id}`
        const savedNoteObject = await storage.get(noteKey)
        if (savedNoteObject && savedNoteObject.text) {
          setNoteContent(savedNoteObject.text)
          // For Slate React: setValue(JSON.parse(savedNoteObject.text));
        } else {
          setNoteContent("") // Clear note content if nothing is saved
        }
      } catch (error) {
        console.error("Failed to load note:", error)
      }
    }

    loadNote()
  }, [id]) //gwt note for video


  const customPopoverOptions = {
    header: "Language",
    subHeader: "Select target language",
    message: "This is the language you want to translate to",
  }

  const customPopoverOptionsLoop = {
    header: "Loop",
    subHeader: "How many loops?",
    message: "Select the number of times a tile should loop",
  }

  //check if an object is empty
  const isObjectEmpty = (obj) => {
    //console.log("Zplayer Examining obj: ", obj);
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  //handle switching language displayed in tiles from target language (original behavior) to video's original language
  const handleSwapSubtitles = async () => {
    //Since user is switching from the curent language, we need to get the opposite of what it currently is, followed by toggling useTileSubsOrig state varible afterwards
    const bookmarks = await bookmarkTilesService.getBookmarks(
      id,
      useTileSubsOrig ? languageCode : languageCodeOrig
    )
    setBookmarkedTiles(bookmarks) //load in the appropriate copy of the bookmarks to display bookmark state
    setBookmarksLoaded(bookmarks)

    setUseTileSubsOrig((prevState) => !prevState) //toggle to indicate if tiles have been swapped to orig from selected, default is false (selected)
  }


  //NEW API3
  const {
    lengthSeconds,
    title,
    channelId,
    channelTitle,
    categoryId,
    viewCount,
  } = videoDetail


  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        position="middle"
      />

      <IonModal
        id="createSub-modal"
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Create Subtitle</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="createSub-container">
          <IonInput
            className="createSub-container__input"
            value={custName}
            placeholder="Enter name"
            onIonChange={(e) => setCustName(e.detail.value || "")}
          />

          <IonRadioGroup
            value={subtitleType}
            onIonChange={(e) => setSubtitleType(e.detail.value)}
          >
            <IonItem>
              <IonLabel>Copy</IonLabel>
              <IonRadio value="copy" />
            </IonItem>
            <IonItem>
              <IonLabel>Blank</IonLabel>
              <IonRadio value="blank" />
            </IonItem>
          </IonRadioGroup>

          <div className="createSub-container__buttonGroup">
            <IonButton
              size="small"
              color="medium"
              onClick={() => setShowModal(false)}
            >
              Close
            </IonButton>
            <IonButton size="small" onClick={handleCreateSubtitles}>
              Create
            </IonButton>
          </div>

          <IonList>
            {custSubtitlesList.map((subtitleName) => (
              <IonItem key={subtitleName}>
                {subtitleName}
                <IonIcon
                  icon={trashOutline}
                  slot="end"
                  onClick={() => {
                    setSubtitleToDelete(subtitleName)
                    setShowDeleteAlert(true)
                  }}
                />
              </IonItem>
            ))}
          </IonList>

          <IonAlert
            isOpen={showDeleteAlert}
            onDidDismiss={() => setShowDeleteAlert(false)}
            header={"Confirm Delete"}
            message={`Are you sure you want to delete the subtitle '${subtitleToDelete}'?`}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  setShowDeleteAlert(false)
                },
              },
              {
                text: "Delete",
                handler: () => {
                  handleDeleteSubtitle()
                },
              },
            ]}
          />
        </IonContent>
      </IonModal>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton default-href="/home/new" />
          </IonButtons>

          {/*<IonSegment value={tilesFilterValue} onIonChange={e => setTilesFilterValue(e.detail.value!)}> */}
          <IonSegment
            value={tilesFilterValue}
            onIonChange={(e) => handleSegmentClick(e.detail.value!)}
            style={{ marginRight: "0", paddingRight: "0" }}
          >
            <IonSegmentButton value="all">
              <IonLabel>All</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="bookmarked">
              <IonLabel>Bookmarked</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="chapters">
              <IonLabel>Chapters</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="note">
              {/*<IonIcon size="small" color="dark" icon={documentTextOutline} /> */}
              <IonLabel>Notes</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <IonItem slot="end" style={{ background: "transparent" }}>
            {/*<HeartComponent solid={isFavorite} onClick={handleFavoriteClick} /> */}
          </IonItem>
        </IonToolbar>
      </IonHeader>

      <div className="zplayer">
        <div className="zplayer__subLayout">
          <div className="zplayer__subLeftLayout">
            {/* == 01: Player == */}
            <div className="zplayer__player">
              {/* player seek slider */}
              <div className="sliderContainer">
                <div className="timeElapsed">{formatTimeRP(currentTime)}</div>
                <input
                  className="slider"
                  type="range"
                  min={0}
                  max={durationRP}
                  value={currentTime}
                  step="any"
                  onChange={(e) => {
                    const time = parseFloat(e.target.value)
                    setCurrentTime(time)
                    playerRef.current?.seekTo(time)

                    const tooltip = document.querySelector(
                      ".timeTooltip"
                    ) as HTMLElement

                    if (tooltip) {
                      const percent = ((time - 0) / (durationRP - 0)) * 100
                      tooltip.style.left = percent + "%"
                      tooltip.textContent = formatTimeRP(time)
                      tooltip.style.display = "block"
                    }
                  }}
                  onMouseUp={() => {
                    const tooltip = document.querySelector(
                      ".timeTooltip"
                    ) as HTMLElement
                    if (tooltip) {
                      tooltip.style.display = "none"
                    }
                  }}
                  onTouchEnd={() => {
                    const tooltip = document.querySelector(
                      ".timeTooltip"
                    ) as HTMLElement
                    if (tooltip) {
                      tooltip.style.display = "none"
                    }
                  }}
                />
                <div className="timeTooltip">
                  {/* Content will be updated via JS */}
                </div>

                <div className="timeRemaining">
                  {/*formatTimeRP(durationRP - currentTime)*/}
                  {formatTimeRP(durationRP)}
                </div>
              </div>

              {/* main subs overlay over video */}
              {((subtitlesEnabled && activeSubtitleIndex >= 0) ||
                (subtitlesOrigEnabled && activeSubtitleIndexOrig >= 0)) && (
                <div
                  style={{
                    ...getFontSizeAndLineHeight(),
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%", // Make it span about 66% the size of the video
                    textAlign: "center", // Center the text horizontally
                    backgroundColor: "rgba(0, 0, 0, 0.26)",
                    padding: "10px", // Increase padding to make the text bigger
                    borderRadius: "10px", // Increase border radius to match the bigger text
                    color: "rgb(254,212,71)",
                    whiteSpace: "pre-wrap", // Wrap the text if it exceeds the maxWidth
                    textShadow: "1px 1px 3px rgba(0, 0, 0, 1)", // Add shadow to the text
                    fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                    fontWeight: 700,
                  }}
                >
                  {subtitlesEnabled &&
                    vttLoaded[
                      tilesFilterValue === "bookmarked"
                        ? activeSubtitleIndex //bookmarksLoaded[activeSubtitleIndex]
                        : activeSubtitleIndex
                    ]?.t}
                  {subtitlesOrigEnabled &&
                    vttLoadedOrig[
                      tilesFilterValue === "bookmarked"
                        ? activeSubtitleIndexOrig //bookmarksLoaded[activeSubtitleIndexOrig]
                        : activeSubtitleIndexOrig
                    ]?.t}
                  <div>
                    {/* vttLoadedOrigRom && vttLoadedOrigRom[activeSubtitleIndexOrig].t */}
                  </div>
                </div>
              )}

              {/* separate overlay for vttLoadedOrigRom (romanization) at the bottom of the screen */}
              {subtitlesOrigEnabled &&
                activeSubtitleIndexOrig >= 0 &&
                vttLoadedOrigRom.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "5%",
                      left: "50%",
                      transform: "translate(-50%, 0)",
                      width: "90%",
                      fontSize: isTabletOrDesktop ? "1.6rem" : "1rem", // Setting font size to 2rem
                      textAlign: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.13)",
                      padding: "10px",
                      borderRadius: "10px",
                      color: "rgb(210,210,200)",
                      whiteSpace: "pre-wrap",
                      textShadow: "1px 1px 3px rgba(0, 0, 0, 1)",
                      fontFamily: "Noto Sans KR, Noto Sans,sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    {
                      vttLoadedOrigRom[
                        tilesFilterValue == "bookmarked"
                          ? bookmarksLoaded[activeSubtitleIndexOrig]
                          : activeSubtitleIndexOrig
                      ]?.t
                    }
                  </div>
                )}

                <div className="zplayer__player__ytPlayer" style={{ 
    maxHeight: '80vh', // Maximum height relative to the viewport height
    overflow: 'hidden',
    position: 'relative',
}}>
                  <ReactPlayer
                    ref={playerRef}
                    url={videoUrl}
                    playing={playing}
                    onPlay={() => setPlaying(true)}
                    playbackRate={playbackRate}
                    width="100%"
                    height="100%"
                    onDuration={handleDuration}
                    // onProgress={({ playedSeconds }) =>
                    //   setCurrentTime(playedSeconds)
                    // }
                    onProgress={onVideoProgress}

                    controls={false}
                    style={{ 
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                  }}
                  />
                  <IonIcon
                    icon={informationCircleOutline}
                    onClick={openMediaCard}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      fontSize: "25px",
                      color: "gold",
                      cursor: "pointer",
                      zIndex: 1000, // Ensure it's above the video player layer
                    }}
                  />
                </div>

            </div>

            {/* == 02: PlayerSubs == */}
            <div className="zplayer__playerSubs">
              <PlayerSubs
                languageMap={languageMap}
                languageCode={languageCode}
                languageCodeOrig={languageCodeOrig}
                vttLoaded={vttLoaded}
                vttLoadedOrig={vttLoadedOrig}
                // activeSubtitleIndex={
                //   tilesFilterValue == "bookmarked"
                //     ? bookmarksLoaded[activeSubtitleIndex]
                //     : activeSubtitleIndex
                // }
                // activeSubtitleIndexOrig={
                //   tilesFilterValue == "bookmarked"
                //     ? bookmarksLoaded[activeSubtitleIndexOrig]
                //     : activeSubtitleIndexOrig
                // }
                activeSubtitleIndex={activeSubtitleIndex}
                activeSubtitleIndexOrig={activeSubtitleIndexOrig}
                processingMsg={processingMessage}
                addWordToRecents={addWordToRecents}
                playing={playing}
                setPlaying={setPlaying}
              />
            </div>

            {/* == 03: Loop & target language select == */}
            <div className="zplayer__loop">
              <IonRow className="zplayer__loop__row" style={{margin:"0px"}}>
                {/* Gear Icon Button */}
                <IonCol className="buttons-col">
                  <IonButton
                    class="no-margin-padding"
                    fill="clear"
                    onClick={() => setShowModal(true)}
                  >
                    <IonIcon color="dark" icon={settingsOutline} />
                  </IonButton>

                  <IonButton
                    class="no-margin-padding"
                    fill="clear"
                    onClick={handleSwapSubtitles}
                  >
                    <IonIcon
                      color={useTileSubsOrig ? "dark" : "warning"}
                      icon={gridOutline}
                    />
                  </IonButton>
                </IonCol>

                {/* Swap tiles from selected to orig Button */}
                {/* <IonCol>
                  <IonButton
                    class="no-margin-padding"
                    fill="clear"
                    onClick={handleSwapSubtitles}
                  >
                    <IonIcon
                      color={useTileSubsOrig ? "dark" : "warning"}
                      icon={gridOutline}
                    />
                  </IonButton>
                </IonCol> */}

                <IonCol>
                  <IonItem>
                    <IonLabel>Loop</IonLabel>
                    <IonSelect
                      value={loopCount}
                      onIonChange={handleLoopCountChange}
                      interface="popover"
                      interfaceOptions={customPopoverOptionsLoop}
                    >
                      <IonSelectOption value={1}>1</IonSelectOption>
                      <IonSelectOption value={2}>2</IonSelectOption>
                      <IonSelectOption value={3}>3</IonSelectOption>
                      <IonSelectOption value={4}>4</IonSelectOption>
                      <IonSelectOption value={5}>5</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>


                <IonCol>

                  <IonItem>

<input
    type="file"
    ref={videoFileInputRef}
    accept="video/*"
    style={{ display: 'none' }}
    onChange={handleVideoFileChange} 
/>


<IonButton size="small" color="danger" onClick={handleSelectVideoFile}>
    Video File
</IonButton>


                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".vtt, .srt"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />

                    {/* Button to trigger the file input */}
                    <IonButton size="small"  color="tertiary" onClick={handleSelectSubsFile} disabled={!id}>
                      Subs File
                    </IonButton>

                    
                  </IonItem>
                </IonCol>
              </IonRow>
            </div>
          </div>

          {/* == 04: Button Sub Tiles == */}
          <div className="zplayer__buttonSubTiles">

            {tilesFilterValue === "all" && (
              <IonItem className="custom-search-item">
                {searchTerm && (
                  <IonLabel position="floating">
                    {matchCount} matching tiles found
                  </IonLabel>
                )}
                <IonSearchbar
                  className=""
                  value={searchTerm}
                  onIonChange={handleTileSearch}
                  debounce={300}
                  enterkeyhint="search"
                  placeholder="Search tiles"
                ></IonSearchbar>
              </IonItem>
            )}

            <IonGrid>
              {tilesFilterValue === "note" ? (
                <>
                  <IonRow>
                    <IonCol>
                      <IonTextarea
                        onIonChange={(e) =>
                          setNoteContent(e.detail.value ?? "")
                        }
                        onKeyDown={(e) => {
                          if ((e.ctrlKey || e.metaKey) && e.key === "s") {
                            e.preventDefault() // Prevent the default save action
                            handleSaveNote() // Call your save function
                          }
                        }}
                        value={noteContent}
                        className="custom-textarea"
                        autoGrow={true}
                        placeholder="Enter your note here..."
                      ></IonTextarea>
                      {/*<div className="custom-slate-editor">
                    <Slate
                      editor={editor}
                      initialValue={value}
                      onChange={(newValue) => setValue(newValue as CustomDescendant[])}
                      
                    >
                      <Editable />
                    </Slate>
                    </div> */}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="auto">
                      <IonButton
                        fill="outline"
                        size="small"
                        onClick={copyToClipboard}
                      >
                        Copy to Clipboard
                      </IonButton>
                    </IonCol>

                    {/* Column for the Save Note button */}
                    <IonCol>
                      <IonButton
                        size="small"
                        expand="block"
                        onClick={handleSaveNote}
                      >
                        Save Note
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </>
              ) : (
                <IonRow
                  style={{
                    overflowY:
                      (filteredSubtitles || []).length > 4 ? "auto" : "hidden",
                  }}
                >
                  {/* {(vttLoaded || []).map((subtitle, index) => ( */}
                  {/*(vttLoaded || []) */}
                  {(tilesFilterValue === "chapters"
                    ? chaptersLoaded || []
                    : filteredSubtitles || []
                  )
                    // .filter(
                    //   (_, index) =>
                    //     tilesFilterValue !== "bookmarked" ||
                    //     bookmarksLoaded.includes(index)
                    // )
                    .map((subtitle, index) => (
                      <IonCol
                        key={index}
                        size="6"
                        sizeMd={
                          windowWidth > 915 && windowWidth < 992 ? "12" : "4"
                        }
                        sizeLg="12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IonCard
                          ref={(el) =>
                            (buttonRefs.current[index] =
                              el as HTMLIonCardElement)
                          }
                          color={
                            tilesFilterValue === "chapters"
                              ? index === activeChapterIndex
                                ? "warning"
                                : "medium"
                              : index === (useTileSubsOrig ? activeTilePosSubIndexOrig : activeTilePosSubIndex)
                              ? "warning"
                              : "medium"
                          }
                          className="ion-card-hoverable ion-activatable ripple-parent tile-card"
                          key={index}
                          onClick={() =>
                            handleButtonClick(subtitle.s, subtitle.e, index)
                          }
                          style={{
                            fontFamily: "Noto Sans, sans-serif",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            fontSize: "16px",
                            whiteSpace: "pre-wrap",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 5,
                            WebkitBoxOrient: "vertical",
                            WebkitBoxLines: 4,
                            height: "5.4rem",
                            //backgroundColor: subtitle === activeSubtitle ? 'lightblue' : 'grey', // Set the background color if the subtitle is active
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {tilesFilterValue != "chapters" && (
                              <>
                                <IonIcon
                                  icon={createOutline}
                                  onClick={(e) =>
                                    handleEditSubtitle(subtitle, e)
                                  }
                                  className="subeditor-icon"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    color: "lightgrey",
                                  }}
                                />

                                {/* Bookmark solid should evaluate not only if the index is in the list but if it's the correct index.
                                  because the user can unbookmark a tile which will mean more tiles than bookmarks, results in the
                                  last tiles not getting correct bookmark indicators (solid/outline) */}
                                <BookmarkComponent
                                  solid={bookmarkedTiles.includes(
                                    tilesFilterValue == "all"
                                      //? parseInt(subtitle.i, 10) - 1 //index
                                      //: parseInt(subtitle.i, 10) - 1 //bookmarksLoaded[index] <--since each sub now has index key "i", no longer need to keep extra bookmarksLoaded list around, confirm before removal
                                      ? subtitle.i- 1 //index
                                      : subtitle.i - 1 //bookmarksLoaded[index] <--since each sub now has index key "i", no longer need to keep extra bookmarksLoaded list around, confirm before removal

                                      )}
                                  size="small"
                                  onClick={(event) =>
                                    handleBookmarkClick(
                                      tilesFilterValue === "all"
                                        ? subtitle
                                        : subtitle, //bookmarksLoaded[index],
                                      event
                                    )
                                  }
                                  className="bookmark-icon"
                                />

                                {/* <IonBadge className="tile-views" slot="end">22</IonBadge> */}
                              </>
                            )}

                            <IonRippleEffect></IonRippleEffect>
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                padding: "3px",
                                textAlign: "center",
                                fontSize: "14px",
                                zIndex: 1,
                                color: "white",
                              }}
                            >
                              {`${formatTime(subtitle.s)} - ${formatTime(
                                subtitle.e
                              )}`}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                padding: "3px",
                                paddingTop: "24px", // To make room for the header
                              }}
                            >
                              {subtitle.t}
                            </div>
                          </div>
                        </IonCard>
                      </IonCol>
                    ))}
                </IonRow>
              )}
              {/* Render the subtitle editor when open */}
              <SubtitleEditor
                subtitle={editedSubtitle}
                isOpen={isSubtitleEditorOpen}
                onClose={() => {
                  console.log("onClose called");
                  setIsSubtitleEditorOpen(false);
                }}
                onSave={handleSaveEditedSubtitle}
              />
            </IonGrid>
          </div>
        </div>

        {/* == 05: Controls == */}
        <div className="zplayer__playerControls">
          <PlayerControls
            playing={playing}
            handleSetPlaybackRate={handleSetPlaybackRate}
            handlePlayPause={handlePlayPause}
            handleBackFive={handleBackFive}
            handleForwardFive={handleForwardFive}
            subtitlesEnabled={subtitlesEnabled}
            subtitlesOrigEnabled={subtitlesOrigEnabled}
            toggleOverlay={toggleOverlay}
          />
        </div>
      </div>
    </IonPage>
  )
}

export default ZLplayer
