/*
JMR
*/
import React, { useState, useEffect, useRef } from "react"
import { IonInput, IonLabel, IonThumbnail, IonText, IonTextarea, IonRouterLink, IonCard, IonCardTitle, IonCardSubtitle,IonCardContent, IonButton, IonToast,IonIcon, } from "@ionic/react"
import wordsService from "../../utils/WordsService"
import "../../App.scss"
import "./VideoCardWord.scss"
import DisplaySubs from '../PlayerSubs/DisplaySubs'
import { useHistory, useLocation } from "react-router-dom"
import { closeCircle, checkmarkCircle, checkmarkCircleOutline, trashOutline, pencil, checkmark, close, globeOutline, openOutline } from 'ionicons/icons'
import { Browser } from '@capacitor/browser'
import pinyin from "pinyin";
import { toRomaji } from 'wanakana';//romanji to hiragana, no kanji

import { motion } from "framer-motion";



interface VideoProps {
  id: {
    videoId?: string
  }
  snippet?: {
    title?: string
    channelId?: string
    channelTitle?: string
    thumbnails?: {
      medium?: {
        url?: string
      }
    }
    description?: string
  }
  title?: string
  channelTitle?: string
  description?: string
  thumbnail?: string
  text?: string;
  channelId?: string
  sourceLanguage?: string
  sourceText?: string
  sourceTileText?: string
  sourceTileIndex?: number
  targetLanguage?: string
  targetText?: string
  targetTileText?: string
  notes?: string
  quizResult?: boolean; // **Added quizResult prop**
  friend?: string;
}

const VideoCardCompactWord = ({ video, deckWords, updateDeckWords, onWordUpdated, showProgress = false,
  showActions = false, isInDoneSegment = false, allowEditing = true, quizResult, showFriend = false,}) => {

    const [reviewCount, setReviewCount] = useState(video.reviewCount || 0)
  const videoId = video.id?.videoId || video.id || "";
  const title = video.snippet?.title || video.title || "";
  const channelId = video.snippet?.channelId || video.channelId || "";
  const channelTitle = video.snippet?.channelTitle || video.channelTitle || "";
  const description = video.snippet?.description || video.description || "";
  const thumbnailUrl = video.snippet?.thumbnails?.medium?.url || video.thumbnail || "";
    
  const wordTileIndex = video.sourceTileIndex || 0
  
  const [shouldNavigate, setShouldNavigate] = useState(false)
  const isLocalVideo = channelTitle === "LOCAL";
  const [toastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState(false)

  const history = useHistory()
  const location = useLocation()

  //edit source and target text/vocab
  const [isEditingSourceText, setIsEditingSourceText] = useState(false)
  const [isEditingTargetText, setIsEditingTargetText] = useState(false)
  const [editedSourceText, setEditedSourceText] = useState(video.sourceText)
  const [editedTargetText, setEditedTargetText] = useState(video.targetText)
  const [videoState, setVideo] = useState(video)

  // State for editing notes
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [editedNotes, setEditedNotes] = useState(video.notes || '')

// =========================================================
  //SIMPLE "PLATFORMER" LOGIC FOR THE animal friends
  // =========================================================
  // Track which way the friend is facing ("right" or "left")
  const [friendFacingRight, setFriendFacingRight] = useState(true);

  // Track the current jump offset in pixels (0 means no jump)
  const [jumpOffset, setJumpOffset] = useState(0);

  function getFriendOpacity(count: number): number {
    switch (count) {
      case 0: return 0.5;  // 50%
      case 1: return 0.8;  // 80%
      default: return 1.0; // 100% for count >= 2
    }
  }

  function getJumpDistance(count: number) {
    // 4 => 3px
    // 5 => 10px
    // 6 => 30px
    // 7 => 60px
    // If below 4, we do 0
    switch (count) {
      case 4: return 3;
      case 5: return 10;
      case 6: return 30;
      case 7: return 60;
      default: return 0;
    }
  }



  
  useEffect(() => {
    if (reviewCount < 3) return;
  
    let timeoutId: any;
  
    function scheduleFlip() {
      // flip direction
      setFriendFacingRight(prev => !prev);
  
      // if reviewCount >= 4, jump
      if (reviewCount >= 4) {
        const dist = getJumpDistance(reviewCount);
        setJumpOffset(dist);
  
        // return to normal after 300ms
        setTimeout(() => setJumpOffset(0), 300);
      }
  
      // schedule next flip
      const randomDelay = 1000 + Math.random() * 9000; // 1-10 seconds
      timeoutId = setTimeout(scheduleFlip, randomDelay);
    }
  
    // start
    scheduleFlip();
  
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [reviewCount]);
  

  


  // =========================================================
  // END NEW CODE
  // =========================================================


    
  //Open Google Translate
  const openGoogleTranslate = async (text) => {
    const encodedText = encodeURIComponent(text);

    //determine which tiles were clicked on, the orig lang or target language. 
    //If value of useTileSubsOrig is false then we need to switch the source and target for trnslation
    let sourceLang, targetLang;
    
    // Default to auto-detect if source language is not specified
    sourceLang = video.sourceLanguage || 'auto';
    // Default to English if target language is not specified
    targetLang = video.targetLanguage || 'en';


    const url = `https://translate.google.com/?sl=${sourceLang}&tl=${targetLang}&text=${encodedText}&op=translate`;
    await Browser.open({ url });
  };


  useEffect(() => {
    if (shouldNavigate  && videoId) {
      history.push(isLocalVideo ? '/myvideo' : `/video/${videoId}?wordTileIndex=${wordTileIndex}`)
      setShouldNavigate(false) // Reset for future navigations
    }
  }, [shouldNavigate])


  const handleNavigate = () => {
    //history.push(`/video/${videoId}`);
    //Change variable to trigger useEffect above to nav away
    if (videoId) {
      setShouldNavigate(true);
    } else {
      // Do nothing or show a message
    }
  }

  //delete the word from 'srvocabdone'
  const handleDelete = async () => {
    const success = await wordsService.deleteFromDone(video.sourceText)
    if (success) {
      setToastMessage("Word deleted")
      if (onWordUpdated) {
        onWordUpdated()
      }
    } else {
      setToastMessage("Failed to delete word")
    }
    setShowToast(true)
  }


  // const handleAddToDeck = async () => {
  //   const success = await wordsService.addToDeck(video)
  //   if (success) {
  //     setToastMessage("Word added to deck")
  //     updateDeckWords(video.sourceText) // Update deckWords in WordsPage
  //   } else {
  //     setToastMessage("Already in deck")
  //   }
  //   setShowToast(true)
  // }
  const handleAddToDeck = async () => {
    if (isInDoneSegment) {
      // Move word from srvocabdone back to srvocab with reviewCount reset to 0
      const success = await wordsService.moveToActiveFromDone(video.sourceText)
      if (success) {
        setToastMessage("Word restored to deck")
        if (onWordUpdated) {
          onWordUpdated()
        }
      } else {
        setToastMessage("Failed to restore word")
      }
    } else {
      // Original functionality
      const success = await wordsService.addToDeck(video)
      if (success) {
        setToastMessage("Word added to deck")
        updateDeckWords(video.sourceText)
      } else {
        setToastMessage("Already in deck")
      }
    }
    setShowToast(true)
  }
  

  // Determine if the word is in the deck based on the prop
  const isInDeck = deckWords.includes(video.sourceText)

    // Progress Indicator
    const progressIntervals = [
      { index: 0, label: '0' },
      { index: 1, label: '1' },
      { index: 2, label: '3' },
      { index: 3, label: '8' },
      { index: 4, label: '13' },
      { index: 5, label: '21' },
      { index: 6, label: '54' },
      { index: 7, label: '144' },
    ]
  
    // const reviewCount = video.reviewCount || 0
    const maxReviewIndex = progressIntervals.length - 1
    // const [reviewCount, setReviewCount] = useState(video.reviewCount || 0)
  
    const handleIncrementReviewCount = async () => {
      // Check if reviewCount is less than maxReviewIndex
      if (reviewCount < maxReviewIndex) {
        const success = await wordsService.incrementReviewCount(video.sourceText)
        if (success) {
          setReviewCount(reviewCount + 1)
          setToastMessage("Review count incremented")
          setShowToast(true)
          if (onWordUpdated) {
            onWordUpdated()
          }
        }
      } else {
        // Move word to "Done"
        const moved = await wordsService.moveToDone(video.sourceText)
        if (moved) {
          setToastMessage("Word moved to Done")
          setShowToast(true)
          if (onWordUpdated) {
            onWordUpdated()
          }
        }
      }
    }

    
    const handleDecrementReviewCount = async () => {
      // Check if reviewCount is greater than 0
      if (reviewCount > 0) {
        const success = await wordsService.decrementReviewCount(video.sourceText)
        if (success) {
          setReviewCount(reviewCount - 1)
          setToastMessage("Review count decremented")
          setShowToast(true)
          if (onWordUpdated) {
            onWordUpdated()
          }
        }
      }
    }

    //Editing source and target text fields (mainly for error correction)
    const handleEditSourceText = () => {
      if (!allowEditing) return
      setIsEditingSourceText(true)
    }
    
    const handleSaveSourceText = async () => {
      // Short circuit: Check if the edited text is different from the original
      if (editedSourceText === video.sourceText) {
        // No changes made, do not update storage
        setToastMessage("No changes made to source text")
        setShowToast(true)
        setIsEditingSourceText(false)
        return
      }
      // Save the edited sourceText to storage
      const success = await wordsService.updateWordText(video.sourceText, editedSourceText, 'sourceText')
      if (success) {
        setToastMessage("Source text updated")
        setShowToast(true)
        setIsEditingSourceText(false)
        setVideo(prevVideo => ({ ...prevVideo, sourceText: editedSourceText }))
        if (onWordUpdated) {
          onWordUpdated()
        }
      } else {
        setToastMessage("Failed to update source text")
        setShowToast(true)
      }
    }

    const handleCancelSourceText = () => {
      setEditedSourceText(video.sourceText) // Revert to original value
      setIsEditingSourceText(false) // Exit edit mode
    }

    


    
    const handleEditTargetText = () => {
      if (!allowEditing) return
      setIsEditingTargetText(true)
    }
    
    const handleSaveTargetText = async () => {
      // Short circuit: Check if the edited text is different from the original
      if (editedTargetText === video.targetText) {
        // No changes made, do not update storage
        setToastMessage("No changes made to target text")
        setShowToast(true)
        setIsEditingTargetText(false)
        return
      }

      // Save the edited targetText to storage
      const success = await wordsService.updateWordText(video.sourceText, editedTargetText, 'targetText')
      if (success) {
        setToastMessage("Target text updated")
        setShowToast(true)
        setIsEditingTargetText(false)
        setVideo(prevVideo => ({ ...prevVideo, targetText: editedTargetText }))
        if (onWordUpdated) {
          onWordUpdated()
        }
      } else {
        setToastMessage("Failed to update target text")
        setShowToast(true)
      }
    }

    const handleCancelTargetText = () => {
      setEditedTargetText(video.targetText) // Revert to original value
      setIsEditingTargetText(false) // Exit edit mode
    }

    


    //notes editing functions
    const handleEditNotes = () => {
      if (!allowEditing) return
      setIsEditingNotes(true)
    }
    
    // Function to save edited notes
    const handleSaveNotes = async () => {
      // Short circuit: Check if the edited notes are different from the original
      if (editedNotes === video.notes) {
        // No changes made, do not update storage
        setToastMessage("No changes made to notes")
        setShowToast(true)
        setIsEditingNotes(false)
        return
      }

      const success = await wordsService.updateWordText(video.sourceText, editedNotes, 'notes')
      if (success) {
        setToastMessage("Notes updated")
        setShowToast(true)
        setIsEditingNotes(false)
        setVideo(prevVideo => ({ ...prevVideo, notes: editedNotes }))
        if (onWordUpdated) {
          onWordUpdated()
        }
      } else {
        setToastMessage("Failed to update notes")
        setShowToast(true)
      }
    }

    const handleCancelNotes = () => {
      setEditedNotes(video.notes || '') // Revert to original value
      setIsEditingNotes(false) // Exit edit mode
    }
  
      // Function to generate Pinyin for Chinese words
  const generatePinyin = (text: string) => {
    return pinyin(text, { style: pinyin.STYLE_TONE })
      .flat()
      .join(" ");
  };



  return (
    <IonCard className="earthy-gradient">
<div style={{ position: "relative"  }} >
    <IonCardContent style={{ position: 'relative' }} >
        {/* **Quiz Result Icon** */}
        {quizResult !== undefined && (
  <IonIcon
  icon={quizResult ? checkmarkCircle : closeCircle}
  color={quizResult ? "success" : "danger"}
  style={{
    position: "absolute",
    top: "0px",
    left: "0px",
    fontSize: "24px",
  }}
/>
        )}

      {/* Delete Button */}
      {isInDoneSegment && (
          <IonButton
            color="danger"
            fill="clear"
            style={{ fontSize: "1.1rem", position: 'absolute' ,top: '-5px', right: '-5px' }}
            onClick={handleDelete}
          >
            <IonIcon icon={trashOutline} />
          </IonButton>
        )}

      {/* Progress Indicator */}
      {showProgress && (
        <div className="progress-indicator" style={{ position: 'absolute', top: '0px', right: '0px', display: 'flex' }}>
          {progressIntervals.map((interval) => (
            <IonButton
              key={interval.index}
              size="small"
              fill="solid"
              color={interval.index <= reviewCount ? 'success' : 'medium'}
              style={{
                padding: '0px',
                margin: '0 1px',
                minWidth: '12px',
                height: '15px',
                fontSize: '9px',
                '--border-radius': '1px',
              }}
            >
              {interval.label}
            </IonButton>
          ))}
                  {/* Friend GIF */}
                  
        <img
          src={video.friendThumb? video.friendThumb : "/assets/friends/fHonkingGoose.png"}
          alt="Honking Goose"
          style={{
 marginLeft:'5px'
          }}
        />
        </div>
      )}

      {/*video.sourceLanguage && video.sourceText && video.targetLanguage && video.targetText && (
        <div className="language-section">
          <IonCardSubtitle color='warning' className="large-subtitle-source">
            [{video.sourceLanguage}]: {video.sourceText}
          </IonCardSubtitle>
          <IonCardSubtitle  className="large-subtitle-target">
            [{video.targetLanguage}]: {video.targetText}
          </IonCardSubtitle>
        </div>
        
      )*/}
        {/* Editable Text Fields */}
        {video.sourceLanguage && video.sourceText && (
          <>
          <IonCardSubtitle className="large-subtitle-source" style={{ marginTop:'5px',display: 'flex', alignItems: 'center' }}>
            [{video.sourceLanguage}]:
            {isEditingSourceText ? (
              <>
                <IonInput
                  value={editedSourceText}
                  onIonChange={(e) => setEditedSourceText(e.detail.value!)}
                  style={{ marginLeft: '5px', flex: 1, backgroundColor: 'rgb(112,112,143)',borderRadius: '2px' }}
                />
                <IonButton size="small" fill="clear"  onClick={handleSaveSourceText} style={{margin: '0', padding: '0', '--padding-start': '0', '--padding-end': '0', '--margin-start': '0', '--margin-end': '0', }}>
                  <IonIcon color='success' icon={checkmark} />
                </IonButton>
                <IonButton size="small" fill="clear" onClick={handleCancelSourceText} style={{margin: '0', marginLeft:'10px', padding: '0', '--padding-start': '0', '--padding-end': '0', '--margin-start': '0', '--margin-end': '0', }}>
                  <IonIcon color="danger" icon={close} />
                </IonButton>
              </>
            ) : (
              
                <>
                  <span style={{ fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif", marginLeft: '5px', flex: 1 }}>{video.sourceText}</span>
                  {allowEditing && !isInDoneSegment && (
                  <IonButton size="small" fill="clear" color='medium' onClick={handleEditSourceText}>
                    <IonIcon icon={pencil} />
                  </IonButton>
                  )}
                </>
              
            )}
          </IonCardSubtitle>
          {video.sourceLanguage.startsWith('zh') && (<IonCardSubtitle style={{ fontSize:'1.3em', color:'rgb(178,178,188)'}}>{generatePinyin(video.sourceText)}</IonCardSubtitle>)}
          {video.sourceLanguage.startsWith('ja') && (<IonCardSubtitle style={{ fontSize:'1.3em',color:'rgb(178,178,188)'}}>{video.hiragana}</IonCardSubtitle>)}
          {video.sourceLanguage.startsWith('ja') && (<IonCardSubtitle style={{ fontSize:'1.2em',color:'rgb(178,178,188)'}}>{toRomaji(video.hiragana)}</IonCardSubtitle>)}

          </>
        )}
        {video.targetLanguage && video.targetText && (
          <><IonCardSubtitle  className="large-subtitle-target" style={{ color:'rgb(255,243,142)', display: 'flex', alignItems: 'center' }}>
            [{video.targetLanguage}]:
            {isEditingTargetText ? (
              <>
                <IonInput
                  value={editedTargetText}
                  onIonChange={(e) => setEditedTargetText(e.detail.value!)}
                  style={{ marginLeft: '5px', flex: 1, backgroundColor: 'rgb(112,112,143)',borderRadius: '2px' }}
                />
                <IonButton size="small" fill="clear" onClick={handleSaveTargetText} style={{margin: '0', marginLeft:'3px', padding: '0', '--padding-start': '0', '--padding-end': '0', '--margin-start': '0', '--margin-end': '0', }}>
                  <IonIcon color='success' icon={checkmark} />
                </IonButton>
                <IonButton size="small" fill="clear" onClick={handleCancelTargetText} style={{margin: '0', marginLeft:'10px', padding: '0', '--padding-start': '0', '--padding-end': '0', '--margin-start': '0', '--margin-end': '0', }}>
                  <IonIcon color="danger" icon={close} />
                </IonButton>

              </>
            ) : (
              
                <>
                  <span style={{ fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",  marginLeft: '5px', flex: 1 }}>{video.targetText}</span>
                {allowEditing && !isInDoneSegment && (
                  <IonButton size="small" fill="clear" color='medium' onClick={handleEditTargetText}>
                    <IonIcon icon={pencil} />
                  </IonButton>
   
                )}
            
                </>
                
              
            )}
          </IonCardSubtitle>
          {video.targetLanguage.startsWith('zh') && (<IonCardSubtitle style={{ color:'rgb(210,206,93)'}}>{generatePinyin(video.targetText)}</IonCardSubtitle>)}
          {video.targetLanguage.startsWith('ja') && (<IonCardSubtitle style={{ fontSize:'1.3em',color:'rgb(178,178,188)'}}>{video.hiragana}</IonCardSubtitle>)}
          {video.targetLanguage.startsWith('ja') && (<IonCardSubtitle style={{ fontSize:'1.2em',color:'rgb(178,178,188)'}}>{toRomaji(video.hiragana)}</IonCardSubtitle>)}
          </>
          
        )}

{(thumbnailUrl || title) && (
  <>
      <div className="video-section">
        <IonThumbnail slot="start" onClick={handleNavigate}>
          <img alt={title} src={thumbnailUrl} />
        </IonThumbnail>

        <IonLabel className="vcardCompact__labels">
          <h2 className="title" onClick={handleNavigate} style={{ color: 'rgb(144,204,240)' }}>
            {title}
          </h2>

          {channelId ? (
            <IonRouterLink routerLink={isLocalVideo ? '/myvideo' : `/channel/${channelId}`}>
              <h4 className="vsubtitle" style={{ color: 'rgb(171,124,18)' }}>{channelTitle}</h4>
            </IonRouterLink>
          ) : (
            <h4 className="vsubtitle" style={{ color: 'rgb(171,124,18)' }}>{channelTitle}</h4>
          )}
        </IonLabel>

      </div>
              <DisplaySubs
              sourceLanguageCode={video.sourceLanguage}
              targetLanguageCode={video.targetLanguage}
              sourceTileText={video.sourceTileText}
              targetTileText={video.targetTileText}
            />
            </>

)}

      {/*video.text && (
        <div className="notes-section">
          <IonLabel>
            <IonText color="medium">
              <p className="notes-text">{video.text}</p>
            </IonText>
          </IonLabel>
        </div>
      ) */}
      {/* Editable Notes Field */}
      <div className="notes-section" style={{ marginTop: '1px' }}>
        <IonLabel style={{ color:'rgb(204,204,204)', display: 'flex', alignItems: 'center' }}>
          <strong>Note:</strong>
          {isEditingNotes ? (
            <>
              <IonTextarea
                value={editedNotes}
                onIonChange={(e) => setEditedNotes(e.detail.value!)}
                autoGrow={true}
                maxlength={140}
                style={{
                  marginLeft: '8px',
                  flex: 1,
                  backgroundColor: 'rgb(112,112,143)', // Dark grey background
                  color: 'white',
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
              <IonButton size="small" fill="clear" onClick={handleSaveNotes} style={{margin: '0', marginLeft:'3px', padding: '0', '--padding-start': '0', '--padding-end': '0', '--margin-start': '0', '--margin-end': '0', }}>
                <IonIcon color='success' icon={checkmark} />
              </IonButton>
              <IonButton size="small" fill="clear" onClick={handleCancelNotes} style={{margin: '0', marginLeft:'10px', padding: '0', '--padding-start': '0', '--padding-end': '0', '--margin-start': '0', '--margin-end': '0', }}>
                <IonIcon color="danger" icon={close} />
              </IonButton>
            </>
          ) : (
            <>
              <IonText style={{ color:'rgb(204,204,204)', marginLeft: '8px', flex: 1 }}>
                <p className="notes-text">{video.notes || ''}</p>
              </IonText>
              {allowEditing && !isInDoneSegment && (
                <IonButton size="small" fill="clear" onClick={handleEditNotes}>
                  <IonIcon color='medium' icon={pencil} />
                </IonButton>
              )}
            </>
          )}
        </IonLabel>
      </div>




      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
      />
    </IonCardContent>


    {/* Main friend animation, show only on Review segement on Flashcards page */}
    {showFriend && (
      <motion.img
        src={video.friend ? video.friend : "/assets/friends/fHonkingGoose.gif"}
        alt="Friends"
        style={{
          position: "absolute",
          width: "2.6em",
          height: "2.6em",
          bottom: "10px",
          left: "50%",
          pointerEvents: "none",
          zIndex: -1000,
          opacity: getFriendOpacity(reviewCount),
          transform: `
            translateX(-50%) 
            ${friendFacingRight ? "scaleX(1)" : "scaleX(-1)"}
            translateY(${ -jumpOffset }px)
          `,
        }}
      />
    )}

</div>

    <div className="translate-icon-container">
      <IonIcon
        icon={globeOutline}
        onClick={() => openGoogleTranslate(video.sourceText)}
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '10px',
          fontSize: '1rem',
          color: "rgb(204,204,204)",
          cursor: 'pointer',
        }}
      />
    </div>
    {/* increment/decrement buttons for review progress */}
    {showActions && (

    <div className="action-buttons" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginRight: '0px' }}>

      <IonButton
        size="small"
        onClick={handleDecrementReviewCount}
        color="light"
        style={{ marginRight: '5px' }}
      >
        Redo
      </IonButton>

      <IonButton
        size="small"
        onClick={handleIncrementReviewCount}
        color="primary"
        style={{ marginRight: '0px' }}
      >
        Reviewed
      </IonButton>
    </div>
    )}

    {/* Always render the "Add to Deck" or "Restore" button in Done segment */}
    {(isInDoneSegment || !isInDeck) && (
      <div className="action-buttons" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginRight: '0px' }}>
        <IonButton
          size='small'
          onClick={handleAddToDeck}
          style={{ marginLeft: 'auto', alignSelf: 'center' }}
        >
          {isInDoneSegment ? 'Restore' : 'Add to deck'}
        </IonButton>
      </div>

      
    )}

{/* 
<div className="translate-icon-container">
      <IonIcon
        icon={globeOutline}
        onClick={() => openGoogleTranslate(video.sourceText)}
        style={{
          cursor: 'pointer',
          fontSize: '1rem',
          color: "rgb(204,204,204)",
        }}
      />
    </div> */}

  </IonCard>
  )
}

export default VideoCardCompactWord