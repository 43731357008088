const friends = [
    'fCluckingChicken.gif',
    'fCoralCrab.gif',
    'fCroakingToad.gif',
    'fDaintyPig.gif',
    'fHonkingGoose.gif',
    'fLeapingFrog.gif',
    'fMadBoar.gif',
    'fMeowingCat.gif',
    'fPasturingSheep.gif',
    'fSlowTurtle.gif',
    'fSnowFox.gif',
    'fSpikeyPorcupine.gif',
    'fStinkySkunk.gif',
    'fTimberWolf.gif',
    'fTinyChick.gif'
  ];
  
  const friendsLoc = "/assets/friends/";
  // const friendsLoc = "/assets/";
  /**
   * Generates a friend's asset URL.
   * @param fid Optional index of the friend. Randomly selected if not provided or invalid.
   * @returns The full URL to the friend's asset.
   */
  export function generateFriend(fid?: number): string {
    const index = fid !== undefined && fid >= 0 && fid < friends.length ? fid : Math.floor(Math.random() * friends.length);
    return `${friendsLoc}${friends[index]}`;
  }
  
