export function formatTime(timeString) {
  // console.log("formatTime: ", timeString)
  if (timeString){
  const parts = timeString.split(":")
  const hours = parts[0]
  const minutes = parts[1]
  const seconds = parseInt(parts[2])

  // Hours should have 1 zero placeholder
  const formattedHours = hours.length === 1 ? `0${hours}` : hours
  // Minutes and seconds should have 2 zero placeholders
  const formattedMinutes = minutes.length < 2 ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  } else {
    return ""
  }
}

export const convertToSeconds = (time: string): number => {
  const [hours, minutes, seconds] = time.split(":").map(parseFloat)
  return hours * 3600 + minutes * 60 + seconds
}

//Returns time/clock format when number of seconds provided
export const formatTimeRP = (seconds) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)
  return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
    .filter(Boolean)
    .join(":")
}

export function getFontSizeAndLineHeight() {
  const isPortrait = window.innerHeight > window.innerWidth
  const size = isPortrait ? "5.8vw" : "5.8vh"
  const lh = isPortrait ? "6.8vw" : "6.8vh"
  return { fontSize: size, lineHeight: lh }
}

//REturn ISO 8601 time format, i.e. PT1H0M5S
export const secondsToYouTube8601 = (totalSecondsStr) => {
  const totalSeconds = parseInt(totalSecondsStr, 10);

  if (isNaN(totalSeconds)) {
    throw new Error("Invalid seconds input");
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
  const seconds = totalSeconds - (hours * 3600) - (minutes * 60);
  
  let result = 'PT';
  
  if (hours > 0) {
    result += `${hours}H`;
  }
  if (minutes > 0 || hours > 0) { // Including hours check to ensure '0M' in cases like PT1H0M5S
    result += `${minutes}M`;
  }
  result += `${seconds}S`;
  
  return result;
}

//Extract date from datetime ISO string
export const extractISODate = (datetimeStr) => {
  // Check if the input is blank or not a string
  if (!datetimeStr || typeof datetimeStr !== 'string') {
    return '';
  }

  // Extract the date part before the 'T' character
  const datePart = datetimeStr.split('T')[0];

  // Validate the extracted date
  if (!datePart || isNaN(Date.parse(datePart))) {
    return '';
  }

  return datePart;
};
