import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import * as serviceWorkerRegistration from "./others/serviceWorkerRegistration"
import reportWebVitals from "./others/reportWebVitals"
// import {setupIonicReact } from '@ionic/react';

const container = document.getElementById("root")
const root = createRoot(container!)

// Initialize Ionic React with global mode
// setupIonicReact({
//   mode: 'ios',
// });

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
// serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
