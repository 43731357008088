import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useLocation, useParams } from "react-router"
import ReactPlayer from "react-player"
import { Browser } from '@capacitor/browser';
import {
  IonIcon,
  IonCard,
  IonRippleEffect,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonSegmentButton,
  IonSegment,
  IonThumbnail,
  IonButton,
  IonTextarea,
  IonToast,
  IonModal,
  IonInput,
  IonTitle,
  IonContent,
  IonRadioGroup,
  IonRadio,
  IonList,
  IonAlert,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonRouterLink,
  IonSkeletonText,
  IonSearchbar,
  IonCheckbox,
  useIonToast,
  IonListHeader,
} from "@ionic/react"
import {
  createOutline,
  settingsOutline,
  trashOutline,
  informationCircleOutline,
  gridOutline,
  copyOutline,
  repeatOutline,
  constructOutline,
  construct,
  hammerOutline,
  refreshOutline,
  globeOutline
} from "ionicons/icons"
import {
  apiYT,
  downloadYoutubeSubtitles,
  convertYouTubeDurationToSeconds,
} from "../utils/apiYT"
import { Storage } from "@ionic/storage"
import { languageMap, languageMapAvail } from "../utils/constants"
import HeartComponent from "../components/HeartComponent"
import favoriteVideosService from "../utils/FavoriteVideosService"
import historyVideosService from "../utils/HistoryVideosService"
import wordsService from "../utils/WordsService"
import BookmarkComponent from "../components/BookmarkComponent"
import bookmarkTilesService from "../utils/BookmarkTilesService"
import PlayerControls from "../components/PlayerControls"
import PlayerSubs from "../components/PlayerSubs/PlayerSubs"
import SubtitleEditor from "../components/SubtitleEditor/SubtitleEditor"
import { useMediaQuery } from "react-responsive"
import "./Zplayer.scss"
import "../components/SubtitleEditor/SubtitleEditor.scss"
import {
  convertToSeconds,
  formatTime,
  formatTimeRP,
  getFontSizeAndLineHeight,
  secondsToYouTube8601,
} from "../utilities/utilities"
import { Clipboard } from "@capacitor/clipboard"
import userActivityService from "../utils/UserActivityService"
// import { createEditor } from 'slate';
// import { Slate, Editable, withReact } from 'slate-react';
// import { BaseElement,Descendant } from 'slate';

const { vtt } = require("../mockdata")

// Create an instance of Ionic Storage
const storage = new Storage()
storage.create()

// Define the type of the subtitle object
type Subtitle = {
  i: number
  s: string
  e: string
  t: string
}

type VideoDetail = {
  categoryId: string;
  lengthSeconds: string;
  title: string;
  channelId: string;
  channelTitle: string;
  viewCount: string;
  publishDate: string;
  description: string;
  thumbnail: { url: string }[];
  availableCountries: string[];
};

//slate react types
// type CustomElement = BaseElement & { type: string };
// type CustomText = { text: string };
// type CustomDescendant = CustomElement | CustomText;

// const initialValue: CustomDescendant[] = [
//   {
//     type: 'paragraph',
//     children: [{ text: 'Enter your note here...' }],
//   },
// ];

const Zplayer = () => {
  let duration = ""
  const intervalRef = useRef<any>() //used for interval tracking, doesn't invoke rerenders and persists across renders

  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 768px)" })
  
  //These subtitles indexes are for the actual index value stored in to each subtitle under key i. There is a separate index for tiles as that list changes
  const [activeSubtitleIndex, setActiveSubtitleIndex] = useState(-1) //activate index of selected user lang
  const [activeSubtitleIndexOrig, setActiveSubtitleIndexOrig] = useState(-1) //activate index of orig lang
  const [activeChapterIndex, setActiveChapterIndex] = useState(-1) //activate index of selected user lang
  //Refs needed to access latest values when within closures like the setIntervals inside the useEffects
  const activeSubtitleIndexRef = useRef(activeSubtitleIndex); //need refs to help prevent setting state var mult times on change
  const activeSubtitleIndexOrigRef = useRef(activeSubtitleIndexOrig);
  const lastSubtitleIndexRef = useRef(-1); // Tracks the last active subtitle index
  const lastSubtitleIndexOrigRef = useRef(-1); // Tracks the last active subtitle index
  const currentStartRef = useRef(0); // Start time of current active sub
  const currentStartOrigRef = useRef(0); // Start time of current active sub
  const currentEndRef = useRef(0); // End time of current active sub
  const currentEndOrigRef = useRef(0); // End time of current active sub
  const nextStartRef = useRef(0); // Start time of next sub after active one
  const nextStartOrigRef = useRef(0); // Start time of next sub after active one
  // These index values pertain to Tile indexes not the actual subtitle's index key "i" that's stored with each sub
  //   these are used to keep track of which tiles are active because the list of tiles displayed might be filtered
  //   by a search term or by bookmarks
  const [activeTilePosSubIndex, setActiveTilePosSubIndex] = useState(-1) //active index of selected user lang
  const [activeTilePosSubIndexOrig, setActiveTilePosSubIndexOrig] = useState(-1) //active index of orig lang

  //Booksmarks playback
  const [playBookmarksOnly, setPlayBookmarksOnly] = useState(true) //defaults to only only bookmarks on bookmarks tab
  const [currentBookmarkIndex, setCurrentBookmarkIndex] = useState(0);
  const [isPlayingBookmarks, setIsPlayingBookmarks] = useState(false);

  const [isDots, setIsDots] = useState(false); // controls show/hide of romanized overlay text

  const [subtitlesEnabled, setSubtitlesEnabled] = useState(false)
  const [subtitlesOrigEnabled, setSubtitlesOrigEnabled] = useState(false)

  // Add a state to store the active subtitle
  const [activeSubtitle, setActiveSubtitle] = useState<Subtitle | null>(null)
  const [videos, setVideos] = useState(null)

  //Video playback
  const [playing, setPlaying] = useState(false)
  const playingRef = useRef(playing); // to access current value inside closures
  const [playbackRate, setPlaybackRate] = useState(1)
  const [count, setCount] = useState(0) // remove after confirming no one is using this
  const [loopCount, setLoopCount] = useState(1)
  const [currentTime, setCurrentTime] = useState(0)
  const [durationRP, setDurationRP] = useState(0)

  const playerRef = useRef<ReactPlayer>(null)
  const { id } = useParams<{ id: string }>() //get the id from route param
  //const [videoDetail, setVideoDetail] = useState(null) //contains the YT response payload from api call
  const [videoDetail, setVideoDetail] = useState<VideoDetail | null>(null);
  //store the keys from subtitles_json to populate the select lang popup
  const [subtitleLanguagesGen, setSubtitleLanguagesGen] = useState<string[]>([]) //list of original processed subtitles
  const [subtitleLanguages, setSubtitleLanguages] = useState<string[]>([]) //all subtitles including custom subs
  const [custSubtitles, setCustSubtitles] = useState<string[]>([]) //custom subs only

  // Add a ref to store the active button
  const activeButtonRef = useRef<HTMLIonButtonElement>(null)

  //const buttonRefs = useRef<HTMLIonButtonElement[]>([]);
  const buttonRefs = useRef<(HTMLIonCardElement | null)[]>([])

  const [isFavorite, setIsFavorite] = useState(false) // only client side datat

  const [firstLoad, setFirstLoad] = useState(true)

  // DB operations
  const [videoUrl, setVideoUrl] = useState("")
  const [languageCode, setLanguageCode] = useState("en")
  const [languageCodeOrig, setLanguageCodeOrig] = useState("")
  const [vttLoaded, setVttLoaded] = useState<Subtitle[]>([]) //user selected-target language of video subtitle
  const [vttLoadedOrig, setVttLoadedOrig] = useState<Subtitle[]>([]) //original-native language of video subtitle
  const [vttLoadedOrigRom, setVttLoadedOrigRom] = useState<Subtitle[]>([]) //for mandarin, japanese, korean phonetics also loaded

  const [chaptersLoaded, setChaptersLoaded] = useState<Subtitle[]>([]) //chapters data for video
  //tiles that are bookmarked for view purposes, this list should usually be stable unless the view is changed, allowing the user to add or remove bookmarks without view changing
  const [bookmarksLoaded, setBookmarksLoaded] = useState<number[]>([]) // View: selected language bookmarks for the video, only client side data, format ex: [2,15,33], used for display between segments switches
  //actual bookmarks list that are used to manipulate, add or remove tiles. This allows the view to remain stable while the user adds/removes bookmarks
  const [bookmarkedTiles, setBookmarkedTiles] = useState<number[]>([]) // Temp to hold changes before saving: selected language bookmarks for the video, used to change bookmarks data
  const [tilesFilterValue, setTilesFilterValue] = useState<string>("all") //determines how the tiles are filtered, default is all, other values are bookmarked, chapters
  const [tilesDisplayOrig, setTilesDisplayOrig] = useState(false) //determines which tiles are displayed, default is "false", which is display the selected tiles

  //Accordion subtitles files
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null)
  const [popoverContent, setPopoverContent] = useState("")
  //const [popoverContentOrig, setPopoverContentOrig] = useState('');
  const [videoLengthSeconds, setVideoLengthSeconds] = useState(0)
  const [processingMessage, setProcessingMessage] = useState("")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const wordTileIndexParam = queryParams.get('wordTileIndex');

  //Subtitle Editing
  const [editingSubtitleIndex, setEditingSubtitleIndex] = useState<
    number | null
  >(null)
  const [isSubtitleEditorOpen, setIsSubtitleEditorOpen] = useState(false)
  const [editedSubtitle, setEditedSubtitle] = useState({
    s: "",
    e: "",
    t: "",
  })

  const [noteContent, setNoteContent] = useState("")

  //slate-react editor
  //const editor = useMemo(() => withReact(createEditor()), []);
  //const [value, setValue] = useState<CustomDescendant[]>(initialValue);

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("")

  //Subtitle tiles create
  const [showModal, setShowModal] = useState(false)
  const [custName, setCustName] = useState("")
  const [subtitleType, setSubtitleType] = useState("blank") // New state for radio button selection
  const [custSubtitlesList, setCustSubtitlesList] = useState<string[]>([]) //*****CUSTOM subs
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [subtitleToDelete, setSubtitleToDelete] = useState("")
  const [showMediaCard, setShowMediaCard] = useState(false)

  const [searchTerm, setSearchTerm] = useState("")
  const [useTileSubsOrig, setUseTileSubsOrig] = useState(false)

  const [loopBookmarks, setLoopBookmarks] = useState(false) //bookmark looping toggle
  const [presentToast, dismissToast] = useIonToast();

  const [workbenchSegment, setWorkbenchSegment] = useState("activities")
  const [isActLoading, setIsActLoading] = useState(true);


  const activityTitles = [
    "Understand and speak",
    "Transcribe to target and correct",
    "Review & Test Yourself",
    "Translate to native in your own words",
    "Summarize & Critique"
  ];
  
  const activitySubtitles = [
    "Watch the video once passively, use subtitle translations to understand the general context. On subsequent viewings speak outloud and bookmark tiles you want to work on.",
    "Without looking, Transcribe to target language, create flashcards by adding to deck from the Recent Words Page, and adjust bookmarks to only difficult tiles.",
    "Do the Quiz on Flashcards and review video by focusing on bookmarked tiles.",
    "Listen and Translate into your native language, in your own words. Only look at target transcriptions when running into difficulty. Your translation should be slightly different from what is provided with the video.",
    "Write a summary, critique, or story in your target language about the content using words from the video, go wild! \u{1F601}"
  ];
    // Initialize each item’s checked state to false
    const [checkedStates, setCheckedStates] = useState<boolean[]>(
      () => activityTitles.map(() => false)
    );

  // const handleCheckChange = (index: number, isChecked: boolean) => {
  //   // Create a copy of the array and toggle the specific index
  //   const updated = [...checkedStates];
  //   updated[index] = isChecked;
  //   setCheckedStates(updated);
  // };
const handleCheckChange = async (index: number, isChecked: boolean) => {
  console.log("?????????: handleCheckChange called for index:", index, "isChecked:", isChecked);

  const updated = [...checkedStates];
  updated[index] = isChecked;
  setCheckedStates(updated);

  try {
    const metaKey = `meta_${id}`;
    let metaString = await storage.get(metaKey);
    let metaObj = metaString ? JSON.parse(metaString) : {};

    // Only update the activity field, preserving other fields.
    metaObj.activity = updated;
    await storage.set(metaKey, JSON.stringify(metaObj));
    console.log("Activity states updated in storage.");
  } catch (error) {
    console.error("Error updating user activity:", error);
  }
};

  const loadActivityStatus = async () => {
    try {
      const metaKey = `meta_${id}`;
      const metaString = await storage.get(metaKey);
      if (metaString) {
        const metaObj = JSON.parse(metaString);
        // If there's an array 'activity' with the same length as activityTitles
        if (Array.isArray(metaObj.activity) && metaObj.activity.length === activityTitles.length) {
          // setCheckedStates(metaObj.activity);
          setCheckedStates([...metaObj.activity]);

        }
      }
    } finally {
      setIsActLoading(false)
    }
  };
  useEffect(() => {
    if (showModal && workbenchSegment === "activities") {
      loadActivityStatus();
    }
  }, [showModal, workbenchSegment, id]);

  



  
  //add translated word to recents list
  const addWordToRecents = (wordObj) => {
    wordsService.addToWords(videoDetail, wordObj.sourceL, wordObj.sourceText, wordObj.sourceTile, wordObj.targetL, wordObj.targetText, wordObj.targetTile);
  }

    //add word to vocab list
  const addWordToVocabList = () => {

    
  }


  //Refresh subtitles
  const handleRefreshSubtitles = async () => {
    try {
      const subtitlesKey = `subtitles_${id}`;
      await storage.remove(subtitlesKey); // Remove existing subtitles
      setVttLoaded([]); // Clear subtitles state
  
      // Ensure videoDetail is defined before proceeding
      if (videoDetail) {
        // Fetch new subtitles from server
        const video_meta = await downloadYoutubeSubtitles(
          id,
          languageCodeOrig,
          languageCode,
          durationRP,
          false,
          videoDetail.categoryId,
          videoDetail.channelId,
          videoDetail.channelTitle
        );
  
        const subtitles_json = video_meta["subtitles_json"];
        const orig_lang = video_meta["origLang"];
  
        if (Object.keys(subtitles_json).length != 0) {
          setVttLoaded(subtitles_json[languageCode] || []);
          setVttLoadedOrig(subtitles_json[orig_lang] || []);
          setVttLoadedOrigRom(subtitles_json[`${orig_lang.slice(0, 2)}rom`] || []);
          storeSubtitles(id, subtitles_json, orig_lang); // Save new subtitles to Ionic Storage
        }
  
        presentToast({
          message: 'Subtitles refreshed successfully.',
          duration: 2000,
          position: 'top'
        });
      } else {
        presentToast({
          message: 'Video details are not available.',
          duration: 2000,
          position: 'top'
        });
      }
    } catch (error) {
      console.error("Error refreshing subtitles:", error);
      presentToast({
        message: 'Error refreshing subtitles.',
        duration: 2000,
        position: 'top'
      });
    }
  };



  //Open Google Translate
  const openGoogleTranslate = async (text, event) => {
    event.stopPropagation();
    const encodedText = encodeURIComponent(text);

    //determine which tiles were clicked on, the orig lang or target language. 
    //If value of useTileSubsOrig is false then we need to switch the source and target for trnslation
    let sourceLang, targetLang;
    if (useTileSubsOrig) {
      // Default to auto-detect if source language is not specified
      sourceLang = languageCodeOrig || 'auto';
      // Default to English if target language is not specified
      targetLang = languageCode || 'en';
    } else {
      // Default to auto-detect if source language is not specified
      sourceLang = languageCode || 'en';
      // Default to English if target language is not specified
      targetLang = languageCodeOrig || 'auto';
    }

    const url = `https://translate.google.com/?sl=${sourceLang}&tl=${targetLang}&text=${encodedText}&op=translate`;
    await Browser.open({ url });
  };
  
  //Search algo to find the index of a subtitle by time
  function binarySearchSubtitles(subtitles, currentTime) {
    let low = 0;
    let high = subtitles.length - 1;
  
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const start = convertToSeconds(subtitles[mid].s);
      const end = convertToSeconds(subtitles[mid].e);
  
      if (start <= currentTime && currentTime <= end) {
        return mid;
      } else if (currentTime < start) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }
    return -1; // not found
  }

  //Search algo to find the positional index of a subtitle by the specific sub's id value
  function binarySearchById(subtitles, targetId) {
    let low = 0;
    let high = subtitles.length - 1;

    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const midIndex = subtitles[mid].i;

        if (midIndex === targetId) {
            return mid;
        } else if (targetId < midIndex) {
            high = mid - 1;
        } else {
            low = mid + 1;
        }
    }
    return -1; // Target index not found
}

  const openMediaCard = () => {
    setShowMediaCard(true)
  }

  // Tile searching functions, uses filteredSubtitles which is used to render the subtitle tiles ***
  const handleTileSearch = (e: any) => {
    setSearchTerm(e.detail.value)
  }
  const subtitlesToFilter = useTileSubsOrig ? vttLoadedOrig : vttLoaded
  // const filteredSubtitles = subtitlesToFilter.filter((subtitle) =>
  //   subtitle.t.toLowerCase().includes(searchTerm.toLowerCase())
  // )

  const filteredSubtitles = subtitlesToFilter
  .filter(subtitle =>
    subtitle.t.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .filter((_, index) =>
    tilesFilterValue !== "bookmarked" || bookmarksLoaded.includes(index)
  );

  // JMR replace the chain above with useMemo below for more efficient processing, TEST it
  // const filteredSubtitles = useMemo(() => {
  //   return subtitlesToFilter
  //     .filter(subtitle =>
  //       subtitle.t.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //     .filter((_, index) =>
  //       tilesFilterValue !== "bookmarked" || bookmarksLoaded.includes(index)
  //     );
  // }, [subtitlesToFilter, searchTerm, tilesFilterValue, bookmarksLoaded]);

  // const filteredSubtitles = useMemo(() => {
  //   const afterSearchFilter = subtitlesToFilter.filter(subtitle =>
  //     subtitle.t.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   console.log('After Search Term Filter:', afterSearchFilter);

  //   const afterBookmarkFilter = afterSearchFilter.filter((_, index) =>
  //     tilesFilterValue !== "bookmarked" || bookmarksLoaded.includes(index)
  //   );
  //   console.log('After Bookmark Filter:', afterBookmarkFilter);

  //   return afterBookmarkFilter;
  // }, [subtitlesToFilter, searchTerm, tilesFilterValue, bookmarksLoaded]);

  
  const matchCount = filteredSubtitles.length


  const handleDeleteSubtitle = async () => {
    const videoSubtitlesKey = `c_subtitles_${id}`
    const existingSubtitlesString = await storage.get(videoSubtitlesKey)
    let existingSubtitles = existingSubtitlesString
      ? JSON.parse(existingSubtitlesString)
      : {}

    delete existingSubtitles[subtitleToDelete] // Delete the selected subtitle

    await storage.set(videoSubtitlesKey, JSON.stringify(existingSubtitles))
    setToastMessage(`Subtitle '${subtitleToDelete}' deleted successfully.`)
    setShowToast(true)
    fetchSubtitlesList() // Refresh the list
    setShowDeleteAlert(false) // Close the alert
  }

  // Fetch and update the list of CUSTOM subtitles
  const fetchSubtitlesList = async () => {
    const videoSubtitlesKey = `c_subtitles_${id}`
    const existingSubtitlesString = await storage.get(videoSubtitlesKey)
    const existingSubtitles = existingSubtitlesString
      ? JSON.parse(existingSubtitlesString)
      : {}
    setCustSubtitlesList(Object.keys(existingSubtitles))
  }

  const handleCreateSubtitles = async () => {
    if (!custName.trim()) {
      setToastMessage("Please enter a name for the subtitles.")
      setShowToast(true)
      return
    }

    const videoSubtitlesKey = `c_subtitles_${id}`
    // let existingSubtitles = await storage.get(videoSubtitlesKey) || {};
    const existingSubtitlesString = await storage.get(videoSubtitlesKey)
    let existingSubtitles = existingSubtitlesString
      ? JSON.parse(existingSubtitlesString)
      : {}
    console.log("Custom SUBS: ", existingSubtitles)
    let uniqueName = custName.trim()
    let counter = 1

    // Check if the name exists and find a unique name
    while (existingSubtitles.hasOwnProperty(uniqueName)) {
      uniqueName = `${custName.trim()}-${counter}`
      counter++
    }

    let newSubtitles = [...vttLoaded] // Copy the existing subtitles

    // If selected blank option, blank out the 't' values
    if (subtitleType === "blank") {
      newSubtitles = newSubtitles.map((subtitle) => ({ ...subtitle, t: "" }))
    }

    console.log("saving subtitle name: " + uniqueName)

    try {
      // Update the CUSTOM subtitles for this video
      existingSubtitles[uniqueName] = newSubtitles
      await storage.set(videoSubtitlesKey, JSON.stringify(existingSubtitles))
      setCustSubtitlesList(Object.keys(existingSubtitles)) //update state variable for cust subs
      setToastMessage(`Subtitles '${uniqueName}' created successfully.`)
      setShowToast(true)
    } catch (error) {
      console.error("Error saving subtitles:", error)
      setToastMessage("Error saving subtitles.")
      setShowToast(true)
    }
    setCustName("")
    setShowModal(false) // Close the modal
  }

  //Save note to the video's own key 'note_videoId'----------------------
  const handleSaveNote = async () => {
    try {
      const noteKey = `note_${id}` // Assuming 'id' is the videoId
      if (noteContent.trim()) {
        // Save video info only if videoDetail is not null
        let videoData = {}
        // if (videoDetail) {
        //   videoData = {
        //     id: id,
        //     title: videoDetail["title"],
        //     channelTitle: videoDetail["channelTitle"],
        //     channelId: videoDetail["channelId"],
        //     description: videoDetail["description"],
        //     thumbnail: videoDetail["thumbnail"][4]
        //       ? videoDetail["thumbnail"][4]["url"]
        //       : videoDetail["thumbnail"][0]["url"],
        //   }
        //   // Optionally, handle videoData here if needed
        // }
        if (videoDetail) {
          videoData = {
            id: id,
            title: videoDetail["title"],
            channelTitle: videoDetail["channelTitle"],
            channelId: videoDetail["channelId"],
            description: videoDetail["description"],
            thumbnail: videoDetail["thumbnail"] 
              ? (videoDetail["thumbnail"][4]?.url || videoDetail["thumbnail"][0]?.url)
              : "/assets/generic.png",  // Default to a placeholder image if thumbnail is undefined
          }
          // Optionally, handle videoData here if needed
        }

        // If noteContent is not empty, save it as an object to Ionic Storage
        const noteObject = { text: noteContent, videoData: videoData }
        await storage.set(noteKey, noteObject)
        setToastMessage("Note saved successfully.")
      } else {
        // If noteContent is empty, remove the key from Ionic Storage
        await storage.remove(noteKey)
        setToastMessage("Note was empty, so it was removed.")
      }
      setShowToast(true)
    } catch (error) {
      console.error("Failed to save or remove note:", error)
      setToastMessage("Failed to save note.")
      setShowToast(true)
    }
  }

  //Copy Note contents to clipboard
  const copyToClipboard = async () => {
    if (noteContent.trim()) {
      await Clipboard.write({
        string: noteContent.trim(),
      })
      setToastMessage("Note copied to clipboard.")
      setShowToast(true)
    }
  }

  //Copy Note contents to clipboard
  const copyTileToClipboard = async (text, event) => {
    event.stopPropagation()
    if (text.trim()) {
      await Clipboard.write({
        string: text.trim(),
      })
      setToastMessage("Text copied to clipboard.")
      setShowToast(true)
    }
  }

  /* FOR SLATE-REACT implementation 
  const handleSaveNote = async () => {
    try {
      const noteKey = `note_${id}`; // Assuming 'id' is the videoId
      const content = JSON.stringify(value); // Convert Slate value to JSON string
  
      if (content && content !== '[{"type":"paragraph","children":[{"text":""}]}]') {
        await storage.set(noteKey, content);
        console.log("Note saved successfully.");
      } else {
        await storage.remove(noteKey);
        console.log("Note was empty, so the key was removed from Ionic Storage.");
      }
    } catch (error) {
      console.error("Failed to save or remove note:", error);
    }
  };
  */

  function parseDescription(
    description: string,
    lengthSeconds: string
  ): Subtitle[] {
    const chapterPattern = /(\d{1,2}:\d{1,2}(?::\d{1,2})?) (.+)/g
    let match: RegExpExecArray | null
    const matches: RegExpExecArray[] = []

    while ((match = chapterPattern.exec(description)) !== null) {
      matches.push(match)
    }

    const chapters: Subtitle[] = []
    matches.forEach((match, index) => {
      const nextTime = index + 1 < matches.length ? matches[index + 1][1] : null

      const currentTimeParts = match[1].split(":").map(Number)
      const nextTimeParts = nextTime ? nextTime.split(":").map(Number) : null

      let endSeconds
      if (nextTimeParts) {
        switch (nextTimeParts.length) {
          case 2:
            // ending should be 100ms less than the start of the next segment
            endSeconds = (nextTimeParts[0] * 60 + nextTimeParts[1]) * 1000 - 100
            break
          case 3:
            endSeconds =
              (nextTimeParts[0] * 3600 +
                nextTimeParts[1] * 60 +
                nextTimeParts[2]) *
                1000 -
              100
            break
          default:
            break
        }
      } else if (index === matches.length - 1) {
        // this is the last chapter
        endSeconds = parseInt(lengthSeconds) * 1000
      } else {
        endSeconds = null
      }

      const endTime = new Date(endSeconds)
      const endHours = String(endTime.getUTCHours()).padStart(2, "0")
      const endMinutes = String(endTime.getUTCMinutes()).padStart(2, "0")
      const endSecondsFormatted = String(endTime.getUTCSeconds()).padStart(
        2,
        "0"
      )

      let startTime = match[1]
      switch (currentTimeParts.length) {
        case 2:
          startTime = formatTime(
            `00:${currentTimeParts[0]}:${currentTimeParts[1]}`
          )
          break
        case 3:
          startTime = formatTime(match[1])
          break
        default:
          break
      }

      chapters.push({
        //i: (index + 1).toString(),
        i: index + 1,
        s: startTime,
        e: endSeconds
          ? `${endHours}:${endMinutes}:${endSecondsFormatted}`
          : "End",
        t: match[2],
      })
    })

    return chapters
  }

  const handleEditSubtitle = (subtitle, event) => {
    event.stopPropagation()
    setEditingSubtitleIndex(vttLoaded.indexOf(subtitle))
    console.log("[Called handleEditSubtitle]", subtitle)
    setEditedSubtitle(subtitle)
    setIsSubtitleEditorOpen(true)
  }

  const handleSaveEditedSubtitle = (editedSubtitle) => {

    if (editingSubtitleIndex !== null) {
      //determine which subs were edited, if updating the orig or selected (which can actually have the orig also selected)
      let updatedLangSubtitles = [...vttLoaded]
      updatedLangSubtitles[editingSubtitleIndex] = editedSubtitle
      setVttLoaded(updatedLangSubtitles)
console.log("Updated subtitle index: " + editingSubtitleIndex, editedSubtitle)
      const isCustomSubtitle = custSubtitlesList.includes(languageCode)
      updateStorageWithSubtitles(updatedLangSubtitles, isCustomSubtitle)
    }

    setIsSubtitleEditorOpen(false)
  }

  const updateStorageWithSubtitles = async (
    updatedLangSubtitles,
    isCustom = false
  ) => {
    try {
      const videoSubtitlesKey = isCustom
        ? `c_subtitles_${id}`
        : `subtitles_${id}`

      // Fetch existing subtitles for the video from Ionic Storage
      // const storedData = await storage.get(`subtitles_${id}`)
      const storedData = await storage.get(videoSubtitlesKey)
      // let subtitlesData = JSON.parse(storedData || "{}")
      let subtitlesData = storedData ? JSON.parse(storedData) : {}

      // Update the specific language subtitles
      // subtitlesData.subs[languageCode] = updatedLangSubtitles
      if (isCustom) {
        subtitlesData[languageCode] = updatedLangSubtitles
      } else {
        // Assuming standard subtitles are stored under their lang key
        subtitlesData.subs[languageCode] = updatedLangSubtitles
      }

      // Save the updated subtitles back to Ionic Storage
      await storage.set(videoSubtitlesKey, JSON.stringify(subtitlesData))
      console.log(`Subtitles for ${languageCode} saved to Ionic Storage`)
    } catch (error) {
      console.error("Error updating subtitle:", error)
    }
  }

  //udpate playing status for non-ui components to get the current state
  useEffect(() => {
    playingRef.current = playing;
  }, [playing]);

  useEffect(() => {
    setPlaying(location?.pathname.includes("/video/") && !firstLoad)
  }, [location, firstLoad])

  //Save subtitles to local storage
  const storeSubtitles = async (
    videoId: string,
    subtitles_json: object,
    orig_lang: string
  ) => {
    try {
      // Save subtitles to Ionic Storage
      await storage.set(
        `subtitles_${videoId}`,
        JSON.stringify({ subs: subtitles_json, origLang: orig_lang })
      )
      console.log("Subtitles saved to Ionic Storage")
      // Store the keys from subtitles_json in subtitleLanguages to indicate available subs languages, <--- DELETE?
      //setSubtitleLanguages(Object.keys(subtitles_json))
    } catch (error) {
      console.error("Error storing subtitles:", error)
    }
  }

  //Attempt to retrieve subs from local storage
  const retrieveSubtitles = async (video_id: string, langCode: string) => {
    try {
      //const subtitles = await storage.get(`subtitles_${video_id}`);
      const storedData = await storage.get(`subtitles_${video_id}`)
      //const subtitles_json = JSON.parse(subtitles);
      const metaData = JSON.parse(storedData)

      const videoSubtitlesKey = `c_subtitles_${id}`
      const customSubtitlesString = await storage.get(videoSubtitlesKey)
      const customSubtitles = customSubtitlesString
        ? JSON.parse(customSubtitlesString)
        : {}
      const customSubtitleNames = Object.keys(customSubtitles)
      // setCustSubtitles(customSubtitleNames) //save list of custom subs DELETE me after confirm
      setCustSubtitlesList(customSubtitleNames) //save list of custom subs

      //if (subtitles_json) {
      if (metaData && metaData.subs) {
        //get orig language
        let orig_lang

        // if (languageCodeOrig === "") {
        orig_lang = metaData["origLang"]
        setLanguageCodeOrig(orig_lang) // Update state variable
        //   console.log("Orig lang changed from META "+orig_lang);
        // } else {
        //   orig_lang = languageCodeOrig;
        //   console.log("Orig lang changed from languageCodeOrig "+orig_lang);
        // }

        const subtitles_json = metaData["subs"]
        // populate language selection popup from subtitles_json in subtitleLanguages  to indicate available subs languages
        //setSubtitleLanguages(Object.keys(subtitles_json))

        // setSubtitleLanguages(
        //   Object.keys(subtitles_json).filter((key) => !key.endsWith("rom"))
        // )

        setSubtitleLanguagesGen(
          Object.keys(subtitles_json).filter((key) => !key.endsWith("rom"))
        ) //save list of generated subs provided by BE

        // Combine standard and custom subtitles to create an ALL list of subs
        const allSubtitleLanguages = [
          ...Object.keys(metaData.subs).filter((key) => !key.endsWith("rom")),
          ...customSubtitleNames,
        ]
        setSubtitleLanguages(allSubtitleLanguages)

        // load selected language subs
        if (subtitles_json[langCode]) {
          setVttLoaded(subtitles_json[langCode])
          // console.log(`Subtitles for SELECTED lang '${langCode}' retrieved from Ionic Storage:`, subtitles_json[langCode])

          //Load bookmarks from local storage (indexedDB) if not already set
          //This will will be the set of tiles the user interacts with, meaning this array does not change until the next "refresh"
          // i.e. user switches to all tiles view or navigates out and back to video. The indexes are used to only toggle the bookmark in local storage
          //So initial load of bookmarks happens here and then subsequent refreshes happen as user switches tile views from All to Bookmarks.
          const bookmarks = await bookmarkTilesService.getBookmarks(
            video_id,
            langCode
          )
          setBookmarksLoaded(bookmarks)
          setBookmarkedTiles(bookmarks)
        }
        // load orig language subs
        if (subtitles_json[orig_lang]) {
          setVttLoadedOrig(subtitles_json[orig_lang])

          //for the 3 languages zh, ja, ko, add the romanization
          const key = orig_lang.slice(0, 2) + "rom" // Extract the first two characters of orig_lang and append 'rom'
          setVttLoadedOrigRom(subtitles_json[key] ? subtitles_json[key] : [])

          //Set player to continue from last index --------------
          //Video player can resume from other last play (lastIndexOrig) or
          //directly linked from a vocabulary word to a specific tile (wordTileIndexParam)

          //get meta data from ionic storage
          const metaDataString = await storage.get(`meta_${video_id}`);
          const metaData = JSON.parse(metaDataString);
          console.log("Got metaData: ", metaData);

          //seek player to last played indexes
          // if (metaData && tilesFilterValue == "all"){
          //   setActiveSubtitleIndexOrig(metaData['lastIndexOrig']);
          //   setActiveSubtitleIndex(metaData['lastIndex']);
          //   setActiveTilePosSubIndex(metaData['lastIndex']);

          //   //scroll to last index tile
          //   const currentRef = useTileSubsOrig
          //     ? buttonRefs.current[activeTilePosSubIndexOrig]
          //     : buttonRefs.current[activeTilePosSubIndex]

          //   if (currentRef) {
          //     currentRef.scrollIntoView({
          //       behavior: "smooth",
          //       block: "center",
          //     })
              
          //     //seek to of last index
          //     playerRef.current?.seekTo(convertToSeconds(vttLoadedOrig[metaData['lastIndex']].s))
          //     //console.log("SEEKING TO TIME: "+ convertToSeconds(vttLoadedOrig[indexOrig].s));
          //   }

          if (wordTileIndexParam  && tilesFilterValue == "all"){
            setActiveSubtitleIndexOrig(parseInt(wordTileIndexParam));
            setActiveSubtitleIndex(parseInt(wordTileIndexParam));
            setActiveTilePosSubIndex(parseInt(wordTileIndexParam));

            //scroll to last index tile
            const currentRef = useTileSubsOrig
              ? buttonRefs.current[activeTilePosSubIndexOrig]
              : buttonRefs.current[activeTilePosSubIndex]

            if (currentRef) {
              currentRef.scrollIntoView({
                behavior: "smooth",
                block: "center",
              })
              
              //seek to of last index
              playerRef.current?.seekTo(convertToSeconds(vttLoadedOrig[parseInt(wordTileIndexParam)].s))
            }//console.log("SEEKING TO TIME: "+ convertToSeconds(vttLoadedOrig[indexOrig].s));
          } else if (metaData && tilesFilterValue == "all") {
              setActiveSubtitleIndexOrig(metaData['lastIndexOrig']);
              setActiveSubtitleIndex(metaData['lastIndex']);
              setActiveTilePosSubIndex(metaData['lastIndex']);
  
              //scroll to last index tile
              const currentRef = useTileSubsOrig
                ? buttonRefs.current[activeTilePosSubIndexOrig]
                : buttonRefs.current[activeTilePosSubIndex]
  
              if (currentRef) {
                currentRef.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
                
                //seek to of last index
                playerRef.current?.seekTo(convertToSeconds(vttLoadedOrig[metaData['lastIndex']].s))
                //console.log("SEEKING TO TIME: "+ convertToSeconds(vttLoadedOrig[indexOrig].s));
              }
            }

            

            console.log("****> Set active indexes to: ",activeSubtitleIndexOrig );
            
            //----------------------------------------------------
          console.log(`Subtitles for ORIG lang '${orig_lang}' retrieved from Ionic Storage:`,subtitles_json[orig_lang])
        }
        return subtitles_json[langCode] || []
      } else {
        console.log(`No subtitles found for '${langCode}' in Ionic Storage.`)
        // Clear the generated buttons if there are no subtitles
        setVttLoaded([])
        return []
      }
    } catch (error) {
      console.error("Error retrieving subtitles:", error)
      return []
    }
  }

  // Attempt to retrieve subs from server or trigger processing for next time
  const retrieveSubtitlesBE = useCallback(
    async (videoId, orig_lang, translated_lang, duration, categoryId, channelId, channelTitle) => {
      try {
        const subtitle_request = {
          orig_lang: orig_lang,
          translated_lang: translated_lang,
          duration: duration,
          categoryId: categoryId,
        }
        console.log("[retrieveSubtitlesBE]: Submitting subs request for: " + channelId +" "+ channelTitle)
        //const subtitles_json = await downloadYoutubeSubtitles(videoId, translated_lang, duration, hasCaptions, categoryId);
        const video_meta = await downloadYoutubeSubtitles(
          videoId,
          orig_lang,
          translated_lang,
          duration,
          false,
          categoryId,
          channelId,
          channelTitle
        )
        console.log(
          translated_lang + " Subtitles from server:",
          video_meta["subtitles_json"]
        )

        const videoSubtitlesKey = `c_subtitles_${id}`
        const customSubtitlesString = await storage.get(videoSubtitlesKey)
        const customSubtitles = customSubtitlesString
          ? JSON.parse(customSubtitlesString)
          : {}
        const customSubtitleNames = Object.keys(customSubtitles)
        setCustSubtitles(customSubtitleNames) //save list of custom subs

        //setVttLoaded([]);
        const subtitles_json = video_meta["subtitles_json"]

        if (Object.keys(subtitles_json).length != 0) {
          const orig_lang = video_meta["origLang"]
          // Process the received subtitles_json here, e.g., convert it to VTT format and save it locally
          storeSubtitles(videoId, subtitles_json, orig_lang)
          console.log(
            "Retrieved Subs from BACKEND: ",
            subtitles_json,
            orig_lang
          )

          // ORIG LANG: check if the received subs contains the original language of video (due to inferencing error, might come after translations)
          if (subtitles_json.hasOwnProperty(orig_lang)) {
            //load specified translation language
            setVttLoadedOrig(subtitles_json[orig_lang])
            setLanguageCodeOrig(orig_lang) //update state varible

            //for the 3 languages zh, ja, ko, add the romanization
            const key = orig_lang.slice(0, 2) + "rom" // Extract the first two characters of orig_lang and append 'rom'
            setVttLoadedOrigRom(subtitles_json[key] ? subtitles_json[key] : [])

            // setSubtitleLanguages(
            //   Object.keys(subtitles_json).filter((key) => !key.endsWith("rom"))
            // )

            setSubtitleLanguagesGen(
              Object.keys(subtitles_json).filter((key) => !key.endsWith("rom"))
            ) //save list of generated subs provided by BE
            // Combine standard and custom subtitles
            const allSubtitleLanguages = [
              ...Object.keys(subtitles_json).filter(
                (key) => !key.endsWith("rom")
              ),
              ...customSubtitleNames,
            ]
            setSubtitleLanguages(allSubtitleLanguages)

            // Store the keys from subtitles_json in subtitleLanguages
            //setSubtitleLanguages(Object.keys(subtitles_json));
          } else {
            console.error("Error: Orig lang not found!")
          }

          // SELECTED LANG: check if the received subs contains the user selected lang
          if (subtitles_json.hasOwnProperty(translated_lang)) {
            //load specified translation language
            setVttLoaded(subtitles_json[translated_lang])
            console.log("Translated Lang: ", subtitles_json[translated_lang])

            // Store the keys from subtitles_json in subtitleLanguages
            //setSubtitleLanguages(Object.keys(subtitles_json));
          } else {
            console.error(
              "Error: Selected subtitle not found, try one of the available languages."
            )
          }
        } else {
          console.log(
            "No Subs available from server, queued for processing, please check back again!"
          )
          // Set only custom subtitles if no standard subtitles are available
          setSubtitleLanguages(customSubtitleNames)
        }
      } catch (error) {
        console.error("Error retrieving subtitles from server:", error)
      }
    },
    []
  )

  //Set videoDetail and chapters
  const processVideoDetails = (videoDetail) => {
    //set videoDetail state var
    setVideoDetail(videoDetail)
    console.log("processing videoDetails: ", videoDetail)
    //extract chapter markers if any
    const chaps = parseDescription(
      videoDetail["description"],
      videoDetail["lengthSeconds"] || '60'
    )
    if (chaps) {
      console.log("Setting Chapters: ", videoDetail["lengthSeconds"], chaps)
      setChaptersLoaded(chaps)
    }
  }

  //--------------------*** HANDLERS ***/

  const handleLanguageChange = async (selectedLanguageCode: string) => {
    //JMR move to a settings page, temp solution is dropdown in menu
    setLanguageCode(selectedLanguageCode)
    //await storage.set("selectedLanguageCode", selectedLanguageCode) //updated user selected target language

    console.log("CHANNGED Lang to: ", selectedLanguageCode)
    console.log("customer subs are: ", custSubtitlesList)
    // Check if the selected language is a custom subtitle
    if (custSubtitlesList.includes(selectedLanguageCode)) {
      const videoSubtitlesKey = `c_subtitles_${id}`
      const existingSubtitlesString = await storage.get(videoSubtitlesKey)
      const existingSubtitles = existingSubtitlesString
        ? JSON.parse(existingSubtitlesString)
        : {}
      console.log(
        "CUSTOM Lang, retrieved following cust subs: ",
        existingSubtitles
      )
      const customSubtitles = existingSubtitles[selectedLanguageCode]

      setVttLoaded(customSubtitles || [])

      //Loads the bookmarks, this code is also used in handleSwapSubtitle, combine into function
      const bookmarks = await bookmarkTilesService.getBookmarks(
        id,
        useTileSubsOrig ?  languageCodeOrig : selectedLanguageCode
      )
      setBookmarkedTiles(bookmarks) //load in the appropriate copy of the bookmarks to display bookmark state
      setBookmarksLoaded(bookmarks)
      console.log("customer subs bookmarks: ", bookmarks)

    } else {
      retrieveSubtitles(id, selectedLanguageCode)
    }
  }

  const handleButtonClickChapter = (
    startTime: string,
    endTime: string,
    subtitle: Subtitle
  ) => {
    //Clear any existing intervals
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  const loopCountRef = useRef(0) // Ref for tracking loop count

  //Subtitle Tile Clicked: Seek to Tile location and repeat the number of times indicated in the loop dropdown
  const handleTileClick = (startTime, endTime, index) => {
    // Clear any existing intervals
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    console.log("Clicked on Sub and Tile indexes: ", index)
    const startSeconds = convertToSeconds(startTime)
    const endSeconds = convertToSeconds(endTime)

    // //Set to currently clicked sub index, This is INCORRECT
    // if (useTileSubsOrig) {
    //   setActiveSubtitleIndexOrig(index)
    //   activeSubtitleIndexOrigRef.current = index
    //   currentStartOrigRef.current = startSeconds;
    //   currentEndOrigRef.current = endSeconds;
    //   lastSubtitleIndexOrigRef.current = index;
    //   setActiveTilePosSubIndexOrig(index);
    //   console.log("\n\nTile clicked ORIG, setting to index: ", index)
    // } else {
    //   setActiveSubtitleIndex(index);
    //   activeSubtitleIndexRef.current = index //parseInt(subtitle.i, 10) - 1
    //   currentStartRef.current =startSeconds;
    //   currentEndRef.current = endSeconds;
    //   lastSubtitleIndexRef.current = index;
    //   setActiveTilePosSubIndex(index);
    //   console.log("\n\nTile clicked  , setActiveSubtitleIndex to: ", index)
    // }
    
    setPlaying(true)
    loopCountRef.current = 0 // Reset loop count
    playerRef.current?.seekTo(startSeconds, "seconds")
    //reset to where the user has clicked the index, if in the bookmarks tab
    if (tilesFilterValue == "bookmarked") {
      setIsPlayingBookmarks(true);
      const nextIndex = index < filteredSubtitles.length -1 ? index +1 : index;
      setCurrentBookmarkIndex(nextIndex)
    }

    const interval = setInterval(() => {
      if ((playerRef.current?.getCurrentTime() ?? 0) >= endSeconds) {
        loopCountRef.current += 1
        console.log(`Loop count: ${loopCountRef.current}`)

        if (loopCountRef.current >= loopCount) {
          clearInterval(interval)
          // setPlaying(false) // Pause playback after reaching loop count
          // setIsPlayingBookmarks(false);
          //Playback is only paused when loopCount is greater than 1
          if (loopCount > 1) {
            setPlaying(false); // Pause playback after reaching loop count
            setIsPlayingBookmarks(false);
          }
          intervalRef.current = null
        } else {
          playerRef.current?.seekTo(startSeconds, "seconds")
        }
      }
    }, 200)

    intervalRef.current = interval // Store the current interval
  }

  const handleLoopCountChange = (event) => {
    const loopCount = parseInt(event.detail.value, 10)
    if (!isNaN(loopCount)) {
      setLoopCount(loopCount)
      console.log("Setting Loop Count from Dropdown: " + loopCount)
    }
  }

//------Bookmarks playback ******

  const onVideoProgress = ({ playedSeconds }) => {
    setCurrentTime(playedSeconds);
    console.log("[onVideoProgress] currentBookmarkIndex: " + currentBookmarkIndex);
    if (bookmarksLoaded.length > 0){
      //check if beyond the previous tile's end time find the current index and play it since it has been incremented prior being called here
      if (isPlayingBookmarks && currentBookmarkIndex > 0 && playedSeconds >= convertToSeconds(filteredSubtitles[currentBookmarkIndex - 1].e)) {
        console.log('Playing next bookmark since over time of current bookmark, index previously incremented: ', currentBookmarkIndex);
        playNextBookmarkedSubtitle();
      } else if (isPlayingBookmarks && currentBookmarkIndex == 0 && playedSeconds >= convertToSeconds(filteredSubtitles[currentBookmarkIndex].e) && filteredSubtitles.length > 1) {
        setCurrentBookmarkIndex(currentBookmarkIndex + 1);
        console.log("FilteredSubtitles.length: " + filteredSubtitles.length);
        console.log('Incremented bookmark, playing nextone now:', currentBookmarkIndex);
        playNextBookmarkedSubtitle();
      }
    }
  };

  // const playNextBookmarkedSubtitle = () => {
  //   if (currentBookmarkIndex < filteredSubtitles.length) {
  //     const subtitle = filteredSubtitles[currentBookmarkIndex];
  //     playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
  //     setCurrentBookmarkIndex(currentBookmarkIndex + 1);
  //   } else {
  //     // Reset at the end of the list
  //     setIsPlayingBookmarks(false);
  //     setPlaying(false);
  //     console.log("PLAYER: Set to STOP, STOP NOW!!!!!!!");
  //     setCurrentBookmarkIndex(0);
  //   }
  // };
  
  //Plays next subtitle tile, also handles repeating the entire stack of bookmarked tiles
  const playNextBookmarkedSubtitle = () => {
    if (currentBookmarkIndex < filteredSubtitles.length) {
      console.log("Bookmarks...... currbookmark:", currentBookmarkIndex, filteredSubtitles.length)
      const subtitle = filteredSubtitles[currentBookmarkIndex];
      // if (subtitle.i > 1 && (subtitle.i - filteredSubtitles[currentBookmarkIndex-1].i) > 1) {
      // if (subtitle.i > 1 && currentBookmarkIndex > 0 && (subtitle.i - filteredSubtitles[currentBookmarkIndex-1].i) > 1) {
      //   playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
      // } else if(subtitle.i == 1) {
      //   playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
      // }
      if ( currentBookmarkIndex === 0 || (subtitle.i - filteredSubtitles[currentBookmarkIndex - 1].i) > 1 ) {
        playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
      }
      
      setCurrentBookmarkIndex(currentBookmarkIndex + 1);
    } else {
      if (filteredSubtitles.length > 0 && loopBookmarks) {
        // If looping is enabled, reset to the first bookmarked subtitle
        setCurrentBookmarkIndex(0);
        console.log("LoopingBookmarks...... currentBookmarkIndex = 0")
        const subtitle = filteredSubtitles[0];
        playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
      } else {
        // Reset at the end of the list without looping
        setIsPlayingBookmarks(false);
        setPlaying(false);
        setCurrentBookmarkIndex(0);
      }
    }
  };
  // const playNextBookmarkedSubtitle = () => {
  //   if (currentBookmarkIndex < filteredSubtitles.length) {
  //     const subtitle = filteredSubtitles[currentBookmarkIndex];
  //     playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
      
  //     // Increment the bookmark index or reset if at the end and looping is enabled
  //     const nextIndex = (currentBookmarkIndex + 1) % (loopBookmarks ? filteredSubtitles.length : currentBookmarkIndex + 1);
  //     setCurrentBookmarkIndex(nextIndex);
  
  //     // Ensure playback continues if looping
  //     if (loopBookmarks && nextIndex === 0) {
  //       // Explicitly continue playing if we're looping back to the first bookmark
  //       setPlaying(true);
  //     }
  //   } else {
  //     // This else block might be redundant with the updated logic but kept for clarity
  //     if (loopBookmarks) {
  //       // Explicitly handle the case where we might loop from here, though the updated logic should prevent reaching this
  //       setCurrentBookmarkIndex(0);
  //       const subtitle = filteredSubtitles[0];
  //       playerRef.current?.seekTo(convertToSeconds(subtitle.s), 'seconds');
  //       setPlaying(true); // Ensure we continue playing
  //     } else {
  //       // Stop playback if not looping
  //       setIsPlayingBookmarks(false);
  //       setPlaying(false);
  //       setCurrentBookmarkIndex(0);
  //     }
  //   }
  // };
  


  
  
  
  


  // Main Play Pause button
  // const handlePlayPause = () => {
  //   // Check if the video is currently playing and if an interval for looping exists
  //   if (playing && intervalRef.current) {
  //     // Clear the looping interval
  //     clearInterval(intervalRef.current)
  //     intervalRef.current = null

  //     // Reset any states if necessary, such as count if you use it to track loop iterations
  //     setCount(0) // Assuming 'count' is used to track the current loop iteration

  //     // Resume normal playback without looping, seek to the current time to avoid skipping to loop start
  //     const currentTime = playerRef.current?.getCurrentTime() ?? 0
  //     playerRef.current?.seekTo(currentTime, "seconds")
  //   }
  //   setPlaying(!playing)
  // }
  const handlePlayPause = () => {
    // Check if the app is in the mode to play only bookmarked subtitles
    if (playBookmarksOnly && tilesFilterValue === "bookmarked") {
      if (isPlayingBookmarks) {
        // If already playing bookmarks, stop the playback
        setIsPlayingBookmarks(false);
        setPlaying(false); // Also stop the video playback
        // Clear the looping interval <--this seems to be needed for bookmarks too
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      } else {
        // Start playing bookmarks from the current index
        playNextBookmarkedSubtitle();
        setIsPlayingBookmarks(true);
        setPlaying(true); // Start the video playback if it's not already playing
      }
    } else {
      // Standard play/pause for continuous video playback
      if (playing && intervalRef.current) {
        // Clear the looping interval
        clearInterval(intervalRef.current);
        intervalRef.current = null;
  
        // Reset any states if necessary
        setCount(0); // used to track the current loop iteration
      }
  
      // Toggling the playing state for standard playback
      setPlaying(!playing);
    }
  };
  

  


  const handleSetPlaybackRate = (rate) => {
    setPlaybackRate(rate)
  }

  //jump to prev or next tile, called when user clicks on prev or next player buttons which will call this if tiles are available
  const handleSkipToTile = (direction: 'back' | 'forward') => {
    // If user is displaying original subtitles in tiles, we use vttLoadedOrig; else vttLoaded.
    const currentIndex = useTileSubsOrig ? activeTilePosSubIndexOrig : activeTilePosSubIndex;

    // If there's no relevant set of filtered subtitles, do nothing.  
    // (You could also guard-check that filteredSubtitles.length > 0.)
    if (currentIndex < 0) return;

    let newIndex = direction === 'forward' ? currentIndex + 1 : currentIndex - 1;

    // Edge case: clamp index to valid range
    if (newIndex < 0) {
      newIndex = 0;
    }
    if (newIndex >= filteredSubtitles.length) {
      newIndex = filteredSubtitles.length - 1;
    }

    // If we didn't actually change positions (because we were already at the boundary),
    // you might choose to return early or show a small toast, etc.
    if (newIndex === currentIndex) {
      console.log('Reached first/last tile, cannot skip further.');
      return;
    }

    // Reuse your existing handleTileClick logic
    // to do the seeking & looping, passing the new tile's start/end, plus the new index:
    const chosenTile = filteredSubtitles[newIndex];
    handleTileClick(chosenTile.s, chosenTile.e, newIndex);

    // Update whichever activeTilePosSubIndex is being used
    if (useTileSubsOrig) {
      setActiveTilePosSubIndexOrig(newIndex);
    } else {
      setActiveTilePosSubIndex(newIndex);
    }
  };




  // const handleBackFive = () => {
  //   console.log("Clicked handleBackFive")
  //   if (playerRef.current?.getCurrentTime()) {
  //     const currentTime = Math.abs(playerRef.current?.getCurrentTime())
  //     console.log("Clicked handleBackFive: " + currentTime)
  //     playerRef.current?.seekTo(currentTime - 5)
  //   } else {
  //     playerRef.current?.seekTo(0)
  //   }
  // }

  // const handleForwardFive = () => {
  //   console.log("Clicked handleForwardFive")
  //   if (playerRef.current?.getCurrentTime()) {
  //     const currentTime = playerRef.current?.getCurrentTime()
  //     playerRef.current?.seekTo(currentTime + 5)
  //   } else {
  //     playerRef.current?.seekTo(5)
  //   }
  // }
  const handleBackFive = () => {
    // If either the target-language subtitles or the original-language subtitles are loaded
    if ((vttLoaded && vttLoaded.length > 0) || (vttLoadedOrig && vttLoadedOrig.length > 0)) {
      handleSkipToTile('back');
    } else {
      // Fallback to original behavior
      console.log("Clicked handleBackFive - skipping 5 seconds");
      if (playerRef.current?.getCurrentTime()) {
        const currentTime = Math.abs(playerRef.current.getCurrentTime());
        playerRef.current.seekTo(Math.max(currentTime - 5, 0));
      } else {
        playerRef.current?.seekTo(0);
      }
    }
  };
  
  const handleForwardFive = () => {
    // If either the target-language subtitles or the original-language subtitles are loaded
    if ((vttLoaded && vttLoaded.length > 0) || (vttLoadedOrig && vttLoadedOrig.length > 0)) {
      handleSkipToTile('forward');
    } else {
      // Fallback to original behavior
      console.log("Clicked handleForwardFive - skipping 5 seconds");
      if (playerRef.current?.getCurrentTime()) {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime + 5);
      } else {
        playerRef.current?.seekTo(5);
      }
    }
  };





  const toggleOverlay = (key) => {
    if (key === "subtitlesEnabled") {
      setSubtitlesEnabled((prev) => !prev)
      setSubtitlesOrigEnabled(false)
    } else if (key === "subtitlesOrigEnabled") {
      setSubtitlesOrigEnabled((prev) => !prev)
      setSubtitlesEnabled(false)
    }
  }
  //toggle overlay romanized text
  const toggleOverlayRom = () => {
    setIsDots((prevState) => !prevState);
  };



  const handleFavoriteClick = async () => {
    try {
      if (isFavorite) {
        await favoriteVideosService.removeFromFavorites(id)
        setIsFavorite(false)
      } else {
        //await favoriteVideosService.addToFavorites({id: { videoId }, video});
        await favoriteVideosService.addToFavorites(videoDetail)
        setIsFavorite(true)
      }

      // Updating the favorite status after user interaction
      //setIsFavorite(!isFavorite);
    } catch (error) {
      console.error(error)
    }
  }


  const handleBookmarkClick = async (subtitle, event) => {
    event.stopPropagation()

    // Determine the correct set of subtitles and index based on useTileSubsOrig
    const subtitles = useTileSubsOrig ? vttLoadedOrig : vttLoaded
    // Find the actual index against the full subtitles unfiltered list, user currently maybe  viewing a filtered bookmark list
    const subtitleIndex = subtitles.indexOf(subtitle)

    // Choose the appropriate language code
    const currentLanguageCode = useTileSubsOrig
      ? languageCodeOrig
      : languageCode

    try {
      const currBookmarks = await bookmarkTilesService.toggleBookmark(
        id,
        currentLanguageCode,
        subtitleIndex
      )
      setBookmarkedTiles(currBookmarks)
    } catch (error) {
      console.error("Error handling bookmark click:", error)
    }
  }

  //Sets the view for the tiles: all, bookmarked, or chapter tiles
  // const handleSegmentClick = async (segVal) => {
  //   setTilesFilterValue(segVal)
  //   setBookmarksLoaded(bookmarkedTiles)
  //   setSearchTerm("") //clear search term whenever user clicks around, this avoids confusion of having filtered tiles when they return back.
  // }
  const handleSegmentClick = async (segVal) => {
    // Check if the current segment is 'note' or the target segment is 'note'
    console.log("[handleSegmentClick]: ", segVal);
  // 1) Store the current (old) segment
  const oldVal = tilesFilterValue;
    setTilesFilterValue(segVal);
    
    setBookmarksLoaded(bookmarkedTiles);
    setSearchTerm(""); // Clear search term whenever user clicks around, this avoids confusion of having filtered tiles when they return back.
    // if (tilesFilterValue !== "note" && segVal !== "note") {
    //   // If switching between segments that are not 'note', stop playback
    //   // setPlaying(false);
    //   if (playing || isPlayingBookmarks){
    //     handlePlayPause();
    //   }
    // }

      // 4) If we switched away from 'note' -> 'someOtherSegment'
  //    or 'someSegment' -> 'anotherSegment' (and both are non-'note'),
  //    pause if playing or isPlayingBookmarks
  if (oldVal !== "note" && segVal !== "note") {
    if (playing || isPlayingBookmarks) {
      handlePlayPause();
    }
  }


  };
  

  //--------------------*** useEffects ***---------------------------/
  useEffect(() => {
    return () => {
      //Unmounting save activities ***
      const metaInfoKey = `meta_${id}`;

      storage.get(metaInfoKey)
        .then((storedMetaInfoString) => {
          let storedMetaInfo = { lastIndex:0, lastIndexOrig:0, furthestIndex: 0, furthestIndexOrig:0, initDate:'', lastDate:''};
  
          if (storedMetaInfoString) {
            try {
              storedMetaInfo = JSON.parse(storedMetaInfoString);
            } catch (e) {
              console.error("Error parsing stored meta info:", e);
            }
          } else {
            console.log("No existing meta info found; initializing new meta info.");
          }
  
          // Set initDate if it doesn't exist
          if (storedMetaInfo.initDate == '') {
            storedMetaInfo.initDate = new Date().toISOString();
          }
  
          // Update lastDate to current date
          storedMetaInfo.lastDate = new Date().toISOString();
  
          // Compare activeSubtitleIndexOrig with stored furthestIndex
          if (activeSubtitleIndexOrigRef.current > storedMetaInfo.furthestIndex) {
            storedMetaInfo.furthestIndexOrig = activeSubtitleIndexOrigRef.current;
            storedMetaInfo.furthestIndex = activeSubtitleIndexRef.current;
          }
          console.log("Last active indexes: "+ activeSubtitleIndexOrigRef.current,activeSubtitleIndexRef.current);

          // Update lastIndex
          if (activeSubtitleIndexOrigRef.current != -1) {
            storedMetaInfo.lastIndexOrig = activeSubtitleIndexOrigRef.current;
          }
          if (activeSubtitleIndexRef.current != -1) {
          storedMetaInfo.lastIndex = activeSubtitleIndexRef.current;
          }
          // Save updated metaInfo back to storage
          return storage.set(metaInfoKey, JSON.stringify(storedMetaInfo));
        })
        .then(() => {
          console.log("Meta info saved successfully");
        })
        .catch((error) => {
          console.error("Error during storage operations:", error);
        });
      console.log("************* Component is unmounting!  ***********")
    }
  }, [])

  useEffect(() => {
    return () => {
      console.log("ORIG language changed to: " + languageCodeOrig)
    }
  }, [languageCodeOrig])

  const vttLoadedOrigRef = useRef(vttLoadedOrig)
  useEffect(() => {
    vttLoadedOrigRef.current = vttLoadedOrig
  }, [vttLoadedOrig])

  // INITIALIZE Video Info: when video is first loaded to attempt to retreive subs locally, then from BE
  useEffect(() => {
    let intervalId // Moved declaration here

    // Clear the interval at the beginning of the effect
    if (intervalId) {
      console.log("Clearing previous interval:", intervalId)
      clearInterval(intervalId)
    }

    if (videoDetail === null) return; //EXIT if triggered by null value

    // This function will be called when the page first loads.
    const loadSubtitles = async () => {
      console.log("CHECKING local subs for: "+videoDetail)
      //Set language codes
      // Get video details here, pass them as arguments if needed
      const localSubtitles = await retrieveSubtitles(id, languageCode)
      console.log(localSubtitles);


     // Check for valid availableCountries
     if (
      videoDetail &&
      Array.isArray(videoDetail.availableCountries) &&
      (
        // !videoDetail.availableCountries.includes('US') ||
        !videoDetail.availableCountries.includes('SG')
      )
    ) {
      setProcessingMessage("(This video has not been made available)");
      console.log("Video not available in US or SG, stopping further actions: ", videoDetail.availableCountries );
      // Stop further processing, do not call backend or set intervals
      return;
    }

      // If there are no subtitles in local storage, retrieve them from the server
      if (!localSubtitles || localSubtitles.length === 0) {
        console.log("Zplayer useEffect videoDetail: ", videoDetail)
        if (videoDetail) {
          // Get video details here, pass them as arguments if needed

          //OLD API
          // const {
          //   contentDetails: { duration, caption },
          //   snippet: { defaultAudioLanguage, categoryId },
          // } = videoDetail

          const { categoryId, lengthSeconds } = videoDetail
          console.log("Zplayer useEffect lengthSeconds: ", lengthSeconds)
          if (lengthSeconds) {
            const defaultAudioLanguage = ""
            duration = secondsToYouTube8601(lengthSeconds)
            console.log("No local subs, checking server...")
            const langCodeOrig = defaultAudioLanguage
              ? defaultAudioLanguage
              : ""
            // Use the current language code and video details to retrieve the subtitles from the server
            retrieveSubtitlesBE(
              id,
              langCodeOrig,
              languageCode,
              duration,
              categoryId,
              videoDetail.channelId,
          videoDetail.channelTitle
            )
            setLanguageCodeOrig(defaultAudioLanguage)
          }

          //Get current video duration in seconds to determine if it can be processed
          const seconds = convertYouTubeDurationToSeconds(duration)
          setVideoLengthSeconds(seconds)
          //1hr = 3600s, 4hr = 14400, 5hr = 18000
          if (seconds > 3600 && seconds <= 7200) {
            setProcessingMessage(
              "(subs processing, pls exit, check back in 30 mins)"
            )
          }
          if (seconds > 7200) {
            setProcessingMessage(
              "(subs for this video is currently unavailable.)"
            )
          }
          if (seconds <= 3600) {
            setProcessingMessage("(subs processing, please wait a few minutes)")

            //Auto checking since user should in theory not have to wait that long
            let attemptCount = 0
            intervalId = setInterval(async () => {
              // Exit interval if vttLoadedOrig has a value
              if (
                vttLoadedOrigRef.current &&
                vttLoadedOrigRef.current.length > 0
              ) {
                console.log(
                  "vttLoadedOrig: has value, clearing intervalId",
                  intervalId,
                  vttLoadedOrigRef.current
                )
                clearInterval(intervalId)
                return
              }

              attemptCount++

              if (attemptCount >= 15) {
                clearInterval(intervalId)
                setProcessingMessage(
                  "(subtitle processing is taking longer than expected, please check back in a few hours)"
                )
                return
              }
              console.log(
                "Calling retrieveSubtitlesBE: ",
                intervalId,
                attemptCount
              )
              await retrieveSubtitlesBE(
                id,
                "",
                languageCode,
                duration,
                categoryId,
                videoDetail.channelId,
          videoDetail.channelTitle
              )
            }, 60000)
            console.log("Started new interval:", intervalId)

            // return () => {
            //   console.log("----- UNMOUNTING, CLEANING UP internvalId: ", intervalId);
            //   clearInterval(intervalId);
            // }
          }
          console.log("This video length is seconds: ", seconds)
        }
      }
    }

    // Call the function
    loadSubtitles()
    return () => {
      console.log("----- UNMOUNTING, CLEANING UP intervalId: ", intervalId)
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [videoDetail])



//TARGET TILE HIGHLIGHT select idx: Set video user selected active sub index so the correct button can be highlighted
// Continuously runs when the user is playing a video, interval checks will stop when video is paused.
// Note: activeSubtitleIndex and activeSubtitleIndexRef represent the subtitle.i index, the absolute index for that language's subtitle
//       activeTileSubIndex and activeTileSubIndexOrig are the relative index of the tile in the current list which changes based on filtering criteria
useEffect(() => {
  if (!vttLoaded.length) {
    // Potentially reset some state here if needed
    return;
  }
  let interval;
  const startInterval = () => {
    interval = setInterval(() => {
      // console.log("Player is playing: ", playingRef.current);
      if (!playerRef.current || !playingRef.current) return;
      const currentTime = playerRef.current.getCurrentTime();

      let currIndex = activeSubtitleIndexRef.current; //current sub index
      let nextIndex = currIndex < vttLoaded.length - 1 ? currIndex + 1 : currIndex; //next sub index
      let newIndex = -1;

      // Update current subtitle times if we have a new index from the last iteration
      if (currIndex !== lastSubtitleIndexRef.current) {
        if (currIndex < vttLoaded.length) {
          //Edge case where user has clicked on first tile in Original sub tiles which has different timings and this Target tile has no corresponding start time yet
          if (currIndex == -1) {
            currIndex = 0
          }
          //console.log("Updating current subtitle start end times for index: ", currIndex,lastSubtitleIndexRef.current, vttLoaded);
          currentStartRef.current = convertToSeconds(vttLoaded[currIndex].s);
          currentEndRef.current = convertToSeconds(vttLoaded[currIndex].e);
          lastSubtitleIndexRef.current = currIndex;
          // console.log("Updated lastSubtitleIndexRef to: ", currIndex);
        }
        if (nextIndex < vttLoaded.length) {
          //save the start time of the next sub, so the gap between the current and next one is captured
          nextStartRef.current = convertToSeconds(vttLoaded[nextIndex].s);
        }
      }

      // ***** SHORT CIRCUITS *********************************
      // Handle case before very first subtitle starts
      if (vttLoaded.length && currentTime < convertToSeconds(vttLoaded[0].s)) {
        if (activeSubtitleIndexRef.current !== -1) {
          //reset the state and ref vars activeSubtitleIndex, activeSubtitleIndexRef, so nothing is selected
          //console.log("BEFORE first tile, setting index to -1")
          setActiveSubtitleIndex(-1);
          activeSubtitleIndexRef.current = -1;
        }
        return; // Exit, as no subtitles are active yet
      }

      // Check if the current time is within the current subtitle or in the gap to the next
      if (currIndex !== -1 && currentStartRef.current !== null && currentEndRef.current !== null) {
        if (currentStartRef.current <= currentTime && currentTime < (nextStartRef.current || Infinity)) {
          //console.log("Current index or gap is active, do nothing.:", currIndex);
          return; // Exit the interval callback
        }
      }
      // ******************************************************

      // Check if the next subtitle is the active one
      if (nextIndex < vttLoaded.length && nextStartRef.current !== null) {
        const endNext = convertToSeconds(vttLoaded[nextIndex].e);

        if (nextStartRef.current <= currentTime && currentTime <= endNext) {
          newIndex = nextIndex;
          //console.log("Next index is valid, making it active:", newIndex);
        }
      }

      // Binary search as last resort
      if (newIndex === -1) {
        newIndex = binarySearchSubtitles(vttLoaded, currentTime);
        // console.log("You jumped! Binary Search found:", newIndex);
      }

      // Update state and ref
      if (newIndex !== -1 && newIndex !== activeSubtitleIndexRef.current) {
        console.log("TARGET >>>>> Setting ActiveSubtitleIndex to new FROM old: ", newIndex, activeSubtitleIndexRef.current);
        setActiveSubtitleIndex(newIndex);
        activeSubtitleIndexRef.current = newIndex;
        
        
        if (tilesFilterValue == "bookmarked"){
          console.log("TARGET>>>>> Setting ActiveTilePosSubIndex to Binary new FROM old: ", binarySearchById(filteredSubtitles,newIndex+1), activeTilePosSubIndex);
          //Add +1 because newIndex is the position of the zero based list but search function expecting Sub Id which is 1 based list
          setActiveTilePosSubIndex(binarySearchById(filteredSubtitles,newIndex+1));
        } else {
          console.log("TARGET>>>>> Setting ActiveTilePosSubIndex to new FROM old: ", newIndex, activeTilePosSubIndex);
          setActiveTilePosSubIndex(newIndex);
        }
      }
    }, 300);
  }

    // Start the interval only if playing, else clear the interval and wait for playing state change
    if (playing) {
      startInterval();
    } else if (interval) {
      clearInterval(interval);
    }

  return () => {
    if (interval){
      clearInterval(interval);
    }
  }
}, [vttLoaded, tilesFilterValue, bookmarksLoaded, playing, filteredSubtitles]);


  //ORIG TILE HIGHLIGHT orig idx: Set video active original language sub index so the correct text can be displayed
  useEffect(() => {
    if (!vttLoaded.length) {
      // Potentially reset some state here if needed
      return;
    }
    let interval;
    const startInterval = () => {
      interval = setInterval(() => {
        // console.log("Player is playing: ", playingRef.current);
        if (!playerRef.current || !playingRef.current) return;
        const currentTime = playerRef.current.getCurrentTime();
  
        let currIndex = activeSubtitleIndexOrigRef.current; //current sub index
        let nextIndex = currIndex < vttLoadedOrig.length - 1 ? currIndex + 1 : currIndex; //next sub index
        let newIndex = -1;
  
        // Update subtitle times if we have a new index
        if (currIndex !== lastSubtitleIndexOrigRef.current) {
          if (currIndex < vttLoadedOrig.length) {
            //Edge case where user has clicked on first tile in Target sub tiles which has different timings and this Original tile has no corresponding start time yet
            if (currIndex == -1) {
              currIndex = 0
            }
            //console.log("Updating current subtitle ORIG start end times for index: ", currIndex, vttLoadedOrig[currIndex]);
            currentStartOrigRef.current = convertToSeconds(vttLoadedOrig[currIndex].s);
            currentEndOrigRef.current = convertToSeconds(vttLoadedOrig[currIndex].e);
            lastSubtitleIndexOrigRef.current = currIndex;
            // console.log("Orig Updated lastSubtitleIndexOrigRef to: ", currIndex);
          }
          if (nextIndex < vttLoadedOrig.length) {
            nextStartOrigRef.current = convertToSeconds(vttLoadedOrig[nextIndex].s);
          }
        }
  
        // Check if the current time is within the current subtitle or in the gap to the next
        if (currIndex !== -1 && currentStartOrigRef.current !== null && currentEndOrigRef.current !== null) {
          if (currentStartOrigRef.current <= currentTime && currentTime < (nextStartOrigRef.current || Infinity)) {
            //console.log("Current index or gap is active, do nothing.:", currIndex);
            return; // Exit the interval callback
          }
        }
  
        // Check if the next subtitle is the active one
        if (nextIndex < vttLoadedOrig.length && nextStartOrigRef.current !== null) {
          const endNext = convertToSeconds(vttLoadedOrig[nextIndex].e);
  
          if (nextStartOrigRef.current <= currentTime && currentTime <= endNext) {
            newIndex = nextIndex;
            // console.log("Orig Next index is valid, making it active:", newIndex);
          }
        }
  
        // Binary search as last resort
        if (newIndex === -1) {
          newIndex = binarySearchSubtitles(vttLoadedOrig, currentTime);
          // console.log("Orig You jumped! Orig Binary Search found:", newIndex);
        }
  
        // Update state and ref
        if (newIndex !== -1 && newIndex !== activeSubtitleIndexOrigRef.current) {
          // console.log("Orig >>>>> Setting ActiveSubtitleIndexOrig to new from old: ", newIndex, activeSubtitleIndexOrigRef.current);
          setActiveSubtitleIndexOrig(newIndex);
          activeSubtitleIndexOrigRef.current = newIndex;
          
          if (tilesFilterValue == "bookmarked"){
            // console.log("Orig >>>>> Setting ActiveTilePosSubIndexOrig to Binary new from old: ", binarySearchById(filteredSubtitles,newIndex+1), activeTilePosSubIndexOrig);
            //Add +1 because newIndex is the position of the zero based list but search function expecting Sub Id which is 1 based list
            setActiveTilePosSubIndexOrig(binarySearchById(filteredSubtitles,newIndex+1));
          } else {
            // console.log("Orig >>>>> Setting ActiveTilePosSubIndexOrig to new from old: ", newIndex, activeTilePosSubIndexOrig);
            setActiveTilePosSubIndexOrig(newIndex);
          }
        }
      }, 300);
    }
  
      // Start the interval only if playing, else clear the interval and wait for playing state change
      if (playing) {
        startInterval();
      } else if (interval) {
        clearInterval(interval);
      }
  
    return () => {
      if (interval){
        clearInterval(interval);
      }
    }
  }, [vttLoadedOrig, tilesFilterValue, bookmarksLoaded, playing, filteredSubtitles]);



  // Auto scroll sub tiles
  useEffect(() => {
    // const currentRef = buttonRefs.current[activeSubtitleIndex]
    if (tilesFilterValue == "all"  || tilesFilterValue == "bookmarked" ) {
      const currentRef = useTileSubsOrig
        ? buttonRefs.current[activeTilePosSubIndexOrig]
        : buttonRefs.current[activeTilePosSubIndex]

        // console.log("SCROLL >>>>> :")
        // console.log("CURRENT: " + activeTilePosSubIndexOrig, currentRef)
        // console.log("LAST: "+ lastSubtitleIndexOrigRef.current );

      
      // if (activeSubtitleIndex >= 0 && currentRef) {
        // if (lastSubtitleIndexOrigRef.current >= 0 && currentRef) {
      if (currentRef) {
        currentRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [activeTilePosSubIndex,activeTilePosSubIndexOrig])

  //Set video active chapter index so the correct tile can be highlighted
  useEffect(() => {
    let interval;
    const startInterval = () => {
      interval = setInterval(() => {
        if (!playerRef.current) return
        const currentTime = playerRef.current.getCurrentTime()
        const newChapterIndex = chaptersLoaded.findIndex(
          (subtitle) =>
            convertToSeconds(subtitle.s) <= currentTime &&
            currentTime <= convertToSeconds(subtitle.e)
        )

        if (newChapterIndex !== activeChapterIndex) {
          setActiveChapterIndex(newChapterIndex)
        }
      }, 1000);
    }
    // Start the interval only if playing, else clear the interval and wait for playing state change
    if (playing) {
      startInterval();
    } else if (interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval){
        clearInterval(interval);
      }
    }

  }, [activeChapterIndex, chaptersLoaded, playing])

  // Auto scroll chapter tiles
  useEffect(() => {
    if (tilesFilterValue == "chapters") {
      const currentRef = buttonRefs.current[activeChapterIndex]
      if (activeChapterIndex >= 0 && currentRef) {
        currentRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [activeChapterIndex])

  // ------- ******* ON PAGE LOAD API: Get video details from api TODO: change to AWS API gateway ******-----------
  // useEffect(() => {
  //   apiYT(`videos?part=snippet,statistics&id=${id}`)
  //     .then((data) => setVideoDetail(data.items[0]))
  // }, [id]);
  useEffect(() => {
    console.log("Zplayer useEffect API Call for videoDetails....... : " + id)
    //OLD apiYT(id).then((data) => setVideoDetail(data.items[0]))
    apiYT(id).then((data) => processVideoDetails(data))
  }, [id])

  //OnLoad: Update preferred translation language, otherwise defaults to en
  // useEffect(() => {
  //   const loadSelectedLanguageCode = async () => {
  //     console.log("-------> ========>> Original Language of video is: " + languageCodeOrig)

  //     const savedLanguageCode = await storage.get("selectedLanguageCode"); //get target language selected by user
  //     const savedNativeLanguageCode = await storage.get("nativeLanguageCode"); //get native language of user 

  //     //set state var languageCode based on the type of video being shown. 
  //     if (savedLanguageCode) {
  //       setLanguageCode(savedLanguageCode)
  //     } else {
  //       setLanguageCode("en")
  //     }
  //   }
  //   loadSelectedLanguageCode()
  // }, [])
  useEffect(() => {
    const loadSelectedLanguageCode = async () => {
      console.log("-------> ========>> Original Language of video is: " + languageCodeOrig);
  
      let savedLanguageCode = await storage.get("selectedLanguageCode"); // Get target language selected by user
      let savedNativeLanguageCode = await storage.get("nativeLanguageCode"); // Get native language of user
  
      // Default to 'en' if either language code is not found
      savedLanguageCode = savedLanguageCode || "en";
      savedNativeLanguageCode = savedNativeLanguageCode || "en";
  
      // Set state var languageCode based on the type of video being shown
      if (languageCodeOrig === savedNativeLanguageCode) {
        setLanguageCode(savedLanguageCode);
      } else {
        setLanguageCode(savedNativeLanguageCode);
      }
    };
    loadSelectedLanguageCode();
  }, [languageCodeOrig])

  //Favorites
  useEffect(() => {
    // Checking if this video is a favorite on component load
    ;(async () => {
      const favorites = await favoriteVideosService.getFavorites()
      setIsFavorite(!!favorites.find((video) => video.id === id))
      //console.log("[UPDATING FAV] "+ title)
    })()
  }, [id])

  useEffect(() => {
    // Only add to history if the video is currently playing
    if (playing) {
      //Increment video counter if video firs ttime load
      if (firstLoad) {
        console.log("Adding to History! firstLoad:", firstLoad, videoDetail)
        historyVideosService.addToHistory(videoDetail) // Assuming 'video' is the current video object
        userActivityService.incrementTodayView()
      }
      setFirstLoad(false)
    }
  }, [playing]) // This effect runs whenever 'playing' changes

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // Call fetchSubtitlesList when the modal is opened or a new subtitle is created
  useEffect(() => {
    if (showModal) {
      fetchSubtitlesList()
    }
  }, [showModal])

  //load custom subtitles, should be for reload only, when user modifies custom subs list???
  useEffect(() => {
    const loadCustomSubtitles = async () => {
      const videoSubtitlesKey = `c_subtitles_${id}`
      console.log(`Loading custom subtitles for key: ${videoSubtitlesKey}`)
      const existingSubtitlesString = await storage.get(videoSubtitlesKey)
      if (existingSubtitlesString) {
        const existingSubtitles = JSON.parse(existingSubtitlesString)
        const customSubtitleNames = Object.keys(existingSubtitles)
        console.log(`^^^^^^^^^^Custom subtitles found: ${customSubtitleNames}`)
        // setSubtitleLanguages(prevLanguages => Array.from(new Set([...prevLanguages, ...customSubtitleNames])));
        setSubtitleLanguages([...subtitleLanguagesGen, ...customSubtitleNames])
      } else {
        console.log("^^^^^^^^^^^No custom subtitles found.")
      }
    }

    if (id) {
      loadCustomSubtitles()
    } else {
      console.log("^^^^^^^No video id found for loading custom subtitles.")
    }
  }, [custSubtitlesList])

  /* SLATE-REACT RTF notes implementation */

  // useEffect(() => {
  //   const loadNote = async () => {
  //     try {
  //       const noteKey = `note_${id}`; // Assuming 'id' is the videoId
  //       const savedNote = await storage.get(noteKey);
  //       if (savedNote) {
  //         setNoteContent(savedNote);
  //         // Slate React: setValue(JSON.parse(savedNote));
  //       }
  //     } catch (error) {
  //       console.error("Failed to load note:", error);
  //     }
  //   };

  //   loadNote();
  // }, [id]); // Dependency array includes 'id' to reload the note if the videoId changes
  useEffect(() => {
    const loadNote = async () => {
      try {
        const noteKey = `note_${id}`
        const savedNoteObject = await storage.get(noteKey)
        if (savedNoteObject && savedNoteObject.text) {
          setNoteContent(savedNoteObject.text)
          // For Slate React: setValue(JSON.parse(savedNoteObject.text));
        } else {
          setNoteContent("") // Clear note content if nothing is saved
        }
      } catch (error) {
        console.error("Failed to load note:", error)
      }
    }

    loadNote()
  }, [id]) // Dependency array

  // -------------------Keyboard shortcuts -------------------
useEffect(() => {
  function handleKeyDown(e: KeyboardEvent) {
        // Check if the event’s target (or its shadow path) is an input or editable field
        const path = e.composedPath() as HTMLElement[];
        const isEditing = path.some(
          (el) =>
            el instanceof HTMLInputElement ||
            el instanceof HTMLTextAreaElement ||
            el.getAttribute?.("contenteditable") === "true"
        );
        // If user is editing a field, don’t override arrow keys
        if (isEditing) {
          return;
        }

    //Keyboard shortcuts ----------------
    if ((e.metaKey || e.ctrlKey)  && e.shiftKey && e.key.toLowerCase() === "s") {
      e.preventDefault();
      handleSegmentClick("all"); 
    }
    else if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === "s") {
      e.preventDefault();
      handleSaveNote();
    }
    // Space bar => play/pause
    else if (e.code === "Space") {
      e.preventDefault(); 
      handlePlayPause();
    }
    // Right arrow => forward 5 seconds
    else if (e.key === "ArrowRight") {
      e.preventDefault(); 
      handleForwardFive();
    }
    // Left arrow => back 5 seconds
    else if (e.key === "ArrowLeft") {
      e.preventDefault(); 
      handleBackFive();
    }
    else if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.key.toLowerCase() === "b") {
      e.preventDefault();
      handleSegmentClick("bookmarked"); 
    }
    else if ((e.metaKey || e.ctrlKey)  && e.shiftKey && e.key.toLowerCase() === "c") {
      e.preventDefault();
      handleSegmentClick("chapters");
    }
    else if ((e.metaKey || e.ctrlKey)  && e.shiftKey && e.key.toLowerCase() === "k") {
      e.preventDefault();
      handleSegmentClick("note");
    }
  }

  window.addEventListener("keydown", handleKeyDown);
  return () => {
    window.removeEventListener("keydown", handleKeyDown);
  };
}, [
  // If desired, list your handlers for clarity
  handleSaveNote,
  handlePlayPause,
  handleForwardFive,
  handleBackFive,
  // handleSegmentClick,
]);
// ------------------- END keyboard shortcuts -------------------



  const [subtitles, setSubtitles] = useState<Subtitle[]>([])

  if (!videoDetail) {
    const rowStyle = {
      display: "flex", // Use flex layout for rows
      justifyContent: "space-around", // Distribute thumbnails evenly
    }

    const thumbnailStyle = {
      width: "20vw", // Each thumbnail takes up approximately one-third of the viewport width
      height: "20vw", // Making the height equal to the width for square thumbnails
    }

    return (
      <IonList>
        {[...Array(3)].map((_, rowIndex) => (
          <IonItem key={rowIndex} style={rowStyle}>
            {[...Array(3)].map((_, colIndex) => (
              <IonThumbnail key={colIndex} style={thumbnailStyle}>
                <IonSkeletonText
                  animated={true}
                  style={{ height: "100%", width: "100%" }}
                />
              </IonThumbnail>
            ))}
          </IonItem>
        ))}
      </IonList>
    )
  }

  const customPopoverOptions = {
    header: "Language",
    subHeader: "Select target language",
    message: "This is the language you want to translate to",
  }

  const customPopoverOptionsLoop = {
    header: "Loop",
    subHeader: "How many loops?",
    message: "Select the number of times a tile should loop",
  }

  //check if an object is empty
  const isObjectEmpty = (obj) => {
    //console.log("Zplayer Examining obj: ", obj);
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  //handle switching language displayed in tiles from target language (original behavior) to video's original language
  const handleSwapSubtitles = async () => {
    //Since user is switching from the curent language, we need to get the opposite of what it currently is, followed by toggling useTileSubsOrig state varible afterwards
    const bookmarks = await bookmarkTilesService.getBookmarks(
      id,
      useTileSubsOrig ? languageCode : languageCodeOrig
    )
    setBookmarkedTiles(bookmarks) //load in the appropriate copy of the bookmarks to display bookmark state
    setBookmarksLoaded(bookmarks)

    setUseTileSubsOrig((prevState) => !prevState) //toggle to indicate if tiles have been swapped to orig from selected, default is false (selected)
  }

  //OLD API
  // const {
  //   contentDetails: { caption, duration },
  //   snippet: {
  //     title,
  //     channelId,
  //     channelTitle,
  //     defaultAudioLanguage,
  //     categoryId,
  //   },
  //   statistics: { viewCount, likeCount },
  // } = videoDetail

  //NEW API3
  const {
    lengthSeconds,
    title,
    channelId,
    channelTitle,
    categoryId,
    viewCount,
  } = videoDetail

  //const duration = secondsToYouTube8601(lengthSeconds)
  //console.log(" VIDEO DETAIL lengthSeconds: ", lengthSeconds);
  //console.log(videoDetail)
  
  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        position="middle"
      />

      {/* Modal: Infocard about video  */}
      <IonModal
          isOpen={showMediaCard}
          onDidDismiss={() => setShowMediaCard(false)}
          
        >
            <IonCard className="shareModal">
              <div className="shareModal__thumbnail">
                {/* <img
                  src={
                    !isObjectEmpty(videoDetail)
                      ? videoDetail["thumbnail"][4]
                        ? videoDetail["thumbnail"][4]["url"]
                        : videoDetail["thumbnail"][0]["url"]
                      : "/assets/generic.png"
                  }
                  alt="Video Thumbnail"
                  className="shareModal__thumbnail__image"
                /> */}
                <img
                  src={
                    !isObjectEmpty(videoDetail) && Array.isArray(videoDetail["thumbnail"]) 
                      ? (videoDetail["thumbnail"][4]?.url || videoDetail["thumbnail"][0]?.url)
                      : "/assets/generic.png"
                  }
                  alt="Video Thumbnail"
                  className="shareModal__thumbnail__image"
                />
              </div>

              <IonCardHeader>
                <IonRouterLink routerLink={`/channel/${videoDetail["channelId"]}`}>
                  <IonCardSubtitle color="warning">
                    Channel: {videoDetail["channelTitle"]}
                  </IonCardSubtitle>
                </IonRouterLink>
                <IonCardTitle>{videoDetail["title"]}</IonCardTitle>
                <IonCardSubtitle>
                  Duration: {formatTimeRP(videoDetail["lengthSeconds"])}
                </IonCardSubtitle>
                <IonCardSubtitle>
                  Views: {Number(videoDetail["viewCount"]).toLocaleString()}
                </IonCardSubtitle>
                <IonCardSubtitle>
                  Published:{" "}
                  {new Date(videoDetail["publishDate"]).toISOString().split("T")[0]}
                </IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent className="shareModal__videoDetail">
                <p>{videoDetail["description"]}</p>
              </IonCardContent>

              <div className="shareModal__buttonGroup">
                <IonButton
                  size="small"
                  fill="outline"
                  onClick={() => setShowMediaCard(false)}
                >
                  Close
                </IonButton>
              </div>
            </IonCard>
      </IonModal>

      {/* Modal: Workbench to manage custom subs and learning activity*/}
      <IonModal
        id="createSub-modal"
        isOpen={showModal}
        style={{     // Force height to 33% (33vh) for both iOS and MD modes
          "--height": "45vh",
          "--min-height": "45vh",
          "--max-height": "45vh",
      
          // Also override material design (MD) if needed:
          "--md-height": "45vh",
          "--md-min-height": "45vh",
          "--md-max-height": "45vh"
        }}
        onDidDismiss={() => setShowModal(false)}
      >
        <IonHeader>
          <IonToolbar style={{ position: 'relative', textAlign: 'center' }}>
            
          <IonButtons slot="start">
            <IonButton
            color="primary"
              size="small"
              fill="outline"
              onClick={() => setShowModal(false)}
              style={{ position: 'absolute', left: '5px' }} 
            >
              Close
            </IonButton>
          </IonButtons>
            <IonTitle >Workbench</IonTitle>
        
          </IonToolbar>
        </IonHeader>

        <IonContent style={{border: '2px solid rgb(140,141,220)'}} className="createSub-container">
          {/* IonSegment for switching between Custom Subs and Activities */}
          <IonSegment 
            value={workbenchSegment} 
            onIonChange={(e) => setWorkbenchSegment(e.detail.value!)}
            style={{ margin: "5px" }}
          >
            <IonSegmentButton value="activities">
              <IonLabel>Activities</IonLabel>
            </IonSegmentButton>

            <IonSegmentButton value="customSubs">
              <IonLabel>Custom Subs</IonLabel>
            </IonSegmentButton>

            <IonSegmentButton value="misc">
              <IonLabel>Misc</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {/* START of second segment: Manage Subs */}
          {workbenchSegment === "customSubs" && (
      <>
          <IonLabel style={{display:'block', padding:'10px'}}>New Subtitle</IonLabel>

          <IonItem lines="none" >
          <IonInput
            className="createSub-container__input"
            value={custName}
            placeholder="Enter name"
            onIonChange={(e) => setCustName(e.detail.value || "")}
          />
          </IonItem>

          {/* <IonRadioGroup
            value={subtitleType}
            onIonChange={(e) => setSubtitleType(e.detail.value)}
          >
            <IonItem style={{padding:'10px'}}>
              <IonLabel>Copy</IonLabel>
              <IonRadio value="copy" />
            </IonItem>
            <IonItem style={{padding:'10px'}}>
              <IonLabel>Blank</IonLabel>
              <IonRadio value="blank" />
            </IonItem>
          </IonRadioGroup> */}

          <IonRadioGroup
            value={subtitleType}
            onIonChange={(e) => setSubtitleType(e.detail.value)}
          >
            <IonItem lines="none" style={{ justifyContent: 'center', padding: '10px' }}>
              <div style={{ display: 'flex', gap: '15px' }}>
                <IonItem lines="none" style={{ padding: '0', margin: '0' }}>
                  <IonLabel style={{ marginRight: '10px' }}>Blank</IonLabel>
                  <IonRadio style={{"--color-checked": "orange",}} value="blank" />
                </IonItem>
                <IonItem lines="none" style={{ padding: '0', margin: '0' }}>
                  <IonLabel style={{ marginRight: '10px' }}>Copy</IonLabel>
                  <IonRadio style={{"--color-checked": "orange",}}  value="copy" />
                </IonItem>
              </div>
            </IonItem>
          </IonRadioGroup>


          <div className="createSub-container__buttonGroup">
            <IonButton
              size="small"
              color="medium"
              onClick={() => setShowModal(false)}
            >
              Close
            </IonButton>
            <IonButton size="small" onClick={handleCreateSubtitles}>
              Create
            </IonButton>
          </div>

          
          <IonList>
            {custSubtitlesList.map((subtitleName) => (
              <IonItem key={subtitleName}>
                {subtitleName}
                <IonIcon
                  icon={trashOutline}
                  slot="end"
                  onClick={() => {
                    setSubtitleToDelete(subtitleName)
                    setShowDeleteAlert(true)
                  }}
                />
              </IonItem>
            ))}
          </IonList>

          <IonAlert
            isOpen={showDeleteAlert}
            onDidDismiss={() => setShowDeleteAlert(false)}
            header={"Confirm Delete"}
            message={`Are you sure you want to delete the subtitle '${subtitleToDelete}'?`}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  setShowDeleteAlert(false)
                },
              },
              {
                text: "Delete",
                handler: () => {
                  handleDeleteSubtitle()
                },
              },
            ]}
          />
          </>
    )}
    {/*  END second Segment: Manage Subs */}

    {/* START of first segment to manage Activities */}
    {workbenchSegment === "activities" && (
      <>
        <IonList>
            <IonListHeader style={{ marginTop: "-0.5rem" }}>
              <IonLabel>Checklist for learning this content. Always choose content at your <u>level</u> and that interests you to maintain motivation.</IonLabel>
            </IonListHeader>
          {activityTitles.map((title, idx) => (
            <IonItem style={{ overflow: "visible" }} key={title}>
              <IonCheckbox slot="start" checked={checkedStates[idx]} onIonChange={(e) => {
                  if (isActLoading) return;
                  if (checkedStates[idx] !== e.detail.checked) {
                    handleCheckChange(idx, e.detail.checked);
                  }
                }} />
              <IonThumbnail slot="start">
                <img src="/assets/icon/spacesubs-120x120.png" alt="placeholder" />
              </IonThumbnail>
              <IonLabel style={{ whiteSpace: "normal" }}>
                {/* main title */}
                <h2 style={{ margin: 0, textDecoration: checkedStates[idx] ? "line-through" : "none"}}>
                  { "Day " +(idx+1) + ": " +title}
                </h2>
                {/* subtitle */}
                <p style={{ color: 'rgb(191,188,91)' }}>{activitySubtitles[idx]}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>

        <div className="createSub-container__buttonGroup">
            <IonButton
              size="small"
              color="medium"
              onClick={() => setShowModal(false)}
            >
              Close
            </IonButton>
          </div>
      </>
    )}


    {/* START of third segment */}
    {workbenchSegment === "misc" && (
      <div style={{ padding:'10px', justifyContent: 'center' }}>
        <IonLabel >
          Reload all the subtitles again from the server. Use this if your subtitles have issues such as broken or malformed text.
        </IonLabel>
        <IonButton
          style={{
            margin: "8px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          size="small"
          color="warning"
          onClick={handleRefreshSubtitles}
        >
          Delete & Reload all subtitles
          <IonIcon icon={refreshOutline} slot="start" />
        </IonButton>
        <div className="createSub-container__buttonGroup">
          <IonButton
            size="small"
            color="medium"
            onClick={() => setShowModal(false)}
          >
            Close
          </IonButton>
        </div>
      </div>

        
)}
        </IonContent>
      </IonModal>



      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton default-href="/home/new" />
          </IonButtons>

          {/*<IonSegment value={tilesFilterValue} onIonChange={e => setTilesFilterValue(e.detail.value!)}> */}
          <IonSegment
            value={tilesFilterValue}
            onIonChange={(e) => handleSegmentClick(e.detail.value!)}
            style={{ marginRight: "0", paddingRight: "0" }}
          >
            <IonSegmentButton value="all">
              <IonLabel>Subs</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="bookmarked">
              <IonLabel>Bookmarked</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="chapters">
              <IonLabel>Chapters</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="note">
              {/*<IonIcon size="small" color="dark" icon={documentTextOutline} /> */}
              <IonLabel>Kx</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <IonItem slot="end" style={{ background: "transparent" }}>
            <HeartComponent solid={isFavorite} onClick={handleFavoriteClick} />
          </IonItem>
        </IonToolbar>
      </IonHeader>

      <div className="zplayer">
        <div className="zplayer__subLayout">
          <div className="zplayer__subLeftLayout">
            {/* == 01: Player == */}
            <div className="zplayer__player">
              {/* player seek slider */}
              <div className="sliderContainer">
                <div className="timeElapsed">{formatTimeRP(currentTime)}</div>
                <input
                  className="slider"
                  type="range"
                  min={0}
                  max={durationRP}
                  value={currentTime}
                  step="any"
                  onChange={(e) => {
                    const time = parseFloat(e.target.value)
                    setCurrentTime(time)
                    playerRef.current?.seekTo(time)

                    const tooltip = document.querySelector(
                      ".timeTooltip"
                    ) as HTMLElement

                    if (tooltip) {
                      const percent = ((time - 0) / (durationRP - 0)) * 100
                      tooltip.style.left = percent + "%"
                      tooltip.textContent = formatTimeRP(time)
                      tooltip.style.display = "block"
                    }
                  }}
                  onMouseUp={() => {
                    const tooltip = document.querySelector(
                      ".timeTooltip"
                    ) as HTMLElement
                    if (tooltip) {
                      tooltip.style.display = "none"
                    }
                  }}
                  onTouchEnd={() => {
                    const tooltip = document.querySelector(
                      ".timeTooltip"
                    ) as HTMLElement
                    if (tooltip) {
                      tooltip.style.display = "none"
                    }
                  }}
                />
                <div className="timeTooltip">
                  {/* Content will be updated via JS */}
                </div>

                <div className="timeRemaining">
                  {/*formatTimeRP(durationRP - currentTime)*/}
                  {formatTimeRP(durationRP)}
                </div>
              </div>

              {/* main subs overlay over video */}
              {((subtitlesEnabled && activeSubtitleIndex >= 0) ||
                (subtitlesOrigEnabled && activeSubtitleIndexOrig >= 0)) && (
                <div
                  style={{
                    ...getFontSizeAndLineHeight(),
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%", // Make it span about 66% the size of the video
                    textAlign: "center", // Center the text horizontally
                    backgroundColor: "rgba(0, 0, 0, 0.26)",
                    padding: "10px", // Increase padding to make the text bigger
                    borderRadius: "10px", // Increase border radius to match the bigger text
                    color: "rgb(254,212,71)",
                    whiteSpace: "pre-wrap", // Wrap the text if it exceeds the maxWidth
                    textShadow: "1px 1px 3px rgba(0, 0, 0, 1)", // Add shadow to the text
                    fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                    fontWeight: 700,
                  }}
                >
                  {subtitlesEnabled &&
                    vttLoaded[
                      tilesFilterValue === "bookmarked"
                        ? activeSubtitleIndex //bookmarksLoaded[activeSubtitleIndex]
                        : activeSubtitleIndex
                    ]?.t}
                  {subtitlesOrigEnabled &&
                    vttLoadedOrig[
                      tilesFilterValue === "bookmarked"
                        ? activeSubtitleIndexOrig //bookmarksLoaded[activeSubtitleIndexOrig]
                        : activeSubtitleIndexOrig
                    ]?.t}
                  <div>
                    {/* vttLoadedOrigRom && vttLoadedOrigRom[activeSubtitleIndexOrig].t */}
                  </div>
                </div>
              )}

              {/* separate overlay for vttLoadedOrigRom (romanization) at the bottom of the screen */}
              {subtitlesOrigEnabled &&
                activeSubtitleIndexOrig >= 0 &&
                vttLoadedOrigRom.length > 0 && (
                  <div
                    onClick={toggleOverlayRom}
                    style={{
                      position: "absolute",
                      top: "5%",
                      left: "50%",
                      transform: "translate(-50%, 0)",
                      width: "90%",
                      fontSize: isTabletOrDesktop ? "1.3rem" : "1rem", // Setting font size to 2rem
                      textAlign: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.13)",
                      padding: "10px",
                      borderRadius: "10px",
                      color: "rgb(224,200,96)",
                      whiteSpace: "pre-wrap",
                      textShadow: "1px 1px 3px rgba(0, 0, 0, 1)",
                      fontFamily: "Noto Sans KR, Noto Sans,sans-serif",
                      fontWeight: 400,
                      cursor: "pointer", 
                    }}
                  >
                    {
                      isDots ? ". . . . ." :
                      vttLoadedOrigRom[
                        tilesFilterValue == "bookmarked"
                          ? bookmarksLoaded[activeSubtitleIndexOrig]
                          : activeSubtitleIndexOrig
                      ]?.t
                    }
                  </div>
                )}

              {id && (
                <div className="zplayer__player__ytPlayer">
                  <ReactPlayer
                    ref={playerRef}
                    url={`https://www.youtube.com/watch?v=${id}`}
                    playing={playing}
                    onPlay={() => setPlaying(true)}
                    playbackRate={playbackRate}
                    width="100%"
                    height="100%"
                    config={{
                      youtube: {
                        playerVars: { enablejsapi: 1, controls: 1 },
                      },
                    }}
                    // onProgress={({ playedSeconds }) =>
                    //   setCurrentTime(playedSeconds)
                    // }
                    onProgress={onVideoProgress}
                    onDuration={(d) => setDurationRP(d)}
                    controls={false}
                    playsinline
                  />
                  <IonIcon
                    icon={informationCircleOutline}
                    onClick={openMediaCard}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      fontSize: "25px",
                      color: "gold",
                      cursor: "pointer",
                      zIndex: 1000, // Ensure it's above the video player layer
                    }}
                  />
                </div>
              )}
            </div>

            {/* == 02: PlayerSubs == */}
            <div className="zplayer__playerSubs">
              <PlayerSubs
                languageMap={languageMap}
                languageCode={languageCode}
                languageCodeOrig={languageCodeOrig}
                vttLoaded={vttLoaded}
                vttLoadedOrig={vttLoadedOrig}
                // activeSubtitleIndex={
                //   tilesFilterValue == "bookmarked"
                //     ? bookmarksLoaded[activeSubtitleIndex]
                //     : activeSubtitleIndex
                // }
                // activeSubtitleIndexOrig={
                //   tilesFilterValue == "bookmarked"
                //     ? bookmarksLoaded[activeSubtitleIndexOrig]
                //     : activeSubtitleIndexOrig
                // }
                activeSubtitleIndex={activeSubtitleIndex}
                activeSubtitleIndexOrig={activeSubtitleIndexOrig}
                processingMsg={processingMessage}
                addWordToRecents={addWordToRecents}
                playing={playing}
                setPlaying={setPlaying}
              />
            </div>

            {/* == 03: Loop & target language select == */}
            <div className="zplayer__loop">
              <IonRow className="zplayer__loop__row">
                {/* Gear Icon Button */}
                <IonCol className="buttons-col">
                  <IonButton
                    class="no-margin-padding"
                    fill="clear"
                    onClick={() => setShowModal(true)}
                  >
                    <IonIcon color="dark" icon={constructOutline} />
                  </IonButton>

                  <IonButton
                    class="no-margin-padding"
                    fill="clear"
                    onClick={handleSwapSubtitles}
                  >
                    <IonIcon
                      color={useTileSubsOrig ? "primary" : "warning"}
                      icon={gridOutline}
                    />
                  </IonButton>
                </IonCol>

                {/* Swap tiles from selected to orig Button */}
                {/* <IonCol>
                  <IonButton
                    class="no-margin-padding"
                    fill="clear"
                    onClick={handleSwapSubtitles}
                  >
                    <IonIcon
                      color={useTileSubsOrig ? "dark" : "warning"}
                      icon={gridOutline}
                    />
                  </IonButton>
                </IonCol> */}

                <IonCol>
                  <IonItem>
                    <IonLabel>Loop</IonLabel>
                    <IonSelect
                      value={loopCount}
                      onIonChange={handleLoopCountChange}
                      interface="popover"
                      interfaceOptions={customPopoverOptionsLoop}
                    >
                      <IonSelectOption value={1}>1</IonSelectOption>
                      <IonSelectOption value={3}>3</IonSelectOption>
                      <IonSelectOption value={5}>5</IonSelectOption>
                      <IonSelectOption value={8}>8</IonSelectOption>
                      <IonSelectOption value={13}>13</IonSelectOption>
                      <IonSelectOption value={21}>21</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>

                <IonCol>
                  {/*<IonButton fill="clear" size="small"><IonIcon slot="start" icon={copyOutline}></IonIcon></IonButton> */}
                  <IonItem>
                    <IonLabel>Selected</IonLabel>
                    <IonSelect
                      style={{ offsetz: "0", minHeight: '3rem' }}
                      interfaceOptions={customPopoverOptions}
                      value={languageCode}
                      placeholder="Select Preferred Language"
                      onIonChange={(e) => handleLanguageChange(e.detail.value)}
                      interface="popover"
                    >
                      {subtitleLanguages.map((lang) => (
                        <IonSelectOption key={lang} value={lang}>
                          {languageMap[lang] || lang}{" "}
                          {/* Display the standard language name or the custom subtitle name */}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
            </div>
          </div>

          {/* == 04: Button Sub Tiles == */}
          <div className="zplayer__buttonSubTiles">
            {/*<IonSegment
            value={tilesFilterValue}
            onIonChange={(e) => handleSegmentClick(e.detail.value!)}
            style={{ marginRight: "0", paddingRight: "0" }}
          >
            <IonSegmentButton value="all">
              <IonLabel>Original</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="bookmarked">
              <IonLabel>selected</IonLabel>
            </IonSegmentButton>
          </IonSegment>
                      */}

            {/* Controls to display above tiles depending on the segement being viewed */}
            {tilesFilterValue === "all" && (
              <IonItem className="custom-search-item">
                {searchTerm && (
                  <IonLabel position="floating">
                    {matchCount} matching tiles found
                  </IonLabel>
                )}
                <IonSearchbar
                  className=""
                  value={searchTerm}
                  onIonChange={handleTileSearch}
                  debounce={300}
                  enterkeyhint="search"
                  placeholder="Search tiles"
                ></IonSearchbar>
              </IonItem>
            )}
            {/*
            {tilesFilterValue === "bookmarked" && (
              <IonItem className="custom-search-item">
                <IonLabel>Loop Bookmarks</IonLabel>
                <IonToggle
                  checked={loopBookmarks}
                  onIonChange={e => setLoopBookmarks(e.detail.checked)}
                />
              </IonItem>
            )} */}
            {tilesFilterValue === "bookmarked" && (
              <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
                <IonIcon
                color={loopBookmarks ? "secondary" : "medium"}
                size="large"
                  icon={repeatOutline}
                  style={{

                    
                    cursor: "pointer"
                  }}
                  onClick={() => setLoopBookmarks(!loopBookmarks)}
                />
              </div>
            )}


            <IonGrid>
              {tilesFilterValue === "note" ? (
                <>
                  <IonRow>
                    <IonCol>
                      <IonTextarea
                        onIonChange={(e) =>
                          setNoteContent(e.detail.value ?? "")
                        }
                        onKeyDown={(e) => {
                          if ((e.ctrlKey || e.metaKey) && e.key === "s") {
                            e.preventDefault() // Prevent the default save action
                            handleSaveNote() // Call your save function
                          }
                        }}
                        value={noteContent}
                        className="custom-textarea"
                        autoGrow={true}
                        placeholder="Enter your note here..."
                      ></IonTextarea>
                      {/*<div className="custom-slate-editor">
                    <Slate
                      editor={editor}
                      initialValue={value}
                      onChange={(newValue) => setValue(newValue as CustomDescendant[])}
                      
                    >
                      <Editable />
                    </Slate>
                    </div> */}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="auto">
                      <IonButton
                        fill="outline"
                        size="small"
                        onClick={copyToClipboard}
                      >
                        Copy to Clipboard
                      </IonButton>
                    </IonCol>

                    {/* Column for the Save Note button */}
                    <IonCol>
                      <IonButton
                        size="small"
                        expand="block"
                        onClick={handleSaveNote}
                      >
                        Save Note
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </>
              ) : (
                <IonRow
                  style={{
                    overflowY:
                      (filteredSubtitles || []).length > 4 ? "auto" : "hidden",
                  }}
                >
                  {/* {(vttLoaded || []).map((subtitle, index) => ( */}
                  {/*(vttLoaded || []) */}
                  {(tilesFilterValue === "chapters"
                    ? chaptersLoaded || []
                    : filteredSubtitles || []
                  )
                    // .filter(
                    //   (_, index) =>
                    //     tilesFilterValue !== "bookmarked" ||
                    //     bookmarksLoaded.includes(index)
                    // )
                    .map((subtitle, index) => (
                      <IonCol
                        key={index}
                        size="6"
                        sizeMd={
                          windowWidth > 915 && windowWidth < 992 ? "12" : "4"
                        }
                        sizeLg="12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IonCard
                          ref={(el) =>
                            (buttonRefs.current[index] =
                              el as HTMLIonCardElement)
                          }
                          color={
                            tilesFilterValue === "chapters"
                              ? index === activeChapterIndex
                                ? "warning"
                                : "medium"
                                : index === (useTileSubsOrig ? activeTilePosSubIndexOrig : activeTilePosSubIndex)
                              ? "warning"
                              : "medium"
                          }
                          className="ion-card-hoverable ion-activatable ripple-parent tile-card"
                          key={index}
                          onClick={() =>
                            handleTileClick(subtitle.s, subtitle.e, index)
                          }
                          style={{
                            fontFamily: "Noto Sans, sans-serif",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            fontSize: "16px",
                            whiteSpace: "pre-wrap",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 5,
                            WebkitBoxOrient: "vertical",
                            WebkitBoxLines: 4,
                            height: "5.4rem",
                            //backgroundColor: subtitle === activeSubtitle ? 'lightblue' : 'grey', // Set the background color if the subtitle is active
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {tilesFilterValue != "chapters" && (
                              <>
                                { !useTileSubsOrig && (
                                <IonIcon
                                  icon={createOutline}
                                  onClick={(e) =>
                                    handleEditSubtitle(subtitle, e)
                                  }
                                  className="subeditor-icon"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    color: "lightgrey",
                                  }}
                                />
                                )}

                                {/* Bookmark solid should evaluate not only if the index is in the list but if it's the correct index.
                                  because the user can unbookmark a tile which will mean more tiles than bookmarks, results in the
                                  last tiles not getting correct bookmark indicators (solid/outline) */}
                                <BookmarkComponent
                                  solid={bookmarkedTiles.includes(
                                    tilesFilterValue == "all"
                                      //? parseInt(subtitle.i, 10) - 1 //index
                                        //: parseInt(subtitle.i, 10) - 1 //bookmarksLoaded[index] <--since each sub now has index key "i", no longer need to keep extra bookmarksLoaded list around, confirm before removal
                                      ? subtitle.i- 1 //index
                                      : subtitle.i - 1 //bookmarksLoaded[index] <--since each sub now has index key "i", no longer need to keep extra bookmarksLoaded list around, confirm before removal

                                  )}
                                  size="small"
                                  onClick={(event) =>
                                    handleBookmarkClick(
                                      tilesFilterValue === "all"
                                        ? subtitle
                                        : subtitle, //bookmarksLoaded[index],
                                      event
                                    )
                                  }
                                  className="bookmark-icon"
                                />

                                {/* <IonBadge className="tile-views" slot="end">22</IonBadge> */}
                              </>
                            )}

                            <IonRippleEffect></IonRippleEffect>
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                padding: "3px",
                                textAlign: "center",
                                fontSize: "14px",
                                zIndex: 1,
                                color: "white",
                              }}
                            >
                              {`${formatTime(subtitle.s)} - ${formatTime(
                                subtitle.e
                              )}`}
{/* TODO: maybe put this index inside the bookmark icon?
      <span       style={{
          marginLeft: '1rem',
          fontSize: '.8rem',
          color: 'darkGrey',
        }}
      >
         [{subtitle.i}]
      </span>  */}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                padding: "3px",
                                paddingTop: "24px", // To make room for the header
                              }}
                            >
                              {subtitle.t}
                            </div>
                          </div>

                  {/* Bottom center badge */}
                  <div className="bottom-badge">
                    {subtitle.i}
                  </div>

                          {/* copy tile text to clipboard  */}
                          <IonIcon
                            icon={copyOutline}
                            onClick={(e) =>
                              copyTileToClipboard(subtitle.t, e)
                            }
                            className="sub-copy-icon"
                            style={{
                              cursor: "pointer",
                              fontSize: "1rem",
                              color: "rgb(204,204,204)",
                            }}
                          />

                          {/* Open Google translate for the current tile text  */}
                          <IonIcon
                            icon={globeOutline}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the card's onClick from firing
                              openGoogleTranslate(subtitle.t, e);
                            }}
                            className="translate-ext-icon"
                            style={{


                              cursor: 'pointer',
                              fontSize: '1rem',
                              color: "rgb(204,204,204)",
                            }}
                          />

                        </IonCard>
                      </IonCol>
                    ))}
                </IonRow>
              )}
              {/* Render the subtitle editor when open */}
              <SubtitleEditor
                subtitle={editedSubtitle}
                isOpen={isSubtitleEditorOpen}
                onClose={() => {
                  console.log("onClose called");
                  setIsSubtitleEditorOpen(false);
                }}
                onSave={handleSaveEditedSubtitle}
              />
            </IonGrid>
          </div>
        </div>

        {/* == 05: Controls == */}
        <div className="zplayer__playerControls">
          <PlayerControls
            playing={playing}
            handleSetPlaybackRate={handleSetPlaybackRate}
            handlePlayPause={handlePlayPause}
            handleBackFive={handleBackFive}
            handleForwardFive={handleForwardFive}
            subtitlesEnabled={subtitlesEnabled}
            subtitlesOrigEnabled={subtitlesOrigEnabled}
            toggleOverlay={toggleOverlay}
          />
        </div>
      </div>

    </IonPage>
  )
}

export default Zplayer
