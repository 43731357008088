import React, { useContext, useState, useEffect } from 'react'
import {
    IonBackButton,
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonRouterLink,
    IonTitle,
    IonToolbar,
    IonItem,
    IonList,
    IonInput,
    IonLabel,
    IonLoading,
    IonText,
    IonToast
  } from "@ionic/react"
  import { Redirect } from "react-router-dom"
  import { UserAuth } from '../auth'
  import { useHistory } from "react-router-dom"
  import "./authPage.scss"


const LoginPage = () => {
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState({ loading: false, error: false });
    const [error, setError] = useState('');
    const [showReset, setShowReset] = useState(false); // New state for showing reset password controls
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const {login, resetPassword} = UserAuth();
    const history = useHistory()

    useEffect(() => {
      // Detect if the user is on a mobile device or if the user agent can't be determined
      const userAgent = window.navigator.userAgent || '';
      const mobileDevices = /Android|iPhone|iPad|iPod|Windows Phone/i;
      const isMobile = mobileDevices.test(userAgent) || !userAgent;
  
      setShowMessage(isMobile);
    }, []);

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        showReset ? sendResetEmail() : handleLogin();
      }
    }

    const handleLogin = async () => {
      try {
        setError('');
        setStatus({ loading: true, error: false });
        const credential = await login(email, password);
        console.log('credential:', credential);
        setStatus({ loading: false, error: false });
        // setEmail("");
        // setPassword("");
        history.push(`/home`)
      } catch (error) {
        setStatus({ loading: false, error: true });
        console.log('error:', error);
      }
    };

    const sendResetEmail = async () => {

      resetPassword(email)
        .then(() => {
          // Password reset email sent!
          setToastMessage('Password reset email sent! Check your inbox.');
          setShowToast(true);
          setShowReset(false); // Hide the reset password input
        })
        .catch((error) => {
          setError('Failed to send password reset email.');
          console.error('Error sending password reset email', error);
        });
    };



    return (
      <IonPage className="page">
        <IonHeader>
          <IonToolbar className="custom-toolbar">
          <IonTitle className="ion-padding">{showReset ? 'Reset Password' : 'Log-in'}</IonTitle>
          {/* <h5 style={{ marginTop: "0.1rem", marginLeft: "1.3rem", color: "#FFD03B"}}>Welcome back! If you're on a mobile device, stop now, add this page to your home screen, and open it from there.</h5> */}
          {showMessage && (
            <h5
              style={{
                marginTop: '0.1rem',
                marginLeft: '1.3rem',
                color: '#FFD03B',
              }}
            >
              If you're on a mobile device, stop now, add this page to your home
              screen, and open it from there.
            </h5>
          )}

          
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
        {/* <div className="auth-text">Space Subs</div> */}
        <IonRouterLink routerLink="/land">
        <div className="auth-text">Space Subs</div>
        </IonRouterLink>
          <IonList style={{ '--background': 'transparent', '--ion-item-background': 'transparent' }}>
            <IonItem fill="outline" className="custom-field ion-padding-start ion-padding-end field-spacing">
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput  type="email" value={email} 
                onIonChange={(event) => setEmail(event.detail.value ?? '')}
                onKeyUp={handleKeyPress}
                clearInput={true}
              />
            </IonItem >

            {!showReset && (
            <IonItem fill="outline" className="custom-field ion-padding-start ion-padding-end field-spacing">
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                type="password"
                value={password}
                onIonChange={(event) => setPassword(event.detail.value ?? '')}
                onKeyUp={handleKeyPress}
                clearInput={true}
              />
            </IonItem>
          )}

          </IonList>
          {status.error && 
            <IonText color="danger">Invalid credentials</IonText>
          }

          {/*<IonButton expand="block" onClick={handleLogin}>Login</IonButton>*/}

          {!showReset ? (
          <>
            <IonButton className='ion-padding' expand="block" onClick={handleLogin}>Log in</IonButton>
            <IonButton className='ion-padding' expand="block" fill="clear" onClick={() => setShowReset(true)}>
              Forgot password?
            </IonButton>
          </>
        ) : (
          <>
            <IonButton className='ion-padding' expand="block" onClick={sendResetEmail}>Send Reset Email</IonButton>
            <IonButton className='ion-padding' expand="block" fill="clear" onClick={() => setShowReset(false)}>
              Back to Login
            </IonButton>
          </>
        )}


          <IonButton className='ion-padding' expand="block" fill="clear" routerLink="/signup">
            Don't have an account?
          </IonButton>
          <IonLoading isOpen={status.loading} />

          <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={3000}
          position="middle"
          />


        </IonContent>
      </IonPage>
    );

}

export default LoginPage